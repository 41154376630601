import {
    CButton,
    CCard,
    CCol,
    CCollapse,
    CContainer,
    CDataTable,
    CInputCheckbox,
    CSpinner,
    CTooltip
} from "@coreui/react";
import {
    GenerateInvoiceRequest, IgnoreInvoice,
    PendingInvoice,
} from "../../../../models/accounting";
import React, {useEffect, useState} from "react";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import ConfirmModal from "../../../SharedComponents/ConfirmModal";
import AccountingClient from "../../../../clients/accountingClient";
import {useHistory, useLocation} from "react-router";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import {downloadBlob} from "../../../../utils/downloadUtils";
import SWSelect from "../../../SharedComponents/SWSelect";
import LookupsClient from "../../../../clients/lookupsClient";
import {useDispatch} from "react-redux";
import {SetLoading, ShowNotification} from "../../../../store/actions/auth";

interface IProps {
    accountingProfiles:{ [k: string]: string }
}

const IgnoredInvoiceTable = (props: IProps) => {
    const accountingClient = new AccountingClient();
    const lookupsClient = new LookupsClient();
    const hist = useHistory()
    const [showConfirmInvoiceModal, setShowConfirmInvoiceModal] = useState<boolean>(false)
    const [selectedRecordToInvoice, setSelectedRecordToInvoice] = useState<PendingInvoice>()
    const [pending, setPending] = useState<PendingInvoice[]>([])
    const [pendingNonCharges, setPendingNonCharges] = useState<PendingInvoice[]>([])
    const [pickupDate, setPickupDate] = useState<Date>(new Date(new Date().setDate(0)))
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [selectedRecords, setSelectedRecords] = useState<string[]>([])
    const [allSelected, setAllSelected] = useState<boolean>(false)
    const [showInvoiceMultipleAccountsModal, setShowInvoiceMultipleInvoicesModal] = useState<boolean>(false)
    const [showIgnoreInvoiceModal, setShowIgnoreInvoiceModal] = useState<boolean>(false)
    const [selectedInvoiceToIgnore, setSelectedInvoiceToIgnore] = useState<PendingInvoice>()
    const loc = useLocation()

    const [chargeTypes, setChargeTypes] = useState<{ [k: string]: string }>({});
    const [selectedProfile, setSelectedProfile] = useState<string>()

    const dispatch = useDispatch()

    useEffect(() => {
        if (isLastDay(new Date())) setPickupDate(new Date())
        lookupsClient.getCharges({availableOn: "shipment", format: 1}).then(c => setChargeTypes(c))
    }, [loc.search])


    const handleSearch = async () => {
        dispatch(SetLoading(true))
        setSubmitting(true)
        let pendingInvoices = await accountingClient.GetIgnoredInvoice({
            pickupDate: pickupDate.toDateString(),
            profileId: selectedProfile
        })
        if (pendingInvoices.succeeded) {
            setPending(pendingInvoices.data)
        }
        setSubmitting(false)
        return pendingInvoices
    }

    const isLastDay = (dt: Date) => {
        return new Date(dt.getTime() + 86400000).getDate() === 1;
    }

    const GetExcelFile = async (item: PendingInvoice) => {
        setSubmitting(true)
        const body: GenerateInvoiceRequest = {
            account: item.account,
            pickupDate: pickupDate.toDateString()
        }

        const remoteBlob = await accountingClient.GetExcelInvoiceDescription(body)
        downloadBlob(remoteBlob.location, remoteBlob.name)
        setSubmitting(false)
    }

    const handleIgnoreInvoice = async () => {
        const rq: IgnoreInvoice = {
            ignore: false,
            account: selectedInvoiceToIgnore?.account,
            pickupDate: pickupDate
        }
        const succeeded = await accountingClient.ModifyIgnoreInvoice(rq);
        if (succeeded) {
            dispatch(ShowNotification("Success", "Invoice ignored successfully", false))
        }
        setShowIgnoreInvoiceModal(false)
        setSelectedInvoiceToIgnore(undefined)
        await handleSearch()
    }

    const handleSelectAll = () => {
        if (!allSelected) {
            const records = pendingNonCharges.map(i => i.account)
            setSelectedRecords(records)
            setAllSelected(true)
        } else {
            setSelectedRecords([])
            setAllSelected(false)
        }
    }

    const scopedSlots = {
        getExcel: (item: PendingInvoice) => {
            return <td>
                <CIcon content={freeSet.cilCloudDownload} size="lg"
                       onClick={async () => GetExcelFile(item)}
                       style={{cursor: "pointer"}}
                />
            </td>
        },
        numberOfShipments: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.numberOfShipments}</td>
        },
        amountTaxable: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.amountTaxable}</td>
        },
        amountLiquidationTax: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.amountLiquidationTax}</td>
        },
        amountNonTaxable: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.amountNonTaxable}</td>
        },
        amountTax: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.amountTax}</td>
        },
        taxType: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.taxType || ""}</td>
        },
        action: (item: PendingInvoice) => {
            return <td className={"text-center"}>
                <CTooltip content={"Retrieve"}>
                    <CIcon content={freeSet.cilArrowCircleLeft}
                           style={{cursor: "pointer"}}
                           onClick={() => {
                               setSelectedInvoiceToIgnore(item)
                               setShowIgnoreInvoiceModal(true)
                           }}
                    />
                </CTooltip>
            </td>
        }
    }

    return (
        <>
            <CCol className="col-md-12 d-flex align-items-center mt-2">
                <CCol md={3}>
                    <SWSelect values={props.accountingProfiles} value={selectedProfile} label={"Profile"}
                              onChange={v => setSelectedProfile(v)} isClearable/>
                </CCol>
                <CCol md={3}>
                    <SWDateAndTimePicker value={pickupDate} handleOnChange={v => setPickupDate(new Date(v))}
                                         label={"Date"}/>
                </CCol>
                <CCol>
                    <CButton variant={"outline"} color={"primary"} size={"sm"} className={"mt-2"}
                             onClick={() => handleSearch()}>Search</CButton>
                </CCol>
                {selectedRecords.length > 0 ?
                    <CCol md={3} className={"mr-0 mt-3 text-right"}>
                        <CButton
                            size="sm"
                            style={{fontSize: "10px", marginBottom: "10px"}}
                            variant="outline"
                            color="primary"
                            onClick={() => {
                                setShowInvoiceMultipleInvoicesModal(true)
                            }
                            }
                        >
                            Generate {selectedRecords.length} invoice{selectedRecords.length > 1 ? 's' : ''}
                        </CButton>
                    </CCol> : <div style={{height: 35}}></div>
                }
            </CCol>
            {submitting ?
                <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                />
                :
                <CContainer>
                    <CDataTable
                        size={"sm"}
                        hover
                        items={pending ?? []}
                        scopedSlots={scopedSlots}
                        columnHeaderSlot={{
                            select: <CInputCheckbox checked={allSelected} onChange={() => handleSelectAll()}
                                                    style={{top: 0}}/>
                        }}
                        fields={[
                            {key: "accountName", "label": "Account"},
                            {key: "taxType", label: "Tax Type", _style: {textAlign: "center"}},
                            {key: "numberOfShipments", label: "Shipments", _style: {textAlign: "center"}},
                            {key: "amountTaxable", label: "Taxable Amount", _style: {textAlign: "center"}},
                            {
                                key: "amountLiquidationTax",
                                label: "Tax Liquidated Amount",
                                _style: {textAlign: "center"}
                            },
                            {key: "amountNonTaxable", label: "Non Taxable Amount", _style: {textAlign: "center"}},
                            {key: "amountTax", label: "Amount Tax", _style: {textAlign: "center"}},
                            {
                                key: "action",
                                label: "Action",
                                _style: {textAlign: "center", width: '5%', padding: '0.2rem'}
                            },
                        ]}
                    />
                </CContainer>

            }
            {showIgnoreInvoiceModal &&
                <ConfirmModal onClose={() => {
                    setSelectedInvoiceToIgnore(undefined)
                    setShowIgnoreInvoiceModal(false)
                }} onSubmit={() => handleIgnoreInvoice()}
                              title={"Retrieve invoice"}
                              body={`Are You sure you want to retrieve invoice for account: '${selectedInvoiceToIgnore?.accountName}'`}/>
            }
        </>
    )
}

export default IgnoredInvoiceTable

import {CCol, CDataTable, CLink} from "@coreui/react";
import {BillingType, ChargeAddUpdate, ChargeIsGroupedSearch, ChargeSearch} from "../../../../models/accounting";
import PageCount from "../../../SharedComponents/PageCount";
import React, {useMemo, useState} from "react";
import {convertToLocalDate, formatDateTime} from "../../../../utils/dateUtil";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import AccountingClient from "../../../../clients/accountingClient";
import AddEditCharge from "../../Shipments/modals/AddEditCharge";
import {ShowNotification} from "../../../../store/actions/auth";
import {useDispatch} from "react-redux";

type Props = {
    data: ChargeSearch[] | ChargeIsGroupedSearch[]
    offset: number
    isGrouped?: boolean
    onChangePagination: (data: { offset: number, limit: number }) => void
    total: number
    limit: number
    accountClient: AccountingClient
    chargeTypes: { [k: string]: string }
    onRefresh: () => void

}
const ChargesTable = (props: Props) => {
    const dispatch = useDispatch()


    const handleEditChargeModal = (id: number) => {
        let charges = props?.data as ChargeSearch[]
        let c = charges.find((charge: ChargeSearch) => charge.id === id) ?? null
        setCharge(c)


    }

    const handleSaveEditCharge = async (toAddOrUpdate: ChargeAddUpdate) => {
        const result = await props.accountClient.UpdateCharge(toAddOrUpdate);
        if (result) {
            props.onRefresh()
            dispatch(ShowNotification("Success", "Charge saved successfully", false));
            setCharge(null);
        }

    }


    const [charge, setCharge] = useState<ChargeSearch | null>(null)
    const scopedSlotsCharges = {
        createdOn: (item: ChargeSearch) => {
            return <td> {formatDateTime(convertToLocalDate(item?.createdOn ?? ''))}</td>;
        },
        pickupDate: (item: ChargeSearch) => {
            return <td> {formatDateTime(convertToLocalDate(item?.pickupDate ?? ''))}</td>;
        },


        weight: (item: ChargeSearch) => {
            return <td> {item.weightValue + " " + item.weightUnit}</td>;
        },
        invoiceNumber: (item: ChargeSearch) => {

            return <td>
                {item.invoiceId &&
                    <CLink to={`/financial/billing/${item.invoiceId}`}>{item.invoiceNumber ?? 'View Draft'}</CLink>}
            </td>;
        },
        edit: (item: ChargeSearch) => {
            let disabled = item.deleted || Boolean(item.invoiceId)
            return <td>
                {!disabled &&
                    <CIcon
                        disabled={disabled}
                        content={freeSet.cilPencil}
                        style={{cursor: "pointer", margin: "auto"}}
                        onClick={() =>
                            handleEditChargeModal(item.id)
                        }
                    />}
            </td>;
        },
        shipmentNumber: (item: ChargeSearch) => {
            return <td>{item.shipmentUid &&
                <CLink to={`/shipments/${item.shipmentUid}`}>{item.shipmentNumber ?? 'View Shipment'}</CLink>}</td>
        },
        value: (item: ChargeSearch) => {
            return <td>{item.value + " " + item.currency ?? ''}</td>
        }
    }

    const scopedSlotsGroupedCharges={
        shipmentNumber: (item: ChargeIsGroupedSearch) => {
            return <td>{item.shipmentUid &&
                <CLink to={`/shipments/${item.shipmentUid}`}>{item.shipmentNumber ?? 'View Shipment'}</CLink>}</td>
        },
    }
    const fields = useMemo(() => {
        if (props.isGrouped)
            return [
                {key: "customerName", label: "Customer Name"},
                {key: "shipmentNumber", label: "Shipment Number"},
                {key: "totalCost", label: "Total Cost"},
                {key: "totalSelling", label: "Total Selling"},
            ]

        return [
            {key: "edit", label: ""},
            {key: "billingTypeText", label: "Billing"},
            {key: "typeName", label: "Type"},
            {key: "value", label: "Value"},
            {key: 'account', label: 'Account'},
            {key: 'customerAccountName', label: 'Customer Account'},
            {key: "shipmentNumber", label: "Shipment Number"},
            {key: "invoiceNumber", label: "Invoice Number"},
            {key: "pickupDate", label: "Pickup Date"},
            {key: "weight", label: "Weight"},
        ]
    }, [props.isGrouped])
    return (
        <>
            <CCol className={'pt-3'}>
                <CDataTable
                    size="sm"
                    hover
                    scopedSlots={props.isGrouped ? scopedSlotsGroupedCharges: scopedSlotsCharges}
                    items={props.data}
                    fields={fields}

                />

                <PageCount
                    onPageChange={(p) => {
                        props.onChangePagination({limit: props.limit, offset: (p * props.limit)})
                    }}
                    onPageSizeChange={(pageSize) => {
                        props.onChangePagination({limit: pageSize, offset: props.offset})
                    }}
                    currentPage={Math.floor((props.offset / props.limit))}
                    maxPage={Math.ceil(
                        props.total! / props.limit!)}
                    maxFound={props.total!}

                />

            </CCol>
            <div className={'text-black'}>
                {charge &&
                    <AddEditCharge hideIsGroupedCheckBox={true} onSave={handleSaveEditCharge} account={charge.account}
                                   onClose={() => setCharge(null)}
                                   chargeToEdit={charge}
                                   chargeTypes={props.chargeTypes} billingType={charge.billingType}
                                   shipmentUid={charge.shipmentUid} accountingClient={props.accountClient}
                                   showList={false}/>}
            </div>

        </>

    )
}
export default ChargesTable

import React, { useState } from "react";
import { Link } from "react-router-dom";
import routes, { RouteData } from "../../routes";
import {
  CContainer,
  CRow,
  CCol,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CCreateElement,
  CNavItem,
  CCollapse,
  CNav,
} from "@coreui/react";
import { routeDataToRoute } from "../../utils/routeUtils";
import NavItem from "./NavItem";

interface Props {
  routesData: RouteData[];
}

const Navmenu = (props: Props) => {
  const { routesData } = props;
  console.log(routesData);
  return (
    <CSidebarNav>
      {routesData.map((rd) => (
        <NavItem key={`${rd.name}${rd.path}`} routeData={rd} />
      ))}
    </CSidebarNav>
  );
};

export default Navmenu;

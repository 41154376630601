import { ClientFactory } from "@simplify9/simplyapiclient";
import IClient from "@simplify9/simplyapiclient/dist/Models/IClient";
import { FilterGroup } from "../models/filter";
import { EmailProvider, UserUpdate } from "../models/user";
import { filtersToSearchy } from "../utils/filterUtils";
import CookieManager from "../services/cookieManager";

class AuthClient {
  client: IClient;
  BaseUrl: string;
  BaseUrlNoApi: string;
  pageSize: number;

  constructor() {
    this.BaseUrlNoApi = `${process.env.REACT_APP_API_BASE_URL}/auth`;
    this.BaseUrl = `${process.env.REACT_APP_API_BASE_URL}/auth/api`;
    this.client = ClientFactory({
      baseUrl: this.BaseUrl,
      authType: "bearer",
      getBearer: () => CookieManager.getAccessToken(),
      
    });
    this.pageSize = 20;
  }

  async Login(
    username: string,
    password: string,
    emailProvider?: EmailProvider
  ) {
    const rs = this.client.SimplyPostAsync("/login", {
      username,
      password,
    });
    return rs;
  }

  async SearchUsers(filterGroup: FilterGroup) {
    const searchyString = filtersToSearchy(filterGroup);
    const rs = await this.client.SimplyGetAsync(`/users?${searchyString}`);
    return rs.data;
  }
  async DeleteUser(username: string) {}
  async GetUser(username: string) {
    const rs = await this.client.SimplyGetAsync(`/users/${username}`);
    return rs.data;
  }
  async UpdateUser(username: string, user: UserUpdate) {
    const rs = this.client.SimplyPostAsync(`/users/${username}`, user);
    return rs;
  }
  async CreateUser(user: UserUpdate) {
    const rs = this.client.SimplyPostAsync("/users", user);
    return rs;
  }
}

export default AuthClient;

import { Action, AuthenticationAppModel, UserAppModel } from "../../models/app";
import initialState from "../initialStates/userInitialState";
import {
  SET_USER_FILTER_GROUP,
  SET_USER_IS_LOADING,
  SET_USER_RESULT,
} from "../types";
import { UserSearch } from "../../models/user";

const userReducer = (
  state: UserAppModel = initialState,
  action: Action
): UserAppModel => {
  switch (action.type) {
    case SET_USER_FILTER_GROUP:
      return {
        ...state,
        usersSearch: {
          ...state.usersSearch,
          filterGroup: {
            ...state.usersSearch.filterGroup,
            ...action.payload!,
          },
        },
      };

    case SET_USER_IS_LOADING:
    case SET_USER_RESULT:
      return {
        ...state,
        usersSearch: {
          ...state.usersSearch,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default userReducer;

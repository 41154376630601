import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {SearchResult, SearchyResult, ShipmentAppModel} from "../models/app";
import { FilterGroup } from "../models/filter";
import {
  filterGroupToQueryParams,
  filtersToSearchy,
  queryParamsToFilters as queryParamsToFilterGroup,
} from "../utils/filterUtils";
import {ShipmentSearch} from "../models/shipment";
import { useDispatch } from "react-redux";
import { SetIsLoading } from "../store/actions/ui";

export interface SearchOnFilterGroupPayload {
  useFilterGroupSelector: () => FilterGroup;
  updateFilterGroup: (filterGroup: FilterGroup) => void;
  searchFunction: (filterGroup: FilterGroup) => Promise<SearchyResult<any>>;
  setIsLoading: (isLoading: boolean) => void;
  setResult: (searchResult: SearchResult<any>) => void;
}

const areFilterGroupsEqual = (fg1: FilterGroup, fg2: FilterGroup) => {
  const reducedFg1 =
    fg1.filters?.reduce((pv, cv) => {
      return pv + cv.field.length + cv.type + cv.value;
    }, "") + `page=${fg1.page}&limit=${fg1.limit}`;
  const reducedFg2 =
    fg2.filters?.reduce((pv, cv) => {
      return pv + cv.field.length + cv.type + cv.value;
    }, "") + `page=${fg2.page}&limit=${fg2.limit}`;

  const rs = reducedFg1 === reducedFg2;

  return rs;
};

export function useSearchOnFilterGroup(payload: SearchOnFilterGroupPayload) {
  const filterGroup = payload.useFilterGroupSelector();

  const loc = useLocation();
  const hist = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      payload.setIsLoading(true);
       dispatch(SetIsLoading(true))
      const tmpFilterGroup = queryParamsToFilterGroup(loc.search);
      const result = await payload.searchFunction(tmpFilterGroup);
      if (result) {
        payload.setResult({
          result: result.result,
          count: result.totalCount,
          page: filterGroup?.page ?? 0,
          limit: filterGroup?.limit ?? 10,
          missingShipments: result.missingShipments
        });
      } else {
        payload.setResult({
          error: "Could not get data",
          count:0
        });
      }
      payload.setIsLoading(false);
    })();
  }, [loc.search]);

  useEffect(() => {
    // filterGroup &&
    //   hist.push();
  }, [filterGroup]);

  useEffect(() => {
    const tmpFilterGroup = queryParamsToFilterGroup(loc.search);
    if (!areFilterGroupsEqual(tmpFilterGroup, filterGroup)) {
      payload.updateFilterGroup(tmpFilterGroup);
    }
  }, [filterGroup, loc.search]);
}

export function useComplexState<T>(
  state: T | any
): [T, (field: string, prop: any) => void, (state: any) => void] {
  const [internalState, setInternalState] = useState(state);

  const changeProp = (field: string, prop: string) => {
    
    const copy = ({ ...internalState } as unknown) as { [k: string]: any };
    copy[field] = prop;
    setInternalState((copy as unknown) as T);
  };

  return [internalState, changeProp, setInternalState];
}

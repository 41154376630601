import {AccountFilterGroup, FilterGroup} from "../../models/filter";
import {
    SET_ACCOUNT_FILTER_GROUP,
    SET_ACCOUNT_IS_LOADING,
    SET_ACCOUNT_RESULT,
    
} from "../types";
import {IAddAccount} from "../../models/shipment";

export const setAccountsFilterGroup = (filterGroup: AccountFilterGroup) => {
    return {
        type: SET_ACCOUNT_FILTER_GROUP,
        payload: filterGroup,
    };
}
export const SetAccountsIsLoading = (loading: boolean) => {
    return {
        type: SET_ACCOUNT_IS_LOADING,
        payload: {
            isLoading: loading,
        },
    };
};

export const SetAccountResult = (
    page: number,
    count: number,
    result: IAddAccount[],
    limit:number,
    error?: string,
    
) => {
    return {
        type:SET_ACCOUNT_RESULT,
        payload: {
            count: count,
            result: result,
            error: error,
            limit:limit,
        },
    };
};
import { CButton, CButtonGroup, CCol, CContainer, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import validator from "validator";
import { Filter, FilterGroup, FilterType } from "../../../models/filter";
import SWAdvancedSearchContext from "./SWAdvancedSearchContext";
import SWAdvancedSearchFilter from "./SWAdvancedSearchFilter";
import { forEachChild } from "typescript";
import { useHistory, useLocation } from "react-router";
import { property } from "lodash";
import ActionButton from "../ActionButton";

interface Props {
  className?: string;
  defaultFilters?: Filter[];
  filterGroup?: FilterGroup;
  availableFilters: Filter[];
  filter: (filters: Filter[]) => void;
  cancelCb: () => void;
  children?: React.ReactNode;
}

const SWAdvancedSearch = (props: Props) => {
  const {
    className,
    defaultFilters,
    availableFilters,
    filter,
    filterGroup,
    cancelCb,
    children,
  } = props;

  const loc = useLocation();
  const hist = useHistory();

  const initialState = defaultFilters
      ? defaultFilters.map((v) => {
        return { ...v, id: Math.random() };
      })
      : [];

  const [filters, setFilters] = useState<Filter[]>(initialState);

  useEffect(() => {
    if (filterGroup && filterGroup.filters && filterGroup.filters.length > 0) {
      setFilters(
          filterGroup
              .filters!.map((f) => {
            const meta = availableFilters.find((af) => af.field === f.field);
            if (!meta) return null;

            return {
              ...f,
              id: Math.random(),
              lookUp: meta?.lookUp,
              inputType: meta?.inputType,
              value: f.value,
            };
          })
              .filter((f) => f != null) as Filter[]
      );
    }
  }, [filterGroup]);

  useEffect(() => {
    filters.forEach((f, i) => filters[i].value = f.value.toString().trim())
  }, [filters])

  const addFilter = () => {
    const filterData = availableFilters
        .filter((av) => -1 === filters.findIndex((v) => av.field === v.field))
        .pop();

    if (filterData && filterData.field) {
      const newFilters = [
        ...filters,
        {
          id: Math.random(),
          field: filterData.field,
          inputType: filterData.inputType,
          type: FilterType.Equals,
          value: filterData.value ?? "",
          lookup: filterData.lookUp,
        },
      ];

      setFilters(newFilters);
    }
  };

  const changeFilterProperty = (
      id: number,
      value: any,
      property: string,
      reset: boolean = false
  ) => {
    const newFilters: Filter[] = [...filters];

    const idx = newFilters.findIndex((v) => {
      return v.id === id;
    });

    if (reset) {
      newFilters[idx] = availableFilters.find((v) => v.field === value)!;
    } else {
      const newFilterCopy: Filter = {
        ...newFilters[idx],
      };

      newFilterCopy[property] = value;
      newFilters[idx] = newFilterCopy;
    }
    for (let f of newFilters) {
      if (!f.id) f.id = Math.random();
      if (f.lookUp && !f.value) {
        f.value = Object.keys(f.lookUp)[0];
      }
    }
    setFilters(newFilters);
  };

  const deleteFilter = (id: number) => {
    const newFilters = filters.filter((v) => v.id !== id);
    setFilters(newFilters);
  };

  const clearFilters = () => {
    // setFilters([filters[0]]);
    // filter([filters[0]]);
    // reset fields value
    setFilters(initialState)

    const newUrl = loc.pathname;
    hist.push(newUrl);
  };

  return (
      <SWAdvancedSearchContext.Provider
          value={{
            filters: filters,
            addFilter,
            changeFilterProperty,
            deleteFilter,
            availableFilters,
          }}
      >
        <CContainer className={className}>
          {filters.map((f) => (
              <SWAdvancedSearchFilter key={f.id} filter={f} />
          ))}
          <CRow className="justify-content-around w-100">
            <CCol>
              <CButtonGroup>
                {filters.length > 0 && (
                    <CButton
                        size="sm"
                        style={{ fontSize: "10px" }}
                        variant="outline"
                        color="danger"
                        className="mr-2"
                        onClick={clearFilters}
                    >
                      Clear all
                    </CButton>
                )}
                <CButton
                    size="sm"
                    style={{ fontSize: "10px" }}
                    className="mr-2"
                    variant="outline"
                    color="primary"
                    onClick={() => addFilter()}
                >
                  Add Filter
                </CButton>
              </CButtonGroup>
            </CCol>
            <CCol className="text-right">
              {children && (
                  <CButtonGroup className="mr-4">{children}</CButtonGroup>
              )}
              <CButtonGroup>
                <ActionButton
                    text={"Search"}
                    onClick={() => filter(filters)}
                />
              </CButtonGroup>
            </CCol>
          </CRow>
        </CContainer>
      </SWAdvancedSearchContext.Provider>
  );
};

export default SWAdvancedSearch;

import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
    CCard,
    CCardBody,
    CCol,
    CLink,
    CNav,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CTabs,
    CTooltip,
} from "@coreui/react";
import ShipClient from "../../../clients/shipClient";
import { useHistory, useParams } from "react-router";
import {
    SendShipmentAttachmentRequest,
    ShipmentAttachment,
    ShipmentGet,
    ShipmentSearch,
    StandardShipmentItem,
} from "../../../models/shipment";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import AddUpdateItems from "./modals/AddUpdateItems";
import DeleteItemModal from "./modals/DeleteItemModal";
import { useDispatch } from "react-redux";
import { ShowNotification } from "../../../store/actions/auth";
import { formatDateTime } from "../../../utils/dateUtil";

import AddAttachmentModal from "./modals/AddAttachmentModal";
import RatesClient from "../../../clients/ratesClient";
import ConfirmModal from "../../SharedComponents/ConfirmModal";
import WarehousesClient from "../../../clients/warehousesClient";
import {
    InventoryTransactionDetailDto,
    InventoryTransactionDetailReportItem,
    SkuSearch
} from "../../../models/warehouse";
import SWInput from "../../SharedComponents/SWInput";
import EditItemsFromStock from "./modals/EditItemsFromStock";
import { BillingType, ChargeSearch } from "../../../models/accounting";
import Traces from "./ShipmentTablesComponents/Traces";
import NewTraces from "./ShipmentTablesComponents/NewTraces";
import MissingTraces from "./ShipmentTablesComponents/MissingTraces";
import Communications from "./ShipmentTablesComponents/Communications";
import Comments from "./ShipmentTablesComponents/Comments";
import Pieces from "./ShipmentTablesComponents/Pieces";
import Charges from "./ShipmentTablesComponents/Charges";
import CaptureImageModal from "../../SharedComponents/CaptureImageModal";
import CameraIcon from "../../../icons/camera";
import { RemoteBlob } from "../../../models/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    shipment: ShipmentGet;
    countries: { [key: string]: string };
    refresh: () => void;
    onUpdateShipment: (shipment: ShipmentSearch) => void;
    charges: ChargeSearch[]
    chargeTypes: { [k: string]: string }
}

const ShipmentTables = (props: IProps) => {
    const { shipment, countries, refresh, onUpdateShipment, charges, chargeTypes } = props
    const hist = useHistory()
    const shipClient = new ShipClient();
    const ratesClient = new RatesClient();
    const warehouseClient = new WarehousesClient();
    const { id } = useParams() as any;
    const [deleteItemModal, setDeleteItemModal] = useState(false);
    const [agents, setAgents] = useState<{ [k: string]: string }>({})
    const [skus, setSkus] = useState<SkuSearch[]>([])
    const [skuCodes, setSkuCodes] = useState<string[]>([])
    const [stock, setStock] = useState<InventoryTransactionDetailDto[]>()

    const [fileUploaded, setFileUploaded] = useState<boolean>(false)
    const [fileLocation, setFileLocation] = useState<string>()
    const [fileLoading, setFileLoading] = useState<boolean>(false)

    const [itemModalIsShowing, setItemModalIsShowing] = useState(false);
    const [editStockItem, setEditStockItem] = useState(false);
    const [isEditItem, setIsEditItem] = useState(false);
    const [itemIndex, setItemIndex] = useState<number | undefined>(undefined);
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)
    const [attachmentIndex, setAttachmentIndex] = useState<number>()
    const [deleteAttachmentModal, setDeleteAttachmentModal] = useState<boolean>(false)
    const [attachmentId, setAttachmentId] = useState<number>()
    const [sendAttachmentToShipperConfirmModal, setSendAttachmentToShipperConfirmModal] = useState<boolean>(false)
    const [sendAttachmentToConsigneeConfirmModal, setSendAttachmentToConsigneeConfirmModal] = useState<boolean>(false)
    const [containerTypeLookups, setContainerTypeLookups] = useState<{ [k: string]: string }>({})

    const sellingCharges = charges.filter(c => c.billingType == BillingType.Selling);
    const costCharges = charges.filter(c => c.billingType == BillingType.Cost);

    const [itemsLength, setItemsLength] = useState<number>(0)
    const [captureModal, setCaptureModal] = useState<boolean>(false);


    useEffect(() => {
        ratesClient.LookupAgents().then(v => setAgents(v))
        shipClient.GetContainerTypes(null, true).then(ct => setContainerTypeLookups(ct as { [k: string]: string }))
    }, [shipment.uid])

    useEffect(() => {
        if (shipment.uid) {
            if (shipment.number)
                warehouseClient.searchStockDetails({
                    reference: shipment.uid,
                    account: shipment.account,
                    subType: "OrderFulfillment",
                    isNotPagination: true,
                    postedOnly: true,
                    nullReversed: true
                } as any).then((res: any) => {
                    const mutatedRes = res?.map((item: any) => {
                        return {
                            warehouse: item?.warehouse ?? null,
                            lot: {
                                account: item?.lot?.account ?? null,
                                sku: item?.lot?.sku,
                                skuName: item?.lot?.skuName ?? null,
                                skuWeight: item?.lot?.skuWeight,
                                skuWeightUnit: item?.lot?.skuWeightUnit,
                                status: item?.lot?.status,
                                batchNumber: item?.lot?.batchNumber ?? null,
                                expiryDate: item?.lot?.expiryDate ?? null,
                                itemNumber: item?.lot?.itemNumber ?? null,
                            },
                            area: item?.area,
                            location: item?.location,
                            quantity: shipment.number ? Math.abs(item?.quantity) : Math.abs(item?.quantityAvailable),
                        }
                    })
                    setStock((mutatedRes ?? []) as any)
                })
            else
                warehouseClient.searchStockDetails({
                    reference: shipment.uid,
                    account: shipment.account,
                    openReservation: true,
                    isNotPagination: true,
                    postedOnly: true
                } as any).then((res: any) => {
                    const mutatedRes: InventoryTransactionDetailDto[] = res?.map((item: InventoryTransactionDetailReportItem) => {
                        return {
                            warehouse: item?.warehouse ?? null,
                            lot: {
                                account: item?.lot?.account ?? null,
                                sku: item?.lot?.sku,
                                skuName: item?.lot?.skuName ?? null,
                                skuWeight: item?.lot?.skuWeight,
                                skuWeightUnit: item?.lot?.skuWeightUnit,
                                status: item?.lot?.status,
                                batchNumber: item?.lot?.batchNumber ?? null,
                                expiryDate: item?.lot?.expiryDate ?? null,
                                itemNumber: item?.lot?.itemNumber ?? null,
                            },
                            area: item?.area,
                            location: item?.location,
                            quantity: shipment.number ? Math.abs(item?.quantity as number) : Math.abs(item?.quantityAvailable as number),
                        }
                    })
                    setStock((mutatedRes ?? []))
                })
        }
    }, [shipment])
    useEffect(() => {
        getSkus()
        CountItems()
    }, [shipment.items, stock])

    const CountItems = () => {
        let length = stock?.length ?? 0 + shipment.items?.filter(i => !i.productCode || !stock?.map(s => s.lot.sku)?.includes(i.productCode)).length
        if (length == 0)
            length = shipment.items.length
        setItemsLength(length)
    }


    const getSkus = async () => {
        if (!shipment.items || shipment.items.length <= 0) return
        // @ts-ignore
        let productCodes: string[] = shipment.items.map(i => {
            if (i?.productCode != undefined)
                return i.productCode
        })
        const skuSearchResult = await warehouseClient.SearchSku({
            accountNumber: shipment.account,
            codes: productCodes.join(","),
            limit:productCodes?.length,
            calculateQuantityForOrders:true,
        })
        const skus: SkuSearch[] = skuSearchResult.data.matches
        setSkus(skus)
        const skuCodes = skus.map(s => s.code)
        setSkuCodes(skuCodes)
    }

    const showEditItemModal = (index?: number) => {
        setItemModalIsShowing(true);
        setIsEditItem(true)
        setItemIndex(index);
    };
    const showAddItemModal = (index?: number) => {
        setItemModalIsShowing(true);
        setItemIndex(index);
    };

    const showEditStockItem = (index?: number) => {
        setEditStockItem(true);
        setItemIndex(index);
    };

    let dispatch = useDispatch()

    const handleFormChange = (shipment: ShipmentSearch) => {
        let sum: number = 0;
        shipment.items.forEach((item: StandardShipmentItem) =>
            item.value ? (sum += Number(item?.value)) : 0
        );

        const shipmentWithTotalUpdated = {...shipment, value: sum};
        onUpdateShipment(shipmentWithTotalUpdated);
    };

    enum ServiceType {
        UNKNOWN = 'Unknown',
        RETURN = 'Return',
        REPRINTED = 'Reprinted'
    }

    const serviceTypeList: ServiceType[] = [
        ServiceType.UNKNOWN,
        ServiceType.RETURN,
        ServiceType.REPRINTED
    ]

    const handleDeleteItem = () => {

        if (itemIndex !== undefined) {
            const newItems = [
                ...shipment.items.filter((item, index) => index != itemIndex),
            ];

            handleFormChange({...shipment, items: newItems});

            setItemIndex(undefined);
            setDeleteItemModal(false);
        }
    };

    const handleFileImport = async (file?: File) => {
        setFileUploaded(false)
        setFileLoading(true)
        if (!file) return
        let res = await shipClient.UploadFile(file, shipment.number)
        await setFileLocation(res.location)
        if (res.location) {
            setFileUploaded(true)
            setShowAttachmentModal(true)
            setFileLoading(false)
        }
        setIsEditItem(false)
    }

    const handleSendAttachment = async (type: "shipper" | "consignee") => {
        const body: SendShipmentAttachmentRequest = {
            uid: shipment.uid,
            attachmentId: attachmentId ?? 0
        }
        let res = type == "shipper" ?
            await shipClient.sendAttachmentToShipper(body) :
            await shipClient.sendAttachmentToConsignee(body);
        if (res.succeeded) {
            dispatch(ShowNotification("Success", `Attachment successfully sent to ${type}`))
            let attachments = shipment.attachments
            // @ts-ignore
            let updatedAttachment = attachments[attachmentIndex]
            if (type == "shipper")
                updatedAttachment.sendToShipper = true
            else
                updatedAttachment.sendToConsignee = true
            // @ts-ignore
            attachments[attachmentIndex] = updatedAttachment
            onUpdateShipment({...shipment, attachments: attachments})
        }
        setAttachmentId(undefined)
        setAttachmentIndex(undefined)
        setSendAttachmentToConsigneeConfirmModal(false)
        setSendAttachmentToShipperConfirmModal(false)
    }

    const showDeleteItemModal = (index: number) => {
        setDeleteItemModal(true);
        setItemIndex(index);
    };

    const showDeleteAttachmentModal = (index: number) => {
        setAttachmentIndex(index)
        setDeleteAttachmentModal(true)
    }

    const handleDeleteAttachment = async () => {
        if (attachmentIndex == undefined)
            return
        let attachments = shipment.attachments
        let attachmentId = attachments[attachmentIndex].id
        let newAttachments = attachments.filter((attachment, index) => index != attachmentIndex)
        setAttachmentIndex(undefined)
        const res = await shipClient.deleteAttachment(shipment.uid, attachmentId)
        if (res.succeeded) {
            onUpdateShipment({...shipment, attachments: newAttachments})
            setDeleteAttachmentModal(false)
        }
    }

    const handleAddAttachment = async (attachment: ShipmentAttachment) => {
        setAttachmentIndex(undefined)
        const attachments = shipment.attachments
        attachments?.push(attachment)
        setShowAttachmentModal(false)
        onUpdateShipment({...shipment, attachments: attachments})
    }

    const onUpdateAttachment = (index: number) => {
        setAttachmentIndex(index)
        setShowAttachmentModal(true)
    }

    const handleUpdateAttachment = (attachment: ShipmentAttachment) => {
        if (attachmentIndex == undefined) return
        let attachments = shipment.attachments
        attachments[attachmentIndex] = attachment
        setShowAttachmentModal(false)
        setAttachmentIndex(undefined)
        onUpdateShipment({...shipment, attachments: attachments})
    }

    const onSendAttachment = (id: number, index: number, type: "shipper" | "consignee") => {
        setAttachmentId(id)
        setAttachmentIndex(index)
        if (type == "shipper")
            setSendAttachmentToShipperConfirmModal(true)
        else
            setSendAttachmentToConsigneeConfirmModal(true)
    }

    const renderHeader = (header: string[]) => header.map((value, index) => {
        return (
            <th className="sticky-top bg-white" key={index}>
                {value}
            </th>
        );
    });

    const Td = (value: { val: any, empty?: string }) => <td> {value.val ?? value.empty} </td>


    const handleCopy = async (text: string) => {
        await navigator.clipboard.writeText(text)
        dispatch(ShowNotification("Success", "Url coped to clipboard", false))
    }

    const handleDownload = (text: string) => {
        const link = document.createElement("a");
        link.href = text;
        link.click();
        link.remove();
    }

    const handleAddBulkAttachments = async (attachments:RemoteBlob[]) => {
        const mappedAttachments = attachments?.map((attachment:RemoteBlob) => ({
            fileName: attachment?.name,
            location: attachment?.location,
            type: "Shipment Photo",
            hideOnCustomer:false,
        }))
        const res  = await shipClient.addBulkAttachment(shipment.uid,mappedAttachments)
        setCaptureModal(false)
        refresh()
    }

    const getAvailableQuantity = useMemo(() => {
        const memo = new Map();
        return (productCode:string) => {
            if (memo.has(productCode)) {
                return memo.get(productCode);
            }
            const foundSku = skus?.find((sku) => sku?.code === productCode);
            const availableQuantity = foundSku ? Number(foundSku?.quantityAvailable) :0;
            const stockQuantity = foundSku ? Number(foundSku?.quantity) :0;
            const result = { availableQuantity, stockQuantity };
            memo.set(productCode, result);

            return result;
        };
    }, [skus]);

    return (
        <CCardBody>
            <CTabs>
                <CCard className="small">
                    <CNav variant="tabs">
                        <CNavLink>Tracking ({shipment.traces?.length ?? 0})</CNavLink>
                        <CNavLink>Communications ({shipment.communications?.length ?? 0})</CNavLink>
                        <CNavLink>Internal Comments ({shipment.comments?.length ?? 0})</CNavLink>
                        <CNavLink>Pieces ({shipment.pieces?.length ?? 0})</CNavLink>
                        <CNavLink>Items ({shipment.items?.length ?? 0})</CNavLink>
                        <CNavLink>Attachments ({shipment.attachments?.length ?? 0})</CNavLink>
                        <CNavLink>Containers ({shipment.containers?.length ?? 0})</CNavLink>
                        <CNavLink>Services ({shipment.extraServices?.length ?? 0})</CNavLink>
                        <CNavLink>Routes ({shipment.routes?.length ?? 0})</CNavLink>
                        <CNavLink>Selling Charges ({sellingCharges?.length ?? 0})</CNavLink>
                        <CNavLink>Cost Charges ({costCharges?.length ?? 0})</CNavLink>

                    </CNav>
                    <CTabContent>
                        <CTabPane>
                            <Traces shipment={shipment} shipClient={shipClient} refresh={refresh}/>
                        </CTabPane>
                        <CTabPane>
                            <Communications shipment={shipment}/>
                        </CTabPane>
                        <CTabPane>
                            <Comments shipment={shipment} shipClient={shipClient} onUpdateShipment={onUpdateShipment}/>
                        </CTabPane>
                        <CTabPane>
                            <Pieces shipment={shipment} onUpdateShipment={onUpdateShipment}
                                handleFormChange={handleFormChange}/>
                        </CTabPane>
                        <CTabPane>
                            <div className="itemsTabTable">
                                <CTooltip content={"Add item"}>
                                    <CIcon
                                        content={freeSet.cilPlus}
                                        size={"xl"}
                                        style={{cursor: "pointer"}}
                                        color={"primary"}
                                        onClick={() => {
                                            showAddItemModal();
                                        }}
                                    />
                                </CTooltip>
                                <table className="position-relative table table-hover table-sm">
                                    <thead key={id}>{renderHeader(["","Description", "ٌReference", "Product Code",
                                        "Country of Origin", "Quantity",
                                        "Value/Currency", "HS Code", "Weight", "Dutiable",""])}</thead>
                                    <tbody>
                                        {stock && shipment.items?.filter(i => !i.productCode || !stock.map(s=> s.lot.sku)?.includes(i.productCode))?.map(
                                            (item,i) => {
                                                let actualIndex = shipment.items.findIndex(i => i == item)
                                                return (
                                                    <Fragment key={i}>
                                                        <tr
                                                            style={shipment?.blocked && !!item?.productCode && !!item?.quantity && getAvailableQuantity(item?.productCode).availableQuantity < item?.quantity ? { backgroundColor: "#fadddd" } : { backgroundColor: "white" }}
                                                        >
                                                            <td>
                                                                {shipment?.blocked && !!item?.productCode && !!item?.quantity && getAvailableQuantity(item?.productCode).availableQuantity < item?.quantity &&
                                                                    <CTooltip content={<>
                                                                        <p className="text-left">
                                                                            Stock Quantity: {getAvailableQuantity(item?.productCode).stockQuantity}
                                                                        </p>
                                                                        <p className="text-left">
                                                                            Available Quantity: {getAvailableQuantity(item?.productCode).availableQuantity}
                                                                        </p>
                                                                    </>}>
                                                                        <FontAwesomeIcon
                                                                            icon={faInfoCircle}
                                                                            size="xl"
                                                                            color={"red"}
                                                                            style={{ cursor: "pointer", marginLeft: 3 }}
                                                                        />
                                                                    </CTooltip>
                                                                }
                                                            </td>
                                                            <td>{item?.description || ""}</td>
                                                            <td> {item?.reference || ""}</td>
                                                            <td>{item?.productCode || ""}</td>

                                                            <td>
                                                                {item?.countryOfOrigin &&
                                                                    countries[item.countryOfOrigin]}
                                                            </td>

                                                            <td>{item?.quantity || ""}</td>
                                                            <td>
                                                                {item?.value || ""} {shipment?.valueCurrency || ""}
                                                            </td>
                                                            <td>{item?.hsCode || ""}</td>
                                                            <td>
                                                                {item?.weight || ""} {shipment?.weightUnit || ""}
                                                            </td>
                                                            <td>{item?.dutiable ? "Yes" : "No"}</td>
                                                            <td>
                                                                <CIcon
                                                                    content={freeSet.cilPencil}
                                                                    onClick={() => {
                                                                        showEditItemModal(actualIndex);
                                                                    }}
                                                                />
                                                                <CIcon
                                                                    content={freeSet.cilTrash}
                                                                    onClick={() => {
                                                                        showDeleteItemModal(actualIndex);
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                )
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="itemsTabTable">
                                <CTooltip content={"Edit"}>
                                    <CIcon
                                        content={freeSet.cilPencil}
                                        style={{cursor: "pointer", padding: "0 0 4px 4px"}}
                                        size={"lg"}
                                        color={"primary"}
                                        onClick={() => {
                                            showEditStockItem();
                                        }}
                                    />
                                </CTooltip>
                                <table className="position-relative table table-hover table-sm">
                                    <>
                                        <thead key={id}>{renderHeader(["Sku Name", "Sku Code",
                                            "Quantity", "Weight", "Batch Number", "Batch Barcode", "Expiry Date", "Location"])}</thead>
                                        <tbody>
                                            {stock?.map((item: any, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item?.lot?.skuName || ""}</td>
                                                        <td>{item.lot?.sku}</td>
                                                        <td>{"" + item.quantity}</td>
                                                        <td>
                                                            {item?.lot?.skuWeight || ""} {item.lot?.skuWeightUnit || ""}
                                                        </td>
                                                        <td>
                                                            {item?.lot?.batchNumber || ""}
                                                        </td>
                                                        <td>
                                                            {item?.lot?.itemNumber || ""}
                                                        </td>
                                                        <td>
                                                            {item?.lot?.expiryDate || ""}
                                                        </td>
                                                        <td>
                                                            {item?.location || ""}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </>

                                </table>
                            </div>
                        </CTabPane>
                        <CTabPane>
                            <div className="attachmentsTabTable">
                                {shipment.number &&
                                    <>
                                        <CCol>
                                            <CRow className={" w-20 mt-3 px-2"}>
                                                {!fileLoading ? <SWInput type={"file"} id={"input-file"}
                                                    onChangeFile={(f) => handleFileImport(f)}/>
                                                    : <div
                                                        className={"rounded mb-3 h-100 m-0 py-1 px-2 d-flex align-items-center justify-content-center border-primary"}>Please
                                                        wait...</div>}

                                                <CameraIcon
                                                    style={{
                                                        marginLeft: 9
                                                    }}
                                                    tabIndex={-1}
                                                    onClick={() => setCaptureModal(true)}
                                                    tooltipText={'Capture'} />
                                            </CRow>
                                        </CCol>
                                    </>

                                }
                                <table className="position-relative table table-hover table-sm">
                                    <thead>{renderHeader(["File Name", "Attachment Type", "Download", "Agent", "Send to Shipper", "Send to consignee", "Created By",
                                        "Created On", "Edit"])}</thead>
                                    <tbody>
                                        {shipment.attachments?.length < 1 && (
                                            <div className="mr-2 ml-2 mt-2 mb-2">
                                                <span> No Available Attachments</span>
                                            </div>
                                        )}
                                        {shipment.attachments?.map((a, i) => (
                                            <tr key={i}>
                                                <td>{a.fileName}</td>
                                                <td>{a.type}</td>
                                                <td>
                                                    <CIcon
                                                        content={freeSet.cilCloudDownload}
                                                        style={{cursor: "pointer", margin: "auto"}}
                                                        onClick={() => handleDownload(`/ship/attachment/${a.urlKey}`)}
                                                    />
                                                    <CIcon
                                                        content={freeSet.cilCopy}
                                                        style={{cursor: "pointer", marginLeft: "8px"}}
                                                        onClick={() => handleCopy(a.location)}
                                                    />
                                                </td>
                                                <td>{agents[a.agent] ?? a.agent}</td>
                                                {shipment.shipperEmail ?
                                                    <td>{a.sendToShipper ?
                                                        <>
                                                            <CIcon
                                                                content={freeSet.cilCheck}
                                                                size={"sm"}
                                                            />
                                                            <CLink style={{marginLeft: "5px"}}
                                                                onClick={() => {
                                                                    onSendAttachment(a.id, i, "shipper")
                                                                }}>Resend</CLink>
                                                        </> :
                                                        <CLink style={{marginLeft: "18px"}}
                                                            onClick={() => {
                                                                onSendAttachment(a.id, i, "shipper")
                                                            }}>Send</CLink>
                                                    }
                                                    </td> : <td/>}
                                                {shipment.consigneeEmail ?
                                                    <td>{a.sendToConsignee ?
                                                        <>
                                                            <CIcon
                                                                content={freeSet.cilCheck}
                                                                size={"sm"}
                                                            />
                                                            <CLink style={{marginLeft: "5px"}}
                                                                onClick={() => {
                                                                    onSendAttachment(a.id, i, "consignee")
                                                                }}>Resend</CLink>
                                                        </> :
                                                        <CLink style={{marginLeft: "18px"}}
                                                            onClick={() => {
                                                                onSendAttachment(a.id, i, "consignee")
                                                            }}>Send</CLink>}
                                                    </td> : <td/>}
                                                <td>{a.by}</td>
                                                <td>{formatDateTime(a.on)}</td>
                                                <td>
                                                    <CIcon
                                                        content={freeSet.cilPencil}
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => onUpdateAttachment(i)}
                                                    />
                                                    <CIcon
                                                        content={freeSet.cilTrash}
                                                        style={{cursor: "pointer", marginLeft: "10px"}}
                                                        onClick={() => showDeleteAttachmentModal(i)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </CTabPane>
                        <CTabPane>
                            <div className="containersTabTable">
                                <table className="position-relative table table-hover table-sm">
                                    <thead
                                        key={id}>{renderHeader(["Number", "Type", "Opened on", "Closed on", "Number of shipments"])}</thead>
                                    <tbody>
                                        {shipment.containers?.length < 1 && (
                                            <div className="mr-2 ml-2 mt-2 mb-2">
                                                <span>Shipment has no containers</span>
                                            </div>
                                        )}
                                        {shipment.containers?.map((container, i) => (
                                            <tr key={i}>
                                                <td><CLink
                                                    onClick={() => hist.push(`/containers/${container.id}`)}>{container.number}</CLink>
                                                </td>
                                                <td>{containerTypeLookups[container.type]}</td>
                                                <td>{formatDateTime(container.openedOn)}</td>
                                                <td>{formatDateTime(container.closedOn)}</td>
                                                <td>{container.totalShipments}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CTabPane>
                        <CTabPane>
                            <div className="servicesTabTable">
                                <table className="position-relative table table-hover table-sm">
                                    <thead key={id}>{renderHeader(["Type", "Reference", "Description"])}</thead>
                                    <tbody>
                                        {shipment.extraServices?.map((service,i) => (
                                            <tr key={i}>
                                                <td>{serviceTypeList[(service?.type as any)] || "N/A"}</td>
                                                <td>{service?.reference || "N/A"}</td>
                                                <td>{service?.description || "N/A"}</td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </CTabPane>
                        <CTabPane>
                            <div>
                                <table className="position-relative table table-hover table-sm">
                                    <thead key={id}>{renderHeader(["Id", "Scenario", "Route", "Barcodes",
                                        "Container Type", "Container Route", "Container Prefix",
                                        "On", "By", "Exported", "By"])}</thead>
                                    <tbody>
                                        {shipment.routes?.map(route =>
                                            <tr key={route.id}
                                                style={{textDecoration: route.canceled ? "line-through" : "unset"}}>
                                                <Td val={route.id}/>
                                                <Td val={route.scenario}/>
                                                <Td val={route.route}/>
                                                <Td val={route.barcodes?.join(" ")}/>
                                                <Td val={route.containerTypeName}/>
                                                <Td val={route.containerRoute}/>
                                                <Td val={route.containerPrefix}/>
                                                <Td val={formatDateTime(route.createdOn)}/>
                                                <Td val={route.createdBy}/>
                                                <Td val={formatDateTime(route.exportedOn)}/>
                                                <Td val={route.exportedBy}/>

                                            </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </CTabPane>
                        <CTabPane>
                            <Charges shipment={shipment} shipmentCharges={charges} onRefresh={refresh}
                                billingType={BillingType.Selling} chargeTypes={chargeTypes}/>
                        </CTabPane>
                        <CTabPane>
                            <Charges shipment={shipment} shipmentCharges={charges} onRefresh={refresh}
                                billingType={BillingType.Cost} chargeTypes={chargeTypes}/>
                        </CTabPane>
                        <CTabPane>
                            <NewTraces shipment={shipment}/>
                        </CTabPane>
                        <CTabPane>
                            <MissingTraces shipment={shipment}/>
                        </CTabPane>
                    </CTabContent>
                </CCard>
            </CTabs>

            {/* pieces modal */}


            {/* Items Modal */}
            {itemModalIsShowing && (
                <AddUpdateItems
                    countries={countries}
                    onChange={handleFormChange}
                    shipment={shipment}
                    isShowing={itemModalIsShowing}
                    index={itemIndex}
                    isEdit={isEditItem}
                    onClose={() => {
                        setItemModalIsShowing(false)
                        setIsEditItem(false)
                    }}
                />
            )}

            {editStockItem && (
                // <EditStockItem
                //     onChange={handleFormChange}
                //     shipment={shipment}
                //     isShowing={editStockItem}
                //     stock={stock}
                //     index={itemIndex}
                //     isEdit={isEditItem}
                //     onClose={() => {
                //         setEditStockItem(false)
                //         setIsEditItem(false)
                //         refresh()
                //     }}
                // />
                <EditItemsFromStock
                    account={shipment?.account}
                    stockItems={stock ?? []}
                    skus={skus}
                    shipment={shipment}
                    onSave={() => {
                        setEditStockItem(false)
                        dispatch(ShowNotification("Success", "Stock updated successfully", false))
                        refresh()
                    }}
                    onClose={() => {
                        setEditStockItem(false)
                    }}
                />
            )}


            <DeleteItemModal
                isShowing={deleteItemModal}
                onClose={() => {
                    setItemIndex(0);
                    setDeleteItemModal(false);
                }}
                onSave={handleDeleteItem}
            />


            {showAttachmentModal &&
                <AddAttachmentModal
                    handleClose={() => {
                        setAttachmentIndex(undefined)
                        setFileLoading(false)
                        setFileUploaded(false)
                        setFileLocation(undefined)
                        setShowAttachmentModal(false)
                    }}
                    handleAdd={(file) => handleAddAttachment(file)}
                    handleUpdate={handleUpdateAttachment}
                    attachments={shipment.attachments}
                    fileUploaded={fileUploaded}
                    location={fileLocation}
                    attachmentIndex={attachmentIndex}
                    uid={shipment.uid}
                    number={shipment.number}/>}

            {deleteAttachmentModal &&
                <ConfirmModal onClose={() => setDeleteAttachmentModal(false)} onSubmit={handleDeleteAttachment}
                    title={"Delete Attachment"} body={"Are you sure you want to delete this attachment"}/>}

            {(sendAttachmentToShipperConfirmModal || sendAttachmentToConsigneeConfirmModal) &&
                <ConfirmModal onClose={() => {
                    setSendAttachmentToShipperConfirmModal(false)
                    setSendAttachmentToConsigneeConfirmModal(false)
                }} onSubmit={() =>
                    handleSendAttachment(sendAttachmentToShipperConfirmModal ? "shipper" : "consignee")
                } title={"Send attachment"} body={
                    `Are you sure you want to send attachment to ${sendAttachmentToShipperConfirmModal ? "shipper" : "consignee"}`
                } />}

            {captureModal &&
                <CaptureImageModal

                    onClose={() => {
                        setCaptureModal(false)
                    }}
                    onSave={(e) => {
                        handleAddBulkAttachments(e)
                    }}
                />}
        </CCardBody>
    );
};

export default ShipmentTables;

import {CBadge, CButton, CCol, CDataTable, CInputCheckbox, CRow, CSpinner, CTooltip} from "@coreui/react";
import {IPaginatedResult, IPagination} from "../../../../models/filter";
import {BulkPayRequest, GeneratedInvoiceRequest, InvoiceDto, InvoiceStatus} from "../../../../models/accounting";
import React, {useEffect, useState} from "react";
import PageCount from "../../../SharedComponents/PageCount";
import AccountingClient from "../../../../clients/accountingClient";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router";
import {formatDate} from "../../../../utils/dateUtil";
import GeneratedInvoiceForm from "./GeneratedInvoiceForm";
import BulkPayInvoiceModal from "../modals/BulkPayInvoiceModal";
import {ShowNotification} from "../../../../store/actions/auth";
import {useDispatch} from "react-redux";

interface IProps {
    accountingProfiles:{ [k: string]: string }
}

const GeneratedInvoiceTable = (props: IProps) => {
    const accountingClient = new AccountingClient()
    const [loading, setLoading] = useState<boolean>(false)
    const [invoices, setInvoices] = useState<IPaginatedResult<InvoiceDto>>()
    const [selectedInvoices, setSelectedInvoices] = useState<number[]>([])
    const [selectAll, setSelectAll] = useState<boolean>(false)
    const [showBulkPayInvoices, setShowBulkPayInvoices] = useState<boolean>(false)
    const [paymentError, setPaymentError] = useState<string>()
    const [pagination, setPagination] = useState<IPagination>({
        limit: 20,
        offset: 0,
    });
    const [generatedInvoiceSearchRequest, setGeneratedInvoiceSearchRequest] = useState<GeneratedInvoiceRequest>({
        offset: 0,
        limit: 20
    })
    const hist = useHistory()
    const dispatch = useDispatch()


    const RefreshData = async (body: GeneratedInvoiceRequest) => {
        setLoading(true)
        const generated = await accountingClient.GetGeneratedInvoices(body)
        setInvoices(generated)
        setGeneratedInvoiceSearchRequest(body)
        setLoading(false)
        return generated
    }

    const HandleClear = () => {
        HandleSearch({
            limit: 20,
            offset: 0
        })
    }

    const HandleSearch = (body: GeneratedInvoiceRequest) => {
        RefreshData(body)
    }

  



    const handleSelect = (invoiceId: number) => {
        setSelectedInvoices(selected => {
            let newSelected = selected.includes(invoiceId) ?
                selected.filter(id => invoiceId !== id)
                : [...selected, invoiceId]

            newSelected.length == invoices?.matches.length ? setSelectAll(true) : setSelectAll(false)

            return newSelected
        });
    };

    const handleSelectAll = () => {
        setSelectAll(selected => {
            selected = !selected
            setSelectedInvoices(selected ? invoices?.matches.map(i => i.id) ?? [] : [])

            return selected
        });
    };

    const scopedSlots = {
        getExcel: (item: InvoiceDto) => {
            return <td>
                <CTooltip content={"View Invoice"}>
                    <FontAwesomeIcon icon={faEye} style={{cursor: "pointer"}}
                                     onClick={() => hist.push(`/financial/billing/${item.id}`)}/>
                </CTooltip>
            </td>
        },
        numberOfShipments: (item: InvoiceDto) => {
            return <td className={"text-center"}>{item.numberOfShipments}</td>
        },
        totalBeforeTax: (item: InvoiceDto) => {
            return <td
                className={"text-center"}>{item.taxableValue + item.taxLiquidatedValue + item.nonTaxableValue}</td>
        },
        totalIncludingTax: (item: InvoiceDto) => {
            return <td
                className={"text-center"}>{item.taxableValue + item.taxLiquidatedValue + item.nonTaxableValue + item.taxValue}</td>
        },
        taxType: (item: InvoiceDto) => {
            return <td className={"text-center"}>{item.taxType}</td>
        },
        status: (item: InvoiceDto) => {
            return <td>{InvoiceStatus[item.status]}</td>
        },
        paidAmount: (item: InvoiceDto) => {
            if (item.paidAmount)
                return <td
                className={"text-center"}>
                <div>{item.paidAmount ?? ""}</div>
                {item.status != InvoiceStatus.Paid && item.paidAmount && <CBadge
                    style={{height: "12px", fontSize: "10px"}}
                    shape="pill"
                    color="warning"
                >Partial</CBadge>}</td>
            else return <td />
        },
        select: (item: InvoiceDto) => {
            return <td className={"text-right"}><CInputCheckbox
                checked={selectAll || selectedInvoices.includes(item.id)}
                onChange={() => handleSelect(item.id)}/></td>
        },
        invoiceNumber: (item: InvoiceDto) => {
            return <td>
                <div>{item.alternativeInvoiceNumber ?? item.invoiceNumber ?? ""}</div>
                {item?.creditNoteId && (
                    <div><CBadge
                        style={{height: "12px", fontSize: "10px"}}
                        shape="pill"
                        color="danger"
                    >CN: {item?.creditNoteNumber ? item.creditNoteNumber : "Pending"}
                    </CBadge></div>
                )}</td>
        },
        invoiceDate: (item: InvoiceDto) => {
            return <td>{formatDate(item.invoiceDate)}</td>
        }
    }

    const handleBulkPayment = async (body: BulkPayRequest) => {
        setPaymentError(undefined)
        const rs = await accountingClient.BulkPayment(body)

        if (rs.succeeded) {
            dispatch(ShowNotification("Success", "Payment marked successfully", false))
            setShowBulkPayInvoices(false)
        } else if (rs.status == 400)
            setPaymentError(rs.data.SWException[0]);

    }

    return (
        <>
            <GeneratedInvoiceForm accountingProfiles={props.accountingProfiles} onClear={HandleClear} onSubmit={v => HandleSearch(v)}/>
            {loading ?
                <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                />
                :
                <>
                    <CDataTable
                        size={"sm"}
                        hover
                        items={invoices?.matches ?? []}
                        scopedSlots={scopedSlots}
                        fields={[
                            {key: "getExcel", label: ""},
                            {key: "invoiceNumber", label: "Number"},
                            {key: "invoiceDate", label: "Date"},
                            {key: "accountName", label: "Account"},
                            {key: "numberOfShipments", label: "Shipments", _style: {textAlign: "center"}},
                            {
                                key: "totalBeforeTax", label: "Total Before Tax", _style: {textAlign: "center"}
                            },
                            {
                                key: "totalIncludingTax", label: "Total Including Tax", _style: {textAlign: "center"}
                            },
                            {key: "status", label: "Status", _style: {width: "6px"}},
                            {key: "paidAmount", label: "Paid", _style: {textAlign: "center"}},
                            {
                                key: "select", label: (
                                    <CInputCheckbox style={{top: 1}} checked={selectAll}
                                    onChange={() => handleSelectAll()}/>
                                ),
                                _style: {textAlign: "right"}
                            }
                        ]}
                    />
                    <CRow>
                        <CCol>
                            <PageCount
                                onPageChange={(p) => {
                                    const offset = p * pagination.limit!
                                    HandleSearch({...generatedInvoiceSearchRequest, offset: offset})
                                    setGeneratedInvoiceSearchRequest({...generatedInvoiceSearchRequest, offset: offset})
                                    setPagination({...pagination, offset: offset})
                                    setSelectedInvoices([])
                                    setSelectAll(false)
                                }}
                                onPageSizeChange={(ps) => {
                                    HandleSearch({...generatedInvoiceSearchRequest, limit: ps})
                                    setGeneratedInvoiceSearchRequest({...generatedInvoiceSearchRequest, limit: ps})
                                    setPagination({...pagination, limit: ps})
                                    setSelectedInvoices([])
                                    setSelectAll(false)
                                }}
                                currentPage={pagination.offset! / pagination.limit!}
                                maxPage={Math.ceil(
                                    invoices?.total! / pagination.limit!
                                )}
                                maxFound={invoices?.total ?? 0}
                            />
                        </CCol>
                        <CCol className={"mt-3 text-right"}>
                            {(selectAll || selectedInvoices.length > 0) &&
                                <CButton size={"sm"} color={"primary"} variant={"outline"}
                                         onClick={() => setShowBulkPayInvoices(true)}>Mark
                                    ({selectAll ? invoices?.matches.length : selectedInvoices.length}) invoices as
                                    paid</CButton>}
                        </CCol>
                    </CRow>
                </>}

            {
                showBulkPayInvoices &&
                <BulkPayInvoiceModal invoiceIds={selectAll ? invoices?.matches.map(i => i.id) ?? [] : selectedInvoices}
                                     onClose={() => {
                                         setPaymentError(undefined)
                                         setShowBulkPayInvoices(false)
                                     }}
                                     onSubmit={v => handleBulkPayment(v)} error={paymentError}/>
            }
        </>
    )

}

export default GeneratedInvoiceTable
import {

    CRow,
    CCol,
    CButton,
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppModel, RemoteBlob} from "../../../../models/app";
import SWSelect from "../../../SharedComponents/SWSelect";
import SWInput from "../../../SharedComponents/SWInput";
import ActionButton from "../../../SharedComponents/ActionButton";
import {useHistory} from "react-router";
import {IAddAccount} from "../../../../models/shipment";
import {SearchAccountsRequest} from "../../../../models/accounting";
import {setAccountsFilterGroup} from "../../../../store/actions/accounts";
import {AccountFilterGroup} from "../../../../models/filter";

interface Props {
    newFilters: AccountFilterGroup,
    setNewFilters: React.Dispatch<React.SetStateAction<AccountFilterGroup>>
}

const AccountSearchForm = (props: Props) => {

    const history = useHistory();


    const [Filters, setFilters] = useState<AccountFilterGroup>(props.newFilters || {filters: {}});


    return (
        <fieldset className="b-fieldset p-2 text-primary">
            <legend className="b-legend" style={{width: "92px"}}>
                <h5>Accounts</h5>
            </legend>
            <CRow>
                <CCol md={3} sm={12}>
                    <CCol md={5} sm={12}>Account type</CCol>
                    <SWSelect
                        className={'mt-2'}
                        label={""}
                        name={`accountType`}
                        value={Filters?.filters?.accountType?.toString() ?? ""}
                        onChange={(e) => {
                            setFilters({
                                ...props.newFilters,
                                filters: {...Filters.filters, accountType: e ? parseInt(e) : undefined}
                            })
                        }}

                        values={[
                            {value: "1", label: "Customer"},
                            {value: "2", label: "Agent"}
                        ]}
                    />
                </CCol>
                <CCol md={3}>
                    <SWInput label={"Profile id"} name={"ProfileId"}
                             value={Filters?.filters?.profileId?.toString() ?? ""}
                             onChange={(e) => {
                                 setFilters({
                                     ...Filters,
                                     filters: {
                                         ...Filters.filters, profileId: e ? parseInt(e) : undefined
                                     }
                                 })
                             }}/>

                </CCol>
                <CCol md={3}>
                    <SWInput label={"Barcode"} name={"barcode"} value={""}/>
                </CCol>
                <CCol/>
                <CCol md={1}>
                    <CRow>
                        <ActionButton
                            text={"Search"}
                            onClick={() => {
                                props.setNewFilters(Filters);
                            }}
                        />
                    </CRow>
                </CCol>
                <CCol>
                    <CRow>
                        <ActionButton
                            text={"Create"}
                            color={"success"}
                            onClick={() => history.push({pathname: `/financial/account/create`})}
                        />
                    </CRow>
                </CCol>
            </CRow>
        </fieldset>
    );
};

export default AccountSearchForm;

import {Field, Form, Formik} from "formik";
import {CButton, CButtonGroup, CCol, CContainer, CLink, CRow, CSpinner} from "@coreui/react";
import InputField from "../../../SharedComponents/FormikCustomFields/InputField";
import React, {useEffect, useState} from "react";
import {IAddAccount, ShipmentAttachment, ShipmentGet} from "../../../../models/shipment";
import {object, string} from "yup";
import LookupsClient from "../../../../clients/lookupsClient";
import {SetLoading, ShowNotification} from "../../../../store/actions/auth";
import {useDispatch} from "react-redux";
import SWSelect from "../../../SharedComponents/SWSelect";
import AccountingClient from "../../../../clients/accountingClient";
import ActionButton from "../../../SharedComponents/ActionButton";
import AddAttachmentModal from "../../Shipments/modals/AddAttachmentModal";
import SWInput from "../../../SharedComponents/SWInput";
import CameraIcon from "../../../../icons/camera";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import {formatDateTime} from "../../../../utils/dateUtil";
import ShipClient from "../../../../clients/shipClient";

const AddAccountForm = ({setIsSubmitting}: any) => {
    const [countryLookups, setCountryLookups] = useState<{ [k: string]: string }>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)
    const [fileLoading, setFileLoading] = useState<boolean>(false)
    const [fileUploaded, setFileUploaded] = useState<boolean>(false)
    const [attachmentIndex, setAttachmentIndex] = useState<number>()
    const [fileLocation, setFileLocation] = useState<string>()
    const [isEditItem, setIsEditItem] = useState<boolean>(false);
    const [shipment, setShipment] = useState<ShipmentGet>({
        account: "",
        accountReference: "",
        agent: "",
        attachments: [],
        blocked: false,
        bookings: [],
        cOD: null,
        cODCurrency: "",
        chargeWeight: null,
        cod: null,
        codCurrency: "",
        comments: [],
        communications: [],
        consigneeAddressLine1: "",
        consigneeAddressLine2: "",
        consigneeAddressLine3: "",
        consigneeAddressReference: "",
        consigneeCity: "",
        consigneeCompanyName: "",
        consigneeCountry: "",
        consigneeEmail: "",
        consigneeEori: "",
        consigneeName: "",
        consigneeNotes: "",
        consigneePhone: "",
        consigneePostCode: "",
        consigneeReference: "",
        consigneeSecondPhone: "",
        consigneeState: "",
        containers: [],
        createdBy: "",
        createdOn: "",
        customsShippingCost: null,
        customsShippingCostCurrency: "",
        deleted: false,
        dimensionUnit: "",
        errors: [],
        extraServices: [],
        flags: "",
        hasBooking: false,
        hasErrors: false,
        id: 0,
        incoterm: "",
        ioss: "",
        items: [],
        missingTraces: [],
        newTraces: [],
        number: "",
        onlineCOD: 12,
        pendingPayment: false,
        pickupDate: null,
        pieces: [],
        routes: [],
        service: "",
        shipmentCod: [],
        shipmentTraceSummaryItem: null,
        shipperAddressLine1: "",
        shipperAddressLine2: "",
        shipperAddressLine3: "",
        shipperAddressReference: "",
        shipperCity: "",
        shipperCompanyName: "",
        shipperCountry: "",
        shipperEmail: "",
        shipperEori: "",
        shipperName: "",
        shipperNotes: "",
        shipperPhone: "",
        shipperPostCode: "",
        shipperReference: "",
        shipperSecondPhone: "",
        shipperState: "",
        totalItems: 0,
        totalPieces: 0,
        traceFlags: "",
        traces: [],
        uid: "",
        value: null,
        valueCurrency: "",
        weight: null,
        weightUnit: ""
    });


    const initialValues: IAddAccount = {
        accountType: "",
        dueDays: undefined,
        eori: "",
        id: "",
        name: "",
        preferredLangauges: "",
        profileId: undefined,
        taxType: "",
        state: "",
        street: "",
        postCode: "",
        email: "",
        country: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        phone: "",
        secondPhone: "",
        vat: "",
        city: ""
    };

    const lookups = new LookupsClient();
    const accountingClient = new AccountingClient();
    const dispatch = useDispatch();

    const validationSchema = () => object().shape({
        phone: string().required("required"),
        id: string().required("required"),
        secondPhone: string().required("required"),
        country: string().required("required"),
        city: string().required("required"),
        vat: string().required("required"),
        eori: string().required("required"),
        profileId: string().required("required"),
        email: string().required("required").email("please enter a valid email"),
        taxType: string().required("required"),
        dueDays: string().required("required"),
        addressLine1: string().required("required"),
        addressLine2: string().required("required"),
        addressLine3: string().required("required"),
        postCode: string().required("required"),
        accountType: string().required("required"),
        name:string().required("required")
    });

    const clearForm = (form: any) => {
        form.resetForm();
    };

    const getCountries = async () => {
        let countries = await lookups.getCountries();
        setCountryLookups(countries);
    };

    useEffect(() => {
        getCountries();
    }, []);
    
    
    
    
    const shipClient = new ShipClient();


    useEffect(() => {
        dispatch(SetLoading(true))
        shipClient.getShipment("411").then((v) => {
            setShipment(v);
        })
        }, []);
            const handleFileImport = async (file?: File) => {
        setFileUploaded(false)
        setFileLoading(true)
        if (!file) return
        let res = await shipClient.UploadFile(file, shipment.number)
        await setFileLocation(res.location)
        if (res.location) {
            setFileUploaded(true)
            setShowAttachmentModal(true)
            setFileLoading(false)
        }
        setIsEditItem(false)
    }

    const renderHeader = (header: string[]) => header.map((value, index) => {
        return (
            <th className="sticky-top bg-white" key={index}>
                {value}
            </th>
        );
    });

    const handleAddAttachment = async (attachment: ShipmentAttachment) => {
        setAttachmentIndex(undefined)
        const attachments = shipment.attachments
        attachments?.push(attachment)
        setShowAttachmentModal(false)
        // onUpdateShipment({...shipment, attachments: attachments})
    }

    const handleUpdateAttachment = (attachment: ShipmentAttachment) => {
        if (attachmentIndex == undefined) return
        let attachments = shipment.attachments
        attachments[attachmentIndex] = attachment
        setShowAttachmentModal(false)
        setAttachmentIndex(undefined)
        // onUpdateShipment({...shipment, attachments: attachments})
    }
    
    
    const handleSubmit = async (values: any, formikHelpers: any) => {
        setIsSubmitting(true);
        setIsLoading(true);

        try {
            const rs = await accountingClient.CreateAccount(values);
            console.log("rs:", rs, "loading: ", isLoading);

            if (rs.SWException == null) {
                dispatch(ShowNotification("Success", "Account created successfully", false));
                clearForm(formikHelpers);  // Clear the form upon successful submission
            } else {
                dispatch(ShowNotification("Failed", rs.SWException[0], true));
            }
        } finally {
            setIsSubmitting(false);
            setIsLoading(false);
        }
    };

    return (
        <fieldset className="b-fieldset p-2 text-primary">
            <legend className="b-legend" style={{width: "150px"}}>
                <h5>Create Account</h5>
            </legend>
            {isLoading ? (
                <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                />
            ) : (
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                >
                    {(formik) => (
                        <Form>
                            <CContainer className="form-group mb-0">
                                <CRow>
                                    <CCol className="col-sm-3">
                                        <Field type="string" name="id" label={"Id:"} component={InputField}/>
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field type="text" name="email" label={"Email:"} component={InputField}/>
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field type="text" name="name" label={"Name:"} component={InputField}/>
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field type="string" name="phone" label={"Phone:"} component={InputField}/>
                                    </CCol>

                                </CRow>
                                <CRow>
                                    <CCol className="col-sm-3">
                                        <Field type="string" name="secondPhone" label={"Second Phone:"}
                                               component={InputField}/>
                                    </CCol>
                                    <CCol md={3} sm={12}>
                                        <CCol md={4} sm={12}>Country:</CCol>
                                        <SWSelect
                                            label={""}
                                            style={{marginTop: 9}}
                                            validationErrors={formik.errors?.country?.toString() || ""}
                                            name={`country`}
                                            value={formik.values?.country ?? ""}
                                            onChange={(e) => formik.setFieldValue(`country`, e)}
                                            values={countryLookups}
                                        />
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field type="text" name="city" label={"City:"} component={InputField}/>
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field type="text" name="postCode" label={"Post code:"} component={InputField}/>
                                    </CCol>

                                </CRow>
                                <CRow>
                                    <CCol className="col-sm-3">
                                        <Field type="text" name="addressLine1" label={"Address Line 1:"}
                                               component={InputField}/>
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field type="text" name="addressLine2" label={"Address Line 2:"}
                                               component={InputField}/>
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field type="text" name="addressLine3" label={"Address Line 3:"}
                                               component={InputField}/>
                                    </CCol>
                                    <CCol md={3} sm={12}>
                                        <CCol md={6} sm={12}>Account type:</CCol>
                                        <SWSelect
                                            label={""}
                                            style={{marginTop: 9}}
                                            validationErrors={formik.errors?.accountType?.toString() || ""}
                                            name={`accountType`}
                                            value={formik.values?.accountType ?? ""}
                                            onChange={(e) => formik.setFieldValue(`accountType`, e)}
                                            values={[{value: "1", label: "Customer"}, {value: "2", label: "Agent"}]}
                                        />
                                    </CCol>

                                </CRow>
                                <CRow>
                                    <CCol md={3} sm={12}>
                                        <CCol md={6} sm={12}>Tax type:</CCol>
                                        <SWSelect
                                            label={""}
                                            style={{marginTop: 9}}
                                            validationErrors={formik.errors?.taxType?.toString() || ""}
                                            name={`taxType`}
                                            value={formik.values?.taxType ?? ""}
                                            onChange={(e) => formik.setFieldValue(`taxType`, e)}
                                            values={[{value: "EU", label: "EU"}, {
                                                value: "VAT",
                                                label: "VAT"
                                            }, {value: "NON-EU", label: "NON-EU"}]}
                                        />
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field type="text" name="dueDays" label={"Due days:"} component={InputField}/>
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field type="text" name="vat" label={"vat:"} component={InputField}/>
                                    </CCol>
                                    <CCol className="col-sm-3">
                                        <Field type="text" name="eori" label={"eori:"} component={InputField}/>
                                    </CCol>

                                </CRow>
                                <CRow><CCol className="col-sm-3">
                                    <Field type="text" name="profileId" label={"Profile Id:"}
                                           component={InputField}/>
                                </CCol></CRow>
                                <CRow>
                                    


                                    <>
                                        <CCol>
                                            <CRow className={" w-20 mt-3 px-2"}>
                                                {!fileLoading ? <SWInput type={"file"} id={"input-file"}
                                                                         onChangeFile={(f) => handleFileImport(f)}/>
                                                    : <div
                                                        className={"rounded mb-3 h-100 m-0 py-1 px-2 d-flex align-items-center justify-content-center border-primary"}>Please
                                                        wait...</div>}
                                            </CRow>
                                        </CCol>
                                    </>


                                    <table className="position-relative table table-hover table-sm">
                                        <thead>{renderHeader(["File Name", "Attachment Type", "Download", "Agent", "Created By",
                                            "Created On", "Edit"])}</thead>
                                        <tbody>
                                        {shipment.attachments?.length < 1 && (
                                            <div className="mr-2 ml-2 mt-2 mb-2">
                                                <span> No Available Attachments</span>
                                            </div>
                                        )}
                                        {shipment.attachments?.map((a, i) => (
                                            <tr key={i}>
                                                <td>{a.fileName}</td>
                                                <td>{a.type}</td>
                                                <td>
                                                    <CIcon
                                                        content={freeSet.cilCloudDownload}
                                                        style={{cursor: "pointer", margin: "auto"}}
                                                        onClick={() =>{}}
                                                    />
                                                    <CIcon
                                                        content={freeSet.cilCopy}
                                                        style={{cursor: "pointer", marginLeft: "8px"}}
                                                        onClick={() =>{}}
                                                    />
                                                </td>
                                                <td>{undefined ?? a.agent}</td>
                                                
                                                 
                                                
                                                   
                                                <td>{a.by}</td>
                                                <td>{formatDateTime(a.on)}</td>
                                                <td>
                                                    <CIcon
                                                        content={freeSet.cilPencil}
                                                        style={{cursor: "pointer"}}
                                                        onClick={() =>{}}

                                                    />
                                                    <CIcon
                                                        content={freeSet.cilTrash}
                                                        style={{cursor: "pointer", marginLeft: "10px"}}
                                                        onClick={() =>{}}

                                                    />
                                                </td>
                                            </tr>
                                        ))
                                        }
                                        </tbody>
                                    </table>

                                </CRow>
                              
                            </CContainer>
                            <CCol className="text-right">
                                <CButtonGroup className="mb-0 mr-2">
                                    <CButton
                                        variant="outline"
                                        size="sm"
                                        color="danger"
                                        onClick={() => clearForm(formik)}
                                        style={{fontSize: "10px"}}
                                    >
                                        Clear
                                    </CButton>
                                </CButtonGroup>
                                <CButtonGroup className="mb-0 ">
                                    <ActionButton text={"Add"}/>
                                </CButtonGroup>
                            </CCol>
                        </Form>
                    )}
                </Formik>
            )}
            {showAttachmentModal&&<AddAttachmentModal
                handleClose={() => {
                    setAttachmentIndex(undefined)
                    setFileLoading(false)
                    setFileUploaded(false)
                    setFileLocation(undefined)
                    setShowAttachmentModal(false)
                }}
                handleAdd={(file) => handleAddAttachment(file)}
                handleUpdate={handleUpdateAttachment}
                attachments={shipment.attachments}
                fileUploaded={fileUploaded}
                location={fileLocation}
                attachmentIndex={attachmentIndex}
                uid={shipment.uid}
                number={shipment.number}/>}
        </fieldset>


    );
}

export default AddAccountForm;

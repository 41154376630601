import {ChargeSearchFilters} from "../../../../models/accounting";
import {useFormik} from "formik";
import {
    CCol,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CInputCheckbox,
    CLabel,
    CRow
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import SWSelect from "../../../SharedComponents/SWSelect";
import RatesClient from "../../../../clients/ratesClient";
import SWTextArea from "../../../SharedComponents/SWTextArea";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import ActionButton from "../../../SharedComponents/ActionButton";
import AccountingClient from "../../../../clients/accountingClient";
import {Report} from "../../../../models/Report";
import LookupsClient from "../../../../clients/lookupsClient";
import {ChargeType} from "../../../../models/lookups";
import SWRadioButtons from "../../../SharedComponents/SWRadioButtons";


type Props = {
    onSubmit: (filterData: ChargeSearchFilters) => void
    initialValues:ChargeSearchFilters
    chargesType:{ [k: string]: string }
}

const BillingTypeValues = {
    '1': 'Cost',
    '2': 'Selling'
}

const RadioButtonValues=[
    {label:'All',value:null},
    {label:'Yes',value:true},
    {label:'No',value:false},
]

const ChargesSearchComponent = ({onSubmit,initialValues,chargesType}: Props) => {
    let ratesClient = new RatesClient()
    let lookupsClient = new LookupsClient()
    const accountingClient = new AccountingClient()
    const [customerAccounts, setCustomerAccounts] = useState<{ [k: string]: string }>({})
    const [accounts, setAccounts] = useState<{ [k: string]: string }>({})
    const [profile, setProfile] = useState<{ [k: string]: string }>({})
    const [report, setReport] = useState<Report[]>([])
    const [isReportDownloadLoader,setIsReportDownloadLoader]=useState(false)


    const {setFieldValue, values, handleSubmit} = useFormik<ChargeSearchFilters>({
        initialValues: initialValues,
        onSubmit: (value: ChargeSearchFilters) => {
            onSubmit(value)
        }

    })
    useEffect(() => {

        ratesClient.LookupCustomers().then(ca => setCustomerAccounts(ca))
        ratesClient.GetAccounts().then(a => setAccounts(a))
        accountingClient.LookupProfiles().then(p => setProfile(p))

    }, []);
    useEffect(() => {
        lookupsClient.GetUiReports(values.isGroupedSearch ? "ChargesSearchGrouped" : "ChargesSearch").then(v => setReport(v))

    }, [values.isGroupedSearch]);


    const excelHandler = async (r: number) => {
        setIsReportDownloadLoader(true)
        const {isGroupedSearch, ...rest} = values
        let res
        if (values.isGroupedSearch) {
            res = res = await accountingClient.ChargeExcelGroupedExport({
                ...rest,
                reportId: r
            })
        } else {
            res = await accountingClient.ChargeExcelExport({
                ...rest,
                reportId: r
            })
        }
        if (res) {
            const link = document.createElement('a');
            link.href = res.location;
            link.download = res.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        setIsReportDownloadLoader(false)

    }
    return (
        <>
            <fieldset className="b-fieldset  text-primary">
                <legend className="b-legend" style={{width: "70px"}}>
                    <h5>Charges</h5>
                </legend>
                <CRow className={'align-items-center justify-content-start m-0 p-2'} >
                    <CCol md={12} >
                    <CRow className={'align-items-center justify-content-start'} >
                        <CCol md={3} className={'mt-0 p-0 pl-3 justify-content-start align-items-start'}>
                            <SWTextArea value={values.shipmentNumbers?.join('\n')} label={'Shipment Numbers'}
                                        name={"shipment"} rows={6}
                                        onChangeFormik={(e) => {
                                            setFieldValue('shipmentNumbers', e.target.value.toUpperCase().split('\n') as string)
                                        }}
                            />
                        </CCol>
                        <CCol md={2}>
                            <SWSelect values={customerAccounts} value={values.customerAccount} name={"CustomerAccount"}
                                      isClearable={true}
                                      onChange={e => setFieldValue("customerAccount", e ?? "")}
                                      label={"Customer Account"}/>
                        </CCol>

                        <CCol md={2}>
                            <SWSelect values={profile} value={values.profileId?.toString()}
                                      label={"Profile"}
                                      onChange={v => setFieldValue('profileId', Number(v))} isClearable/>
                        </CCol>
                        <CCol md={2}>
                            <SWSelect values={chargesType} value={values.typeId}
                                      label={"Type"}
                                      onChange={v => setFieldValue('typeId', v)} isClearable/>
                        </CCol>
                        <CCol  md={3} className={'justify-content-start'}>
                            <CRow style={{marginLeft: "37px", display: 'flex'}}>
                                <CInputCheckbox
                                    checked={values.excludeDeleted}
                                    onChange={() => {
                                        setFieldValue("excludeDeleted", !values.excludeDeleted)
                                    }}
                                /> <CLabel>Exclude Deleted</CLabel>
                            </CRow>
                                <SWRadioButtons title={'Invoiced'} values={RadioButtonValues} valueInput={values.invoiced ?? null} onChange={(e)=>setFieldValue('invoiced',e as boolean | null)}/>
                                <SWRadioButtons title={'Label Generated'} values={RadioButtonValues} valueInput={values.labelGenerated ?? null} onChange={(e)=>setFieldValue('labelGenerated',e as boolean | null)}/>
                        </CCol>
                    </CRow>
                    </CCol>

                    <CCol md={3}>
                        <SWSelect values={accounts} value={values.account} name={"Accounts"}
                                  isClearable={true}
                                  onChange={e => setFieldValue("account", e ?? "")} label={"Account"}/>
                    </CCol>
                    <CCol md={3}>
                        <SWSelect values={BillingTypeValues} value={values.billingType?.toString()} name={"billingType"}
                                  isClearable={true}
                                  onChange={e => setFieldValue("billingType", Number(e) ?? "")} label={"Billing Type"}/>
                    </CCol>
                    <CCol md={3}>
                        <SWDateAndTimePicker isClearable={true} label={'Pickup Date From'} value={values.pickupDateFrom}
                                             handleOnChange={e => setFieldValue("pickupDateFrom", e)}/>
                    </CCol>
                    <CCol md={3}>
                        <SWDateAndTimePicker isClearable={true} label={'Pickup Date To'} value={values.pickupDateTo}
                                             handleOnChange={e => setFieldValue("pickupDateTo", e)}/>
                    </CCol>
                    <CCol md={12}>
                        <CRow rows={1} style={{display: 'flex'}}
                              className={'justify-content-between align-items-center'}>
                            <CRow style={{marginLeft: "37px", display: 'flex'}}>
                                <CInputCheckbox
                                    checked={values.isGroupedSearch}
                                    onChange={() => {
                                        setFieldValue("isGroupedSearch", !values.isGroupedSearch)
                                        handleSubmit()
                                    }}
                                /> <CLabel>Grouped</CLabel>
                            </CRow>
                            <CRow className={'p-1 mr-1'}>
                                <CDropdown >
                                    <CDropdownToggle disabled={isReportDownloadLoader} color="success" variant={"outline"} size={"sm"}
                                                     style={{fontSize: "10px", marginRight: '6px'}}>Download
                                        Report</CDropdownToggle>
                                    <CDropdownMenu>
                                        {report?.map((r) => (
                                            <CDropdownItem onClick={() => excelHandler(r.id)}
                                                           key={r.id}>{r.name}</CDropdownItem>
                                        )) ?? <CDropdownItem disabled>No Reports Configured</CDropdownItem>}
                                    </CDropdownMenu>
                                </CDropdown>
                                <ActionButton text={'Search'} onClick={(e) => {
                                    e.preventDefault()
                                    handleSubmit()
                                }}/>

                            </CRow>
                        </CRow>

                    </CCol>
                </CRow>
            </fieldset>

        </>
    )
}

export default ChargesSearchComponent

import {
    CButton,
    CCard,
    CCol,
    CCollapse,
    CContainer,
    CDataTable,
    CInputCheckbox,
    CSpinner,
    CTooltip
} from "@coreui/react";
import {
    BillingType,
    GenerateInvoiceRequest, IgnoreInvoice,
    PendingInvoice,
    PendingShipmentsResponse,
} from "../../../../models/accounting";
import React, {useEffect, useState} from "react";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import ConfirmModal from "../../../SharedComponents/ConfirmModal";
import AccountingClient from "../../../../clients/accountingClient";
import {useHistory, useLocation} from "react-router";
import SWDateAndTimePicker from "../../../SharedComponents/SWDateAndTimePicker";
import {faExclamationCircle, faEye} from "@fortawesome/free-solid-svg-icons";
import {downloadBlob} from "../../../../utils/downloadUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CheckoutClient from "../../../../clients/checkoutClient";
import SWSelect from "../../../SharedComponents/SWSelect";
import ShipmentsWithoutCharges from "./ShipmentsWithoutCharges";
import LookupsClient from "../../../../clients/lookupsClient";
import {useDispatch} from "react-redux";
import {SetLoading, ShowNotification} from "../../../../store/actions/auth";

interface IProps {
    accountingProfiles:{ [k: string]: string }
}

const PendingInvoiceTable = (props: IProps) => {
    const accountingClient = new AccountingClient();
    const lookupsClient = new LookupsClient();
    const hist = useHistory()
    const [showConfirmInvoiceModal, setShowConfirmInvoiceModal] = useState<boolean>(false)
    const [selectedRecordToInvoice, setSelectedRecordToInvoice] = useState<PendingInvoice>()
    const [pending, setPending] = useState<PendingInvoice[]>([])
    const [pendingNonCharges, setPendingNonCharges] = useState<PendingInvoice[]>([])
    const [pickupDate, setPickupDate] = useState<Date>(new Date(new Date().setDate(0)))
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [selectedRecords, setSelectedRecords] = useState<string[]>([])
    const [allSelected, setAllSelected] = useState<boolean>(false)
    const [showInvoiceMultipleAccountsModal, setShowInvoiceMultipleInvoicesModal] = useState<boolean>(false)
    const [showIgnoreInvoiceModal, setShowIgnoreInvoiceModal] = useState<boolean>(false)
    const [selectedInvoiceToIgnore, setSelectedInvoiceToIgnore] = useState<PendingInvoice>()

    const [details, setDetails] = useState<string[]>([])
    const [nonChargedDetails, setNonChargedDetails] = useState<{ [accountId: string]: PendingShipmentsResponse[] }>({})
    const [nonChargedDetailsLoading, setNonChargedDetailsLoading] = useState<{ [accountId: string]: boolean }>({})
    const [chargeTypes, setChargeTypes] = useState<{ [k: string]: string }>({});

    const [selectedProfile, setSelectedProfile] = useState<string>()

    const loc = useLocation()

    const dispatch = useDispatch()
    const toggleDetails = async (account: string) => {

        const position = details?.indexOf(account)
        let newDetails = details?.slice()
        if (position !== -1) {
            newDetails?.splice(position, 1)
        } else {
            newDetails = [...details, account]
        }
        setDetails(newDetails)
        if (position !== -1)
            return;
        setNonChargedDetailsLoading({...nonChargedDetailsLoading, [account]: true})
        const shipments = await accountingClient.GetPendingCharges({
            account: account,
            pickupDate: pickupDate.toDateString(),
            type: BillingType.Selling
        });
        setNonChargedDetailsLoading({...nonChargedDetailsLoading, [account]: false})
        setNonChargedDetails({...nonChargedDetails, [account]: shipments})
    }

    useEffect(() => {
        if (isLastDay(new Date())) setPickupDate(new Date())
        lookupsClient.getCharges({availableOn: "shipment", format: 1}).then(c => setChargeTypes(c))
    }, [])


    const handleSearch = async () => {
        dispatch(SetLoading(true))
        setSubmitting(true)
        let pendingInvoices = await accountingClient.GetPendingInvoice({
            pickupDate: pickupDate.toDateString(),
            profileId: selectedProfile
        })
        if (pendingInvoices.succeeded) {
            const withCharges = pendingInvoices.data.filter((d: any) => d.amountTaxable != 0 || d.amountNonTaxable != 0 || d.amountLiquidationTax != 0)
            const withoutCharges = pendingInvoices.data.filter((d: any) => d.amountTaxable == 0 && d.amountNonTaxable == 0 && d.amountLiquidationTax == 0)
            setPending(withCharges)
            setPendingNonCharges(withoutCharges)
        }
        setDetails([])
        setSubmitting(false)
        setNonChargedDetailsLoading({})
        setNonChargedDetails({})
        return pendingInvoices
    }

    const isLastDay = (dt: Date) => {
        return new Date(dt.getTime() + 86400000).getDate() === 1;
    }

    const GetExcelFile = async (item: PendingInvoice) => {
        setSubmitting(true)
        const body: GenerateInvoiceRequest = {
            account: item.account,
            pickupDate: pickupDate.toDateString()
        }

        const remoteBlob = await accountingClient.GetExcelInvoiceDescription(body)
        downloadBlob(remoteBlob.location, remoteBlob.name)
        setSubmitting(false)
    }

    const Invoice = async (account: string) => {
        const body: GenerateInvoiceRequest = {
            account: account,
            pickupDate: pickupDate.toDateString()
        }
        const result = await accountingClient.Invoice(body)
        setShowConfirmInvoiceModal(false)
        return result
    }

    const InvoiceMultiple = async () => {
        if (!selectedRecords || selectedRecords.length == 0) return
        await Promise.all(selectedRecords.map(async (a) => {
            await Invoice(a);
        }));

        setSelectedRecords([]);
        setShowInvoiceMultipleInvoicesModal(false);
        await handleSearch();
    }

    const handleIgnoreInvoice = async () => {
        const rq: IgnoreInvoice = {
            ignore: true,
            account: selectedInvoiceToIgnore?.account,
            pickupDate: pickupDate
        }
        const succeeded = await accountingClient.ModifyIgnoreInvoice(rq);
        if (succeeded) {
            dispatch(ShowNotification("Success", "Invoice ignored successfully", false))
        }
        setShowIgnoreInvoiceModal(false)
        setSelectedInvoiceToIgnore(undefined)
        await handleSearch()
    }

    const HandleSelectItem = (item: PendingInvoice) => {
        const checked = selectedRecords.includes(item.account)
        if (checked) {
            let filteredRecords = selectedRecords.filter(i => i != item.account)
            setSelectedRecords(filteredRecords)
        } else {
            let filteredRecords = [...selectedRecords]
            filteredRecords.push(item.account)
            setSelectedRecords(filteredRecords)
        }
    }

    const handleSelectAll = () => {
        if (!allSelected) {
            const records = pendingNonCharges.map(i => i.account)
            setSelectedRecords(records)
            setAllSelected(true)
        } else {
            setSelectedRecords([])
            setAllSelected(false)
        }
    }

    const scopedSlots = {
        getExcel: (item: PendingInvoice) => {
            return <td>
                <CIcon content={freeSet.cilCloudDownload} size="lg"
                       onClick={async () => GetExcelFile(item)}
                       style={{cursor: "pointer"}}
                />
            </td>
        },
        numberOfShipments: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.numberOfShipments}</td>
        },
        amountTaxable: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.amountTaxable}</td>
        },
        amountLiquidationTax: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.amountLiquidationTax}</td>
        },
        amountNonTaxable: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.amountNonTaxable}</td>
        },
        amountTax: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.amountTax}</td>
        },
        taxType: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.taxType || ""}</td>
        },
        action: (item: PendingInvoice) => {
            return <td className={"text-center"}>
                <CTooltip content={"Invoice"}>
                    <CIcon
                        content={freeSet.cilDescription}
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            setSelectedRecordToInvoice(item)
                            setShowConfirmInvoiceModal(true)
                        }}
                    />
                </CTooltip>
                <CTooltip content={"Ignore"}>
                    <CIcon content={freeSet.cilX}
                           className={"ml-2"}
                           style={{cursor: "pointer"}}
                           onClick={() => {
                               setSelectedInvoiceToIgnore(item)
                               setShowIgnoreInvoiceModal(true)
                           }}
                    />
                </CTooltip>
            </td>
        },
        hasNonChargedShipments: (item: PendingInvoice, index: number) => {
            return item.hasNonChargedShipments ? <td className={"text-end"}>
                <CTooltip content="Shipments without selling charges">
                    <CContainer>
                        <FontAwesomeIcon icon={faExclamationCircle} size="lg"
                                         onClick={() => toggleDetails(item.account)}
                                         color={"#FF8800"}
                                         style={{cursor: "pointer"}}
                        />
                    </CContainer>
                </CTooltip>
            </td> : <td></td>
        },

        select: (item: PendingInvoice) => {
            return <td className={"text-center"}>
                <CInputCheckbox checked={selectedRecords.includes(item.account)}
                                onChange={() => HandleSelectItem(item)}/>
            </td>
        },
        "details":
            (item: PendingInvoice, index: number) => {
                return (
                    <CCollapse show={details.includes(item.account)}>
                        <ShipmentsWithoutCharges shipments={nonChargedDetails[item.account] ?? []}
                                                 loading={nonChargedDetailsLoading[item.account]}
                                                 accountingClient={accountingClient} chargeTypes={chargeTypes}/>
                    </CCollapse>
                )
            }
    }

    const nonChargesScopedSlots = {
        numberOfShipments: (item: PendingInvoice) => {
            return <td className={"pl-4"}>{item.numberOfShipments}</td>
        },
        taxType: (item: PendingInvoice) => {
            return <td className={"text-center"}>{item.taxType || ""}</td>
        },
        hasNonChargedShipments: (item: PendingInvoice, index: number) => {
            return item.hasNonChargedShipments ? <td className={"text-end"}>
                <CTooltip content="Shipments without selling charges">
                    <CContainer>
                        <FontAwesomeIcon icon={faExclamationCircle} size="lg"
                                         onClick={() => toggleDetails(item.account)}
                                         color={"#FF8800"}
                                         style={{cursor: "pointer", marginLeft: "45%"}}
                        />
                    </CContainer>
                </CTooltip>
            </td> : <td></td>
        },
        "details":
            (item: PendingInvoice, index: number) => {
                return (
                    <CCollapse show={details.includes(item.account)}>
                        <ShipmentsWithoutCharges shipments={nonChargedDetails[item.account] ?? []}
                                                 loading={nonChargedDetailsLoading[item.account]}
                                                 accountingClient={accountingClient} chargeTypes={chargeTypes}/>
                    </CCollapse>
                )
            }
    }

    return (
        <>
            <CCol className="col-md-12 d-flex align-items-center mt-2">
                <CCol md={3}>
                    <SWSelect values={props.accountingProfiles} value={selectedProfile} label={"Profile"}
                              onChange={v => setSelectedProfile(v)} isClearable/>
                </CCol>
                <CCol md={3}>
                    <SWDateAndTimePicker value={pickupDate} handleOnChange={v => setPickupDate(new Date(v))}
                                         label={"Date"}/>
                </CCol>
                <CCol>
                    <CButton variant={"outline"} color={"primary"} size={"sm"} className={"mt-2"}
                             onClick={() => handleSearch()}>Search</CButton>
                </CCol>
                {selectedRecords.length > 0 ?
                    <CCol md={3} className={"mr-0 mt-3 text-right"}>
                        <CButton
                            size="sm"
                            style={{fontSize: "10px", marginBottom: "10px"}}
                            variant="outline"
                            color="primary"
                            onClick={() => {
                                setShowInvoiceMultipleInvoicesModal(true)
                            }
                            }
                        >
                            Generate {selectedRecords.length} invoice{selectedRecords.length > 1 ? 's' : ''}
                        </CButton>
                    </CCol> : <div style={{height: 35}}></div>
                }
            </CCol>
            {submitting ?
                <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{width: "5em", height: "5em"}}
                />
                :
                <CContainer>
                    <CDataTable
                        size={"sm"}
                        hover
                        items={pending ?? []}
                        scopedSlots={scopedSlots}
                        columnHeaderSlot={{
                            select: <CInputCheckbox checked={allSelected} onChange={() => handleSelectAll()}
                                                    style={{top: 0}}/>
                        }}
                        fields={[
                            {key: "getExcel", label: ""},
                            {key: "hasNonChargedShipments", label: ""},
                            {key: "accountName", "label": "Account"},
                            {key: "taxType", label: "Tax Type", _style: {textAlign: "center"}},
                            {key: "numberOfShipments", label: "Shipments", _style: {textAlign: "center"}},
                            {key: "amountTaxable", label: "Taxable Amount", _style: {textAlign: "center"}},
                            {
                                key: "amountLiquidationTax",
                                label: "Tax Liquidated Amount",
                                _style: {textAlign: "center"}
                            },
                            {key: "amountNonTaxable", label: "Non Taxable Amount", _style: {textAlign: "center"}},
                            {key: "amountTax", label: "Amount Tax", _style: {textAlign: "center"}},
                            {
                                key: "action",
                                label: "Action",
                                _style: {textAlign: "center", width: '5%', padding: '0.2rem'}
                            },
                            {key: "select", label: "", _style: {textAlign: "center", width: '5%', padding: '0.2rem'}}
                        ]}
                    />
                    <br/>
                    <CDataTable
                        size={"sm"}
                        hover
                        items={pendingNonCharges ?? []}
                        scopedSlots={nonChargesScopedSlots}
                        fields={[
                            {key: "hasNonChargedShipments", label: "", _style: {width: "10%"}},
                            {key: "accountName", "label": "Account", _style: {width: "14%"}},
                            {key: "taxType", label: "Tax Type", _style: {textAlign: "center", width: "10%"}},
                            {key: "numberOfShipments", label: "Shipments"},
                        ]}
                    />
                </CContainer>

            }

            {showConfirmInvoiceModal && <ConfirmModal onClose={() => {
                setSelectedRecordToInvoice(undefined)
                setShowConfirmInvoiceModal(false)
            }} onSubmit={async () => {
                setSubmitting(true)
                Invoice(selectedRecordToInvoice?.account ?? "").then(v => {
                    setSubmitting(false)
                    if (v.invoiceId) {
                        dispatch(ShowNotification("Success", "Invoice generated successfully"))
                        hist.push(`/financial/billing/${v.invoiceId}`)
                    }
                })
                await handleSearch()
            }}
                                                      title={`Invoice: ${selectedRecordToInvoice?.accountName}`}
                                                      body={"Are you sure you want to invoice this account?"}/>
            }

            {showInvoiceMultipleAccountsModal &&
                <ConfirmModal onClose={() => setShowInvoiceMultipleInvoicesModal(false)}
                              onSubmit={() => {
                                  setSubmitting(true)
                                  InvoiceMultiple().then(() => {
                                      setSubmitting(false)
                                  })
                              }} title={"Invoice multiple customers"}
                              body={`Are you sure you want to invoice these costumers: ${selectedRecords.join(", ")}`}
                              submitting={submitting}
                />}
            {showIgnoreInvoiceModal &&
                <ConfirmModal onClose={() => {
                    setSelectedInvoiceToIgnore(undefined)
                    setShowIgnoreInvoiceModal(false)
                }} onSubmit={() => handleIgnoreInvoice()}
                              title={"Ignore invoice"}
                              body={`Are You sure you want to ignore invoice for account: '${selectedInvoiceToIgnore?.accountName}'`}/>}
        </>
    )
}

export default PendingInvoiceTable

export enum FilterType {
  Equals,
  Like,
  NotEquals,
  GreaterThan,
  LessThan,
  OneOf = 8,
  Range = 21,
  None,
}

export type InputType = "text" | "select" | "date" | "datetime";

export interface Filter {
  field: string;
  id?: number;
  type: FilterType;
  value: string | number | Date;
  lookUp?: { [k: string]: string };
  inputType?: InputType;
  [k: string]: any;
}
export interface accountFilter {
  id?: string;
  name?: string;
  reference?: string;
  notes?: string;
  email?: string;
  phone?: string;
  country?: string;
  city?: string;
  vat?: string;
  eori?: string;
  taxType?: string;
  accountType?: number;
  dueDays?: number;
  profileId?: number;
}

export interface FilterGroup {
  filters?: Filter[];
  page?: number;
  pageSize?:number
  limit?: number;
}

export interface accountFilterGroup {
  filters?: accountFilter;
  page?: number;
  pageSize?:number
  limit?: number;
}


export interface accountFilter {
  name?: string;
  id?: string;
  accountType?: number;
  profileId?:number;
  country?:string;
  phone?:string;
}
export interface AccountFilterGroup {
  filters?: accountFilter;
  page?: number;
  pageSize?:number
  limit?: number;
}

export interface InputFilter {
  title?: string;
  field: string;
  lookUp?: { [k: string]: string };
  inputType?: "text" | "range" | "date";
  value?: any;
  operator?: string;
  id?: string;
}


export interface IPagination{
  offset?:number;
  limit?:number;
}

export interface ISearchyPaging{
  pageSize?: number
  pageIndex?: number
}
export interface IPaginatedResult<T>{
  total:number;
  matches:T[]
  offset?:number;
  limit?:number;
}
export interface BasePagedSearch {
  lookup?: boolean;
  limit?: number | null;
  offset?: number | null;
}

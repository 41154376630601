import {
  CButton,
  CButtonGroup,
  CButtonToolbar,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CImg,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLink,
  CRow,
} from "@coreui/react";
import * as icons from "@coreui/icons";
import React, { useEffect, useState } from "react";
import CIcon from "@coreui/icons-react";
import * as msal from "@azure/msal-browser";
import cookieManager from "../../../services/cookieManager";
import { SetLoggingIn, SetUser } from "../../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Redirect, useHistory } from "react-router";
import { getMsalInstance } from "../../../utils/msalUtils";
import { AppModel } from "../../../models/app";
import jwt from "jsonwebtoken";
import { Form, Input, Submit } from "../../SharedComponents/SWForm";
import AuthClient from "../../../clients/authClient";

interface Props {}
const Login = (props: Props) => {
  const loggingIn = useSelector((state: AppModel) => state.app.loggingIn);
  const authClient = new AuthClient();
  const dispatch = useDispatch();
  const user = useSelector((state: AppModel) => state.app.user);

  const login = (jwtToken: string, refreshToken?: string) => {
    const info = jwt.decode(jwtToken)! as { [k: string]: any };
    cookieManager.setAccessToken(jwtToken);
    dispatch(SetLoggingIn(false));
  };

  const [loginError, setLoginError] = useState("");

  useEffect(() => {
    setLoginError(cookieManager.getCookieValue("loginError"));
  }, []);

  if (loggingIn) {
    return (
      <CContainer>
        <h5 className="text-center">Logging you in...</h5>
      </CContainer>
    );
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      {user && <Redirect to="/" />}
      <CContainer>
        <CRow className="justify-content-around">
          <CCol lg={8}>
            <CCard className="p-5 shadow-lg">
              <CCardHeader>
                <CImg
                  className="d-block mx-auto mb-2"
                  width="25%"
                  fluid
                  src="logo.svg"
                />
              </CCardHeader>
              <CCardBody className="p-4">
                <CRow>
                  <CCol>
                    <CForm
                      onSubmit={async (body) => {
                        return true;
                      }}
                      method="post"
                      action={`${authClient.BaseUrlNoApi}/login?returnUrl=${window.location.href}`}
                    >
                      <CRow>
                        <CCol>
                          <h5 color="secondary">Login</h5>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <CInputGroup className="my-3">
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon content={icons.freeSet.cilUser} />
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              placeholder="username"
                              name="username"
                              id="username"
                              type="text"
                            />
                          </CInputGroup>
                        </CCol>
                      </CRow>

                      <CRow>
                        <CCol>
                          <CInputGroup className="mb-3">
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon content={icons.freeSet.cilLockLocked} />
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput
                              name="password"
                              id="password"
                              type="password"
                              placeholder="password"
                            />
                          </CInputGroup>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol>
                          <CButtonGroup className="my-2">
                            <CButton
                              type="submit"
                              variant="outline"
                              color="primary"
                            >
                              Login
                            </CButton>
                            <CButton
                              className="ml-2"
                              variant="ghost"
                              color="secondary"
                            >
                              Forgot password?
                            </CButton>
                          </CButtonGroup>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCol>
                  <CCol className="divider-left">
                    <CRow>
                      <CCol>
                        <h5 style={{ textAlign: "center" }}>
                          Or sign in using the below
                        </h5>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol>
                        <CButtonToolbar justify="center" className="my-2">
                          <CForm
                            method="post"
                            onSubmit={(e) => {
                              SetLoggingIn(true);
                              return true;
                            }}
                            action={`${authClient.BaseUrlNoApi}/external/login/microsoft?returnUrl=${window.location.href}`}
                          >
                            <CButton
                              className="p-3 mx-2 px-4"
                              color="primary"
                              variant="outline"
                              type="submit"
                            >
                              <CIcon
                                className="mr-2"
                                content={icons.brandSet.cibMicrosoft}
                              />
                              Microsoft
                            </CButton>
                          </CForm>
                          <CButton
                            className="p-3 mx-2 px-4"
                            color="warning"
                            variant="outline"
                            disabled
                          >
                            <CIcon
                              className="mr-2"
                              content={icons.brandSet.cibGoogle}
                            />
                            Google
                          </CButton>
                        </CButtonToolbar>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter>
                <span className="text-danger">{loginError}</span>
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;

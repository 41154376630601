import {IPagination} from "./filter";
import {BookingSearch, StandardShipment} from "./shipment";
import InvoiceBillingAddressForm from "../components/ViewComponents/Accounting/components/InvoiceBillingAddressForm";
import {Account} from "./account";

export enum BillingType {
    Cost = 1,
    Selling = 2
}

export enum InvoiceStatus {
    Draft,
    Issued,
    Sent,
    Paid
}

export interface ChargeAddUpdate {
    account: string
    currency: string
    id: number
    typeId: string
    shipmentUid?: string
    billingType: number
    value: number
    taxRate: number
    taxCode?: string
    comments?: string
    isReadyToBeInvoiced: boolean
    isTaxable: boolean
    addedManually: boolean
    isGrouped: boolean
    taxValue?: number
    totalValue?: number
}

export interface ChargeSearch extends ChargeAddUpdate {
    alternativeInvoiceNumber?: string;
    totalValue: number
    taxValue: number
    createdOn: string
    createdBy: string
    invoiceNumber?: string
    quotationNumber?: string
    isReadyToBeInvoiced: boolean,
    deleted?: boolean,
    customerAccountName?: string
    billingTypeText?: string
    invoiceId?:number
    weightValue?: number;
    weightUnit?: string;
    pickupDate?: string;
    isGroupedText?: string
    shipmentNumber?:string
    typeName?:string








}

export interface PendingInvoice {
    allowAllProfiles: boolean;
    taxType: string;
    amountTax: number;
    account: string
    accountName: string
    numberOfShipments: number
    amountTaxable: number
    amountLiquidationTax: number
    amountNonTaxable: number
    taxCode: string
    allowedProfileIds: string[]
    hasNonChargedShipments: boolean

}

export interface IgnoreInvoice {
    ignore: boolean;
    account?: string;
    pickupDate: Date
}

export interface ChargeDto {
    comments: string;
    shipmentUid: string;
    taxCode: string | undefined;
    shipmentNumber: string;
    id: number;
    taxRate: number,
    typeId: string,
    account: string
    type: BillingType,
    value: number
    taxValue: number
    isGrouped: boolean
}

export interface InvoiceDto {
    paidAmount?: number;
    creditNoteNumber: string;
    creditNoteId?: number;
    creditedInvoiceId?: number
    creditedInvoiceNumber?: string
    alternativeInvoiceNumber: string;
    id: number
    status: InvoiceStatus
    invoiceNumber: string
    allowAllProfiles: boolean;
    allowedProfileIds?: string[];
    taxLiquidatedValue: number;
    taxType: string;
    quotationNumber: string;
    account: string
    accountName: string
    numberOfShipments: number
    invoiceDate: string
    quotationCreatedIn: string
    taxableValue: number
    nonTaxableValue: number
    taxValue: number
    attachments: InvoiceAttachment[]
    summaryCharges: ChargeDto[]
    detailedCharges: ChargeDto[]
    sentOn: string
    sentBy: string
    paidOn: string
    paidBy: string
    externalPaymentReference: string
    billingAddress?: InvoiceBillingAddress,
    preferredLanguages: string[]
    dueDays: number
    paymentAmount?: number
}

export interface GenerateInvoiceRequest {
    account: string
    pickupDate: string
}

export interface PendingInvoicesRequest {
    pickupDate?: string
    profileId?: string
}

export interface PendingInvoicesRequest {
    pickupDate?: string
    profileId?: string
}

export interface GeneratedInvoiceRequest extends IPagination {
    profileId?: string
    status?: string
    account?: string
    invoiceNumber?: string
    invoiceDate?: string
    invoiceDateFrom?: string
    invoiceDateTo?: string
    format?: number
    reportId?: number
    hasPartialPayment?: boolean
}

export interface PendingShipmentsRequest {
    pickupDate?: string
    account?: string
    type?: BillingType,
}

export interface PendingShipmentsResponse {
    uid: string
    number: string,
    account: string,
    shipmentData: StandardShipment,
    bookings: BookingSearch[]
}

export enum InvoiceAttachmentType {
    ExcelDescription,
    Invoice,
    Other
}

export interface InvoiceAttachment {
    id: number
    name: string
    location: string
    type: InvoiceAttachmentType
    includeInInvoiceEmail: boolean
}

export interface CalculateTaxRequest {
    typeId: string
    account: string
    shipmentUid?: string
    isTaxable?: boolean
}

export interface InvoiceAttachmentUpdate {
    invoiceId: number
    name: string
    includeInInvoiceEmail: boolean
}

export interface InvoiceAttachmentAdd extends InvoiceAttachmentUpdate {
    location: string
    type: InvoiceAttachmentType
}

export interface CreateInvoiceRequest {
    account: string
    invoiceDate: Date
    currency: string
}

export interface MarkInvoicePaidRequest {
    externalPaymentReference: string
    amount?: number
    paidOn: Date
}

export interface SearchAccountsRequest extends IPagination{

    id?: string;
    name?: string;
    reference?: string;
    notes?: string;
    email?: string;
    phone?: string;
    country?: string;
    city?: string;
    vat?: string;
    eori?: string;
    taxType?: string;
    accountType?: number;
    dueDays?: number;
    profileId?: number;
    
}

export interface BulkPayRequest extends MarkInvoicePaidRequest {
    InvoiceIds: number[]
}

export interface InvoiceBillingAddress {
    phone: string;
    email: string;
    addressLine2?: string;
    city?: string;
    companyName?: string;
    country?: string;
    eori?: string;
    contactName?: string;
    state?: string;
    street?: string;
    vat?: string;
    zipcode?: string;
}

export interface IssueInvoiceRequest {
    dueDays: number
    invoiceDate: string
    alternativeInvoiceNumber?: string
}

export interface IssueCreditNoteRequest {
    invoiceId: number
}

export interface AllowedProfilesRequest {

}

export interface AllowedProfiles {
    allowedProfileIds: number[];
    allowAllProfiles: boolean;
}



export interface ChargeSearchFilters {
    labelGenerated?: boolean|null;
    account?: string;
    shipmentNumbers?: string[];
    billingType?: BillingType | null;
    withoutPagination?: boolean;
    typeId?:string
    isReadyToBeInvoiced?: boolean;
    quotationNumber?: string;
    invoiceNumber?: string;
    deleted?: boolean;
    invoiced?: boolean | null;
    customerAccount?: string;
    pickupDateFrom?: string;
    pickupDateTo?: string;
    map?: Record<string, string>
    profileId?: number,
    offset?: number
    limit?: number,
    isGroupedSearch?:boolean
    excludeDeleted?:boolean
}

export interface ChargeExport extends ChargeSearchFilters {
    reportId: number
    profileId?: number
}

export interface ChargeIsGroupedSearch {
    CustomerName: string
    shipmentNumber?: number
    totalCost:number
    totalSelling:number
    shipmentUid:string
}

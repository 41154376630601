import { CSpinner, CDataTable, CLink } from "@coreui/react";
import React from "react";
import { AppModel } from "../../../../models/app";
import PageCount from "../../../SharedComponents/PageCount";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { setContainerSearchLimit } from "../../../../store/actions/containers";
import { IAddAccount } from "../../../../models/shipment";

interface Props {
    onRowClick?: (e: IAddAccount) => void;
    className?: string;
}

const AccountSearchResult = (props: Props) => {
    const scopedSlots = {
        links: (item: IAddAccount) => (
            <td>
                <CLink to={`/containers/${item.id}?isEdit=false`} className="card-header-action">
                    <FontAwesomeIcon icon={faEye} />
                </CLink>
            </td>
        ),
    };

    const { className, onRowClick } = props;

    const dispatch = useDispatch();
    const hist = useHistory();
    const loc = useLocation();

    const accountSearchResult = useSelector((state: AppModel) => {
        const { result, isLoading, page, filterGroup, count, limit } = state.account.accountSearch;
        return {
            result,
            isLoading,
            page,
            filterGroup,
            maxFound: count,
            maxPage: Math.ceil(count! / limit!),
            limit
        };
    });

    const { result, page, limit } = accountSearchResult;
    const startIndex = ((page || 1) - 1) * (limit ?? 20);
    const endIndex = startIndex + (limit ?? 20);
    const displayedRecords = result?.slice(startIndex, endIndex);


    const handlePageChange = (p: number) => {
        const modifiedSearch = new URLSearchParams(loc.search);
        modifiedSearch.set("page", p.toString());
        hist.push(`${loc.pathname}?${modifiedSearch.toString()}`);
    };

    const handlePageSizeChange = (ps: number) => {
        const modifiedSearch = new URLSearchParams(loc.search);
        modifiedSearch.set("limit", ps.toString());
        hist.push(`${loc.pathname}?${modifiedSearch.toString()}`);
        dispatch(setContainerSearchLimit(ps));
    };

    return (
        <div className={className}>
            {accountSearchResult.isLoading ? (
                <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{ width: "5em", height: "5em" }}
                />
            ) : (
                <>
                    <div className="small">
                        {displayedRecords!.length > 0 ? (
                            <CDataTable
                                size="sm"
                                hover
                                items={displayedRecords}
                                scopedSlots={scopedSlots}
                                fields={[
                                    { key: "links", label: "", _style: { width: '1%' } },
                                    "id",
                                    "name",
                                    "email",
                                    "phone",
                                    "country",
                                    "accountType",
                                ]}
                            />
                        ) : (
                            <p>No records found.</p> // Handle empty records scenario
                        )}
                        <PageCount
                            maxFound={accountSearchResult.maxFound}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            currentPage={accountSearchResult.page ?? 1}
                            maxPage={accountSearchResult.maxPage}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default AccountSearchResult;

import React, { useEffect, useState } from 'react';
import { CRow, CCol, CContainer, CModal, CModalHeader, CModalBody, CModalFooter, CButton, CTooltip } from '@coreui/react';
import RatesClient from '../../../../clients/ratesClient';
import SWInput from '../../../SharedComponents/SWInput';
import SWSelect from '../../../SharedComponents/SWSelect';
import { FormikProps } from 'formik';
import { CreateShipment } from '../../../../models/shipment';
import RestIcon from '../../../../icons/resetIcon';
import CameraIcon from '../../../../icons/camera';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft} from '@fortawesome/free-solid-svg-icons';

interface IProps {
    formik: FormikProps<CreateShipment>;
    handleClearAll: () => void
    handleCapture: () => void
    setIgnoreDefaults: (e: boolean) => void
}

const Details = ({ formik, handleClearAll, handleCapture, setIgnoreDefaults }: IProps) => {
    const ratesClient = new RatesClient();
    const { values, setFieldValue, touched, errors,setValues } = formik;
    const [changeAccountConfirmation, setChangeAccountConfirmation] = useState<{ isOpen: boolean, newAccount: string } | null>(null)
    const [accountLookup, setAccountLookup] = useState<{ [k: string]: string }>({})
    useEffect(() => {
        getAccounts()
    }, []);

    const getAccounts = async () => {
        let accounts = await ratesClient.LookupCustomers()
        setAccountLookup(accounts)
    }

    const handleInvertShipperAndConsignee = () => {
        setFieldValue('shipper',values?.consignee )
        setFieldValue('consignee',values?.shipper )
    }
    return (
        <CContainer className="form-group mb-0 pt-0 pb-0" style={{}}>
            <CRow>
                <CCol md={3}>
                    <SWSelect
                        labelClassName='m-0 small'
                        values={accountLookup} value={values?.account} name={"account"}
                        onChange={e => {
                            if (!!values?.account && !!e) {
                                setChangeAccountConfirmation({ isOpen: true, newAccount: e })
                            } else {
                                setFieldValue("account", e)
                                formik.setFieldValue("itemsFromStock", [])
                            }
                        }}
                        label={"Account"}
                        validationErrors={touched?.account && errors?.account?.toString() || ""}
                    />
                </CCol>
                <CCol md={3}>
                    <SWInput
                        labelClassName='m-0 small'
                        label={"Reference"}
                        name={"accountReference"}
                        value={values?.accountReference}
                        onChange={(e) => setFieldValue("accountReference", e)}
                        type={"text"}
                        validationErrors={touched?.accountReference && errors?.accountReference?.toString() || ""}
                    />
                </CCol>
                <CCol style={{ alignSelf: "center", textAlign: "end" }}>
                    <CameraIcon
                        style={{
                            marginRight: 9,
                            marginBottom: "1rem"
                        }}
                        tabIndex={-1}
                        onClick={() => handleCapture()}
                        tooltipText={'Capture'} />
                    <CTooltip content={"Reverse"}>
                        <FontAwesomeIcon
                            icon={faArrowRightArrowLeft}
                            tabIndex={-1}
                            onClick={() => handleInvertShipperAndConsignee()}
                            style={{
                                cursor: "pointer", color: "black",
                                width: 21, height: 21, marginRight: 3,
                                alignItems: "center"
                            }}
                        />
                    </CTooltip>
                    <RestIcon
                        style={{
                            marginBottom: "1rem"
                        }}
                        tabIndex={-1}
                        onClick={() => handleClearAll()}
                        tooltipText={'Clear all'} />
                </CCol>
            </CRow>
            <CModal
                show={changeAccountConfirmation?.isOpen}
                centered
                onClose={() => setChangeAccountConfirmation(null)}
                closeOnBackdrop={false}
            >
                <CModalHeader closeButton>
                    <h5>Change Account</h5>
                </CModalHeader>
                <CModalBody>
                    <h6> This action can reset the shipper and consignee data, please advice</h6>
                </CModalBody>
                <CModalFooter>
                    <CRow className="justify-content-between w-100">
                        <CCol className="text-left">
                            <CButton
                                style={{ fontSize: "10px" }}
                                variant="outline"
                                color="danger"
                                onClick={() => {
                                    setFieldValue("account", changeAccountConfirmation?.newAccount)
                                    setFieldValue("itemsFromStock", [])
                                    setChangeAccountConfirmation(null)
                                    setIgnoreDefaults(false)
                                }}
                            >
                                Reset to defaults
                            </CButton>
                        </CCol>

                        <CCol className="text-right">
                            <CButton
                                style={{ fontSize: "10px" }}
                                variant="outline"
                                color="primary"
                                onClick={() => {
                                    setFieldValue("account", changeAccountConfirmation?.newAccount)
                                    setFieldValue("itemsFromStock", [])
                                    setChangeAccountConfirmation(null)
                                    setIgnoreDefaults(true)
                                }}
                            >
                                Keep old data
                            </CButton>
                        </CCol>
                    </CRow>
                </CModalFooter>
            </CModal>
        </CContainer>
    )
}

export default Details;
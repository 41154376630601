import React, {useEffect, useState} from "react"
import {CButton, CCard, CCol, CContainer, CNav, CNavLink, CTabContent, CTabPane, CTabs} from "@coreui/react";
import PendingInvoiceTable from "./components/PendingInvoiceTable";
import GeneratedInvoiceTable from "./components/GeneratedInvoiceTable";
import CreateInvoiceModal from "./modals/CreateInvoiceModal";
import {CreateInvoiceRequest} from "../../../models/accounting";
import AccountingClient from "../../../clients/accountingClient";
import {useHistory, useLocation} from "react-router";
import IgnoredInvoiceTable from "./components/IgnoredInvoiceTable";

const BillingManagement = () => {
    const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<string>()
    const [accountingProfiles, setAccountingProfiles] = useState<{ [k: string]: string }>({});
    const hist = useHistory()
    const loc = useLocation()
    const param = new URLSearchParams(loc.search)
    const accountingClient = new AccountingClient();

  

    useEffect(() => {
        GetAccountingProfiles();
        SetQueryParamActiveTab(param.get("active-tab") ?? "charges")
    }, [])

    const GetAccountingProfiles = async () => {
        const profiles = await accountingClient.LookupProfiles()
        const res = await accountingClient.GetAllowedProfiles({})
        if (res?.allowAllProfiles) {
            setAccountingProfiles(profiles)
            return
        }
        const allowedProfileIds = res?.allowedProfileIds
        if (res.allowedProfileIds) {
            let dictionary: {
                [k: string]: string
            } = Object.assign({}, ...allowedProfileIds.map((x) => ({[x]: profiles[x]})));
            setAccountingProfiles(dictionary)
        }
    }

    const handleCreateInvoice = async (body: CreateInvoiceRequest) => {
        const accountingClient = new AccountingClient();
        const id = await accountingClient.CreateInvoice(body)
        if (id)
            hist.push(`/financial/billing/${id}`)
        setShowCreateInvoiceModal(false)
    }

    const SetQueryParamActiveTab = (value: string) => {

        setActiveTab(value)
        if (param.get("active-tab") != value) {
            param.set("active-tab", value)
            hist.push(`${loc.pathname}?${param.toString()}`)
        }
    }


    return (
        <CContainer className="bg-white p-4 text-primary">
            <CTabs activeTab={activeTab} onActiveTabChange={SetQueryParamActiveTab}>
                <CCard className="small">
                    <CNav variant="tabs">
                        <CNavLink data-tab="charges">Pending Charges</CNavLink>
                        <CNavLink data-tab="invoices">Invoices</CNavLink>
                        <CNavLink data-tab="ignored">On Hold Charges</CNavLink>
                        <CCol className={"text-right mt-1"}>
                            <CButton size={"sm"} variant={"outline"} color={"primary"}
                                     onClick={() => setShowCreateInvoiceModal(true)}>Create
                                Invoice</CButton>
                        </CCol>
                    </CNav>
                    <CTabContent>
                        <CTabPane data-tab="charges">
                            <PendingInvoiceTable
                                accountingProfiles={accountingProfiles}/>
                        </CTabPane>
                        <CTabPane data-tab="invoices">
                            <GeneratedInvoiceTable
                                accountingProfiles={accountingProfiles}/>
                        </CTabPane>
                        <CTabPane data-tab="ignored">
                            <IgnoredInvoiceTable
                                accountingProfiles={accountingProfiles}/>
                        </CTabPane>
                    </CTabContent>
                </CCard>
            </CTabs>
            {
                showCreateInvoiceModal && <CreateInvoiceModal onClose={() => setShowCreateInvoiceModal(false)}
                                                              onsubmit={v => handleCreateInvoice(v)}/>
            }
        </CContainer>)
}

export default BillingManagement
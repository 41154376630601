import React, {useEffect} from "react";
import "./scss/style.scss";
import "./App.css";
import {BrowserRouter as Router, Redirect, Route, Switch,} from "react-router-dom";
import Main from "./components/LayoutComponents/Main";
import Login from "./components/ViewComponents/Login/Login";
import {useDispatch, useSelector} from "react-redux";
import {AppModel} from "./models/app";
import cookieManager from "./services/cookieManager";
import {LogOut, SetUser} from "./store/actions/auth";
import jwt from "jsonwebtoken";
import Logout from "./components/ViewComponents/Login/Logout";
import {now} from "lodash";
import CookieManager from "./services/cookieManager";

function App() {
    const {user, isAuthenticated, loggingIn} = useSelector(
        (state: AppModel) => {
            return {
                user: state.app.user,
                isAuthenticated: state.app.isAuthenticated,
                loggingIn: state.app.loggingIn,
            };
        }
    );

    const dispatch = useDispatch();

    useEffect(() => {
        //todo remove
        const accessToken = CookieManager.getAccessToken();

        // const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJ3YWhlZWIiLCJnaXZlbl9uYW1lIjoiV2FoZWViIiwiVXNlcm5hbWUiOiJ3YWhlZWIiLCJlbWFpbCI6IndhaGVlYkBzaW1wbGlmeTkuY29tIiwicm9sZSI6Ik1lbWJlciIsIm5iZiI6MTY4MTk5OTAxOCwiZXhwIjoxNjgyNjAzODE4LCJpYXQiOjE2ODE5OTkwMTgsImlzcyI6ImxvY2FsIiwiYXVkIjoibG9jYWwifQ.eFysxJiseGx52v_Yx68rN_XafrH_AOCUfRleoSMdyZY"//cookieManager.getAccessToken();
        if (!accessToken) {
            dispatch(LogOut());
            return;
        }

        const jwtUser = jwt.decode(accessToken) as { [k: string]: any };


        let expCode: number = jwtUser['exp']
        // let currentUtc = ~~(+new Date())
        // if(expCode < currentUtc)
        let expiryDate = new Date(expCode * 1000)
        if (expiryDate < new Date(now())) {
            dispatch(LogOut())
            return;
        }

        if (jwtUser)
            cookieManager.deleteCookie("loginError");

        if (jwtUser) {
            dispatch(
                SetUser({
                    name: jwtUser.nameid ?? jwtUser.Username,
                    displayName: jwtUser.given_name ?? jwtUser.nameid ?? jwtUser.Username,
                })
            );
        }

    }, [loggingIn, cookieManager, cookieManager.getCookieValue("accessToken")]);

    return (
        <Router>
            {!isAuthenticated && <Redirect to="/Login"/>}
            <Switch>
                <Route exact path="/logout">
                    <Logout/>
                </Route>
                <Route exact path="/login">
                    <Login/>
                </Route>
                <Route path="/">
                    <Main/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;

import { CCard, CContainer, CNav, CNavLink, CTabContent, CTabPane, CTabs } from "@coreui/react";
import React, { Fragment, useState } from "react";
import AddCODForm from "./components/AddCODForm";
import PendingCODTable from "./components/PendingCODTable";
import GeneratedCodTable from "./components/GeneratedCodTable";
import BankDepositsTable from "./components/BankDeposits";
import AddAccountForm from "./components/AddAccountForm";

const CreateAccount = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    return (
        <Fragment>
            <CContainer className="bg-white p-4 text-primary small">
                <AddAccountForm setIsSubmitting={setIsSubmitting} />
                <div className={"py-4"}></div>

            </CContainer>
        </Fragment>
    )
}

export default CreateAccount
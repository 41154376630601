import {
  CFormGroup,
  CFormText,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel
} from "@coreui/react";
import React, { CSSProperties } from "react";
import ReactSelect, { StylesConfig } from 'react-select';

import CIcon from "@coreui/icons-react";


interface MultiOptions {
  value: string;
  label: string;
}

const customMultiStyles: CSSProperties = {
  fontSize: "10px",
  backgroundColor: "#f7f7f7",
}

type IsMulti = false;

interface Props {
  style?: React.CSSProperties;
  backgroundColor?: string;
  textColor?: string;
  readonly?: boolean;
  label?: string;
  className?: string;
  labelClassName?: string;
  append?: any;
  onChange?: (val?: string) => void
  onBlur?: (e: any) => void
  onFocus?: () => void
  icon?: any
  maxLength?: number
  id?: string
  name?: string
  key?: any
  value?: string[] | string | null
  values: { [key: string]: string } | { label: string; value: string }[]
  validationErrors?: string
  isMulti?: boolean
  hideArrow?: boolean
  isClearable?: boolean
  isFieldHidden?: boolean,
  customBackgroundColor?: string;
  optionLabelIcon?: JSX.Element
  handleIconClick?: (e: string) => void
  labelClicked?:()=>void
}



const SWSelect = (props: Props) => {
  const {
    key,
    id,
    name,
    value,
    label,
    append,
    icon,
    className,
    labelClassName,
    style,
    readonly,
    onChange,
    maxLength,
    onBlur,
    onFocus,
    validationErrors,
    values,
    isMulti,
    hideArrow,
    isClearable,
    backgroundColor,
    textColor,
    isFieldHidden = false,
    customBackgroundColor,
    optionLabelIcon,
    handleIconClick,
    labelClicked
  } = props;


  const options = values ? Array.isArray(values) ? values : Object.entries(values).map((v) => ({
    label: v[1],
    value: v[0],
  })) : undefined;

  const selectStyle: StylesConfig<MultiOptions, IsMulti> = {
    control: (provided, state) => {
      return {
        ...provided,
        ...customMultiStyles,
        minHeight: "26px",
        border:!!validationErrors ? "2px solid #007bff" : undefined,
        backgroundColor: state.isDisabled ? provided.backgroundColor : customBackgroundColor ?? backgroundColor ?? "none",
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
       color: textColor ?? "#768192"
      }
    },
    indicatorsContainer: (provided, state) => {
      return {
        ...provided,
        ...customMultiStyles,
        height: "20px",
        marginTop: "3px",
        marginBottom: "3px",
        backgroundColor: state.isDisabled ? provided.backgroundColor : "none",
      };
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        ...customMultiStyles,
        height: "20px",
        marginTop: "0px",
        marginBottom: "3px",
        paddingTop: "0px",
        backgroundColor: "none",
      };
    },

    multiValue: (provided) => {
      return {
        ...provided,
        alignItems: "center",


      };
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        backgroundColor: "none",
      }
    },

    dropdownIndicator: (provided) => {
      return {
        ...provided,
        height: "26px",
        marginBottom: "14px",
        color: textColor ?? provided.color
      }
    },
    menu: (provided) => {
      return {
        ...provided,
        overflow: "auto",
      }
    },
  };

  const getSelectedOptions = (vals: string[]) => options ? options.filter(o => vals.includes(o.value)) : null;
  const getSelectedOption = (val: string) => options ? options.find(o => o.value == val) : null;

  const selectedValueOrValues = value ? (Array.isArray(value) ? getSelectedOptions(value) : getSelectedOption(value)) : null;


  const handleChange = (e: any) => onChange && onChange(e.e?.value);

  const customOption = (props: any) => {
    const { data, isFocused, isSelected, innerProps } = props;
    return (
      <div
        {...innerProps}
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: isFocused ? "#deebff" : "white",
          cursor: "pointer",
          fontSize: "10px",
          padding: "0.5rem",
        }}
        onClick={(e) => {
          innerProps.onClick(e)
          labelClicked?.()
        }}
      >
        <div>
          {data.label}</div>
        <div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              innerProps.onClick(e);
              handleIconClick?.(data.value);
            }}
            style={{ display: "inline-block" }}>
            {optionLabelIcon}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={className} style={style}>
      <CFormGroup>
        {label && <CLabel className={labelClassName ?? "small"}>{label}</CLabel>}
        <CInputGroup>
          {icon && (
            <CInputGroupPrepend>
              <CInputGroupText>
                <CIcon content={icon} />
              </CInputGroupText>
            </CInputGroupPrepend>
          )}
          <ReactSelect
            isClearable={isClearable}
            onFocus={() => onFocus ? onFocus() : undefined}
            isMulti={isMulti}
            styles={selectStyle}
            style={{ fontSize: "10px" }}
            maxLength={maxLength}
            readOnly={readonly}
            isDisabled={readonly}
            className={isFieldHidden ? "d-none" : "w-100 bg-white rounded"}
            classNamePrefix="mySelect"
            inputId={id}
            onBlur={(e) => onBlur ? onBlur(e) : undefined}
            name={name}
            key={key}
            options={options}
            value={selectedValueOrValues}
            onChange={onChange ? (e) => handleChange({ e: e }) : undefined}
            components={{
              ...(optionLabelIcon ? { Option: customOption } : {}),
              ...(hideArrow ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : {})
            }}
          />
          {append && (
            <CInputGroupAppend>
              <CInputGroupText>{append}</CInputGroupText>
            </CInputGroupAppend>
          )}
        </CInputGroup>
        {validationErrors ? (
          <CFormText
            style={{ fontSize: "1em" }}
            color="danger"
            className="help-block">
            {validationErrors}

          </CFormText>
        ) : (
          ""
        )}
      </CFormGroup>
    </div>
  );
};

export default SWSelect;

import {CContainer, CSpinner} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSearchOnFilterGroup} from "../../../../hooks/helperHooks";
import AccountSearchForm from "./AccountSearchForm";

import {AppModel, SearchyResult} from "../../../../models/app";
import AccountSearchResult from "./AccountSearchResult";
import AccountingClient from "../../../../clients/accountingClient";
import {toQueryParam} from "../../../../utils/filterUtils";
import {accountFilterGroup, AccountFilterGroup, FilterGroup} from "../../../../models/filter";
import {IAddAccount} from "../../../../models/shipment";
import {SetAccountResult, setAccountsFilterGroup, SetAccountsIsLoading} from "../../../../store/actions/accounts";

const AccountingAccounts = () => {
        const accountingClient = new AccountingClient();

        const dispatch = useDispatch();

        const filterGroup = useSelector(
            (state: AppModel) => state.account.accountSearch.accountFilterGroup
        ) as AccountFilterGroup;

        const [newFilters, setNewFilters] = useState<AccountFilterGroup>(filterGroup || {filters: {}});


        useEffect(() => {
            const fetchAccounts = async () => {
                dispatch(SetAccountsIsLoading(true))
                const result = await accountingClient.SearchAccount(newFilters);
                if (result.total > 0) {
                    dispatch(SetAccountResult(1, result.total, result.matches, result.limit))
                } else {
                    dispatch(SetAccountResult(1, 0, [], 0));
                }
                dispatch(SetAccountsIsLoading(false))
            }
            fetchAccounts();
        }, [newFilters, dispatch]);


        return (
            <CContainer className="bg-white p-4">
                <AccountSearchForm newFilters={newFilters} setNewFilters={setNewFilters}/>
                <AccountSearchResult/>
            </CContainer>
        );
    }
;

export default AccountingAccounts;

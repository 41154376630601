import React, { Fragment, useState } from 'react';
import { freeSet } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CRow, CTooltip, CCol, CCardBody, CInputRadio, CLabel } from '@coreui/react';
import { useFormik } from 'formik';
import { CreateShipment, StandardShipmentItem } from '../../../../models/shipment';
import SWInput from '../../../SharedComponents/SWInput';
import * as yup from "yup"
import SWSelect from '../../../SharedComponents/SWSelect';
import ResetIcon from '../../../../icons/resetIcon';
import { FormikProps } from 'formik';
import { isNumber } from '../../../../utils/commonValidators';

interface IProps {
    items: StandardShipmentItem[]
    addItem: (values: StandardShipmentItem) => void
    handleDeleteItem: (index: number) => void
    handleEditItem: (index: number, key: string, newValue: string | number) => void
    showFields: boolean;
    setShowFields: (e: boolean) => void
    formik: FormikProps<CreateShipment>;
    countryLookups: { [k: string]: string }
}

const ItemDetails = ({ formik, countryLookups, items, addItem, handleDeleteItem, handleEditItem, showFields, setShowFields }: IProps) => {
    const [isHover, setIsHover] = useState(false);
    const { isDocument } = formik?.values
    const [focusedInput, setFocusedInput] = useState<boolean>(false)

    const isRadioDisabled = Boolean(!showFields || (formik?.values?.itemsFromStock?.length && formik?.values?.itemsFromStock?.length > 0))


    const { values, setFieldValue, resetForm, submitForm, errors, touched } = useFormik<StandardShipmentItem>({
        enableReinitialize: true,
        initialValues: {
            description: "",
            countryOfOrigin: "",
            hsCode: "",
            weight: undefined,
            quantity: undefined,
            dutiable: !isDocument
        },
        onSubmit: (values) => {
            addItem(values);
            resetForm();
        },
        validationSchema: yup.object({

        })
    })

    const handleClear = () => {
        formik.setFieldValue("items", [{
            description: "",
            countryOfOrigin: "",
            hsCode: "",
            weight: undefined,
            quantity: undefined,
            dutiable: !isDocument
        }])
        resetForm();
    }

    return (
        <CCardBody
            className={`mt-1 pt-2 pb-1 mt-0 mb-0  rounded text-black  ${(isHover || focusedInput) ? "bg-secondary" : ""
                }`}
            onMouseOver={() => {
                setIsHover(true);
            }}
            onMouseLeave={() => {
                setIsHover(false);
            }}
        >
            <CRow className="mb-3">

                <h6>
                    <strong>{"Contents:" + "\xa0"}</strong>
                </h6>


                <CCol>
                    <CRow style={{}}>
                        <CLabel className={"text-primary"}>Document:</CLabel>
                        <CCol style={{ marginLeft: "20px" }}><CInputRadio
                            readOnly={isRadioDisabled}
                            disabled={isRadioDisabled}
                            style={{
                                width: "1.25em",
                                height: "1.25em",
                                fontSize: "10px",
                                marginTop: "1px",
                            }}
                            checked={isDocument}
                            onChange={() => {
                                formik?.setFieldValue("isDocument", true)
                                handleClear()
                            }}
                        /><CLabel className={"text-primary"}>Yes</CLabel>
                        </CCol>
                        <CCol style={{ marginLeft: "20px" }}><CInputRadio
                            readOnly={isRadioDisabled}
                            disabled={isRadioDisabled}
                            style={{
                                width: "1.25em",
                                height: "1.25em",
                                fontSize: "10px",
                                marginTop: "1px",
                            }}
                            checked={!isDocument}
                            onChange={() => {
                                formik?.setFieldValue("isDocument", false)
                            }}
                        /><CLabel className={"text-primary"} >No</CLabel></CCol>
                    </CRow>
                </CCol>



                <CCol className="text-right">
                    {(isHover || focusedInput) &&
                        <>
                            {!showFields &&
                                <CTooltip content={"unlock"} >
                                    <CIcon
                                        content={freeSet.cilLockLocked}
                                        onClick={() => setShowFields(true)}
                                        style={{
                                            height: "1.5em",
                                            width: "1.5em",
                                            cursor: "pointer",

                                        }}
                                    />
                                </CTooltip>}
                            {showFields &&
                                <>
                                    <ResetIcon onClick={() => handleClear()} tooltipText={"Clear"} />
                                    <CTooltip content={"Lock"} >
                                        <CIcon
                                            content={freeSet.cilLockUnlocked}
                                            onClick={() => setShowFields(false)}
                                            style={{
                                                height: "1.5em",
                                                width: "1.5em",
                                                cursor: "pointer",
                                                marginLeft: 8
                                            }}
                                        />
                                    </CTooltip></>}

                        </>
                    }
                </CCol>
            </CRow>

            {items?.map((item: StandardShipmentItem, index: number) => {
                return (
                    <Fragment key={index}>

                        <CRow className={'ml-5 mb-1 '}>
                            <CCol className={"p-0 mr-1"} md={1}>
                                <SWInput
                                    readonly={isDocument}
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "Quantity" : ""}
                                    name={"quantity"}
                                    value={item?.quantity?.toString() ?? ""}
                                    onChange={(e) => {
                                        if (e) {
                                            isNumber(e) && handleEditItem(index, "quantity", e)
                                        } else {
                                            handleEditItem(index, "quantity", e)
                                        }
                                    }}
                                    type={"text"}
                                    maxLength={2}
                                /> {!showFields && <strong> {item?.quantity}</strong>}

                            </CCol>
                            <CCol className={"p-0 mr-1"} md={2}>
                                <SWInput
                                    readonly={isDocument}
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "Description" : ""}
                                    name={"description"}
                                    value={item?.description}
                                    onChange={(e) => handleEditItem(index, "description", e)}
                                    type={"text"}
                                      // @ts-ignore
                                      validationErrors={formik?.touched?.items && !!formik?.errors?.items?.[index] && formik?.errors?.items?.[index]?.description?.toString() || ""}
                                /> {!showFields && <strong> {item?.description}</strong>}
                            </CCol>
                            <CCol className={"p-0 mr-1"} md={3}  >

                                <SWInput // around 10 digits
                                    readonly={isDocument}
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "HS Code" : ""}
                                    name={"hsCode"}
                                    value={item?.hsCode}
                                    onChange={(e) => handleEditItem(index, "hsCode", e)}
                                    type={"text"}
                                />  {!showFields && <strong> {item?.hsCode}</strong>}

                            </CCol>

                            <CCol className={"p-0 mr-1"}  >
                                <SWInput
                                    readonly={isDocument}
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "Kg" : ""}
                                    name={"weight"}
                                    value={item?.weight?.toString() ?? ""}
                                    onChange={(e) => {
                                        if (e) {
                                            isNumber(e) && handleEditItem(index, "weight", e)
                                        } else {
                                            handleEditItem(index, "weight", e)
                                        }
                                    }}
                                    type={"text"}
                                    maxLength={4}
                                /> {!showFields && <strong> {item?.weight}</strong>}
                            </CCol>
                            <CCol className={"p-0"} md={3} >
                                <SWSelect
                                    readonly={isDocument}
                                    onFocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "Origin" : ""}
                                    name={"countryOfOrigin"}
                                    value={item?.countryOfOrigin?.toString() ?? ""}
                                    onChange={(e) => handleEditItem(index, "countryOfOrigin", e!)}
                                    values={countryLookups}
                                />  {!showFields && <strong> {item?.countryOfOrigin}</strong>}
                            </CCol>

                            <CCol style={{ marginTop: -5 }} className={"p-0"}  >
                            {(showFields  && !isDocument && index != 0) &&
                                    <CTooltip content={"Delete"}>
                                        <CIcon
                                            tabIndex={-1}
                                            content={freeSet.cilTrash}
                                            onClick={() => handleDeleteItem(index)}
                                            style={{
                                                height: "1em",
                                                width: "1.5em",
                                                cursor: "pointer",
                                                marginTop: index == 0 ? "1rem" : 0
                                            }}
                                        />
                                    </CTooltip>}

                                {(showFields && !isDocument && index == items?.length - 1) &&
                                    <CTooltip content={"Add new content"}>
                                        <CIcon
                                            tabIndex={-1}
                                            content={freeSet.cilPlus}
                                            onClick={() => submitForm()}
                                            style={{
                                                height: "1.5em",
                                                width: "1.5em",
                                                cursor: "pointer",
                                                marginTop: index == 0 ? "1rem" : 0
                                            }}
                                        />
                                    </CTooltip>}
                            </CCol>

                        </CRow>
                    </Fragment>
                )
            })}
        </CCardBody>
    )
}

export default ItemDetails;
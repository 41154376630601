import ClientWithErrorNotifier from "./clientWithErrorNotifier";
import {ApiResponse} from "@simplify9/simplyapiclient";
import {
    SkuCreate,
    SkuSearchFilter,
    InventoryReceivingRequest,
    InventoryTransaction,
    SkuSearch,
    InventoryTransactionSearchFilter,
    SkuUpdate,
    InventorySummeryReportQueryParameters,
    InventorySummeryReportItem,
    InventoryDetailsReportQueryParameters,
    InventoryDetailsReportItem,
    InventoryTransactionDetailsSearchModel,
    InventoryTransactionDetailReportItem,
    WarehouseDto,
    WarehouseAreaDto,
    WarehouseLocationDto,
    warehouse,
    SkuLookup,
    SkuLookupResult,
    OpenReservationDetails,
    OpenReservationDetailsRequest,
    InventoryTransactionDetailDto,
    ItemReservation,
    AddItemReservation,
    SellerSearch,
    TransactionAttachmentDto,
    TransactionAttachmentSearchRequest,
    AddBulkTransactionAttachmentRequest,
    AddTransactionAttachmentRequest,
    TransactionAttachment,
    DeleteTransactionAttachmentRequest,
    UpdateTransactionAttachmentRequest,
} from "../models/warehouse";
import {toQueryParam} from "../utils/filterUtils";
import {IPaginatedResult} from "../models/filter";
import {RemoteBlob} from "../models/app";
import {ShipmentsImportParameters} from "../models/shipment";
import {SheetInfo} from "../models/sheetInfo";
import {SkuImportRequest, SkuImportSearch, SkusImportSearchRequest} from "../models/sku";

interface GetStockTransactionResult {
    transaction: InventoryTransaction
    originalRequest: InventoryTransaction
}

const localUrl = 'http://localhost:5000'

class WarehousesClient {
    BaseUrl: string;
    pageSize: number;
    apiClient: ClientWithErrorNotifier;

    constructor() {
        this.BaseUrl = `${process.env.REACT_APP_API_BASE_URL}/warehouse/api`;
        this.pageSize = 20;
        this.apiClient = new ClientWithErrorNotifier(this.BaseUrl);
    }

    async CreateSKU(body: SkuCreate) {
        const rs = await this.apiClient.postAsync(`/skus`, body);

        return rs;
    }

    async UpdateSKU(skuId: number, body: SkuUpdate) {
        const rs = await this.apiClient.postAsync(`/skus/${skuId}`, body);

        return rs;
    }

    async itemReservationsUpdateItems(uid: number | string, body: InventoryTransactionDetailDto[]) {
        const rs = await this.apiClient.postAsync(`/reservations/${uid}/updateitems`, body);

        return rs;
    }

    async itemConsumptionsUpdateItems(uid: number | string, body: InventoryTransactionDetailDto[]) {
        const rs = await this.apiClient.postAsync(`/consumes/${uid}/updateitems`, body);

        return rs;
    }

    async itemReservationsWithNumber(uid: number | string, body: ItemReservation) {
        const rs = await this.apiClient.postAsync(`/consumes/${uid}/updatefinal`, body);

        return rs;
    }

    async activateSku(skuCode: string, skuAccountNumber: string) {
        const rs = await this.apiClient.postAsync(`/skus/activate`, {
            active: true,
            code: skuCode,
            accountNumber: skuAccountNumber,
        });

        return rs;
    }

    async deactivateSku(skuCode: string, skuAccountNumber: string) {
        const rs = await this.apiClient.postAsync(`/skus/activate`, {
            active: false,
            code: skuCode,
            accountNumber: skuAccountNumber,
        });

        return rs;
    }

    async SearchSku(filter: SkuSearchFilter) {
        const rs = await this.apiClient.getAsync(`/skus?${toQueryParam(filter)}`);

        return rs;
    }

    async SearchSkuDetails(filter: SkuSearchFilter) {
        const rs = await this.apiClient.getAsync(`/skus/locationdetails?${toQueryParam(filter)}`);

        return rs;
    }

    async SearchSkuExcel(filter: SkuSearchFilter) {
        const rs = await this.apiClient.getAsync(`/skus?${toQueryParam({...filter, ExportToExcel: true})}`);

        return rs.data as Promise<RemoteBlob>;
    }

    async getSku(id: any): Promise<SkuSearch> {
        const rs = await this.apiClient.getAsync(`/skus/${id}`);
        return rs.data;
    }

    async GetAccount(accountNumber: string) {
        const rs = await this.apiClient.getAsync(`accounts/${accountNumber}`);

        return rs.data;
    }

    async CreateSkuImport(req: SkuImportRequest): Promise<string | number> {
        const rs = await this.apiClient.postAsync(`/skusImport`, req, {ignoreError: true});
        if (rs?.succeeded)
            return rs.data

        if (rs?.status == 400)
            return rs.data[Object.keys(rs.data)[0]][0]

        if (rs.status > 500)
            return "Server error, please try again later";

        return `Error, status code ${rs.status}`;
    }

    async GetDefaultImportOptions(account: string): Promise<ShipmentsImportParameters | null> {
        const rs = await this.apiClient.getAsync(`/skusImport/${account}/getdefault`);

        return rs.data
    }

    async UploadExcelFile(file: File): Promise<{ blobInfo: RemoteBlob, sheets: SheetInfo[] }> {

        let formData = new FormData();

        formData.append("File", file);

        let res: ApiResponse = await this.apiClient.postFormAsync("/excel", formData);
        return res.data;

    };

    async SellersLookup() {
        const rs = await this.apiClient.getAsync(`/sellers?lookup=true`);

        return rs.data ?? {};
    }

    async SearchSellers(): Promise<SellerSearch[]> {
        const rs = await this.apiClient.getAsync("sellers?limit=1000")

        return rs.data.matches
    }

    async SearchSkuImport(searchRequest: SkusImportSearchRequest): Promise<SkuImportSearch[]> {
        let searchString;
        let searchArray:string[]=[];
        Object.keys(searchRequest).forEach((key) => {
            if(searchRequest[key as keyof SkusImportSearchRequest])
            searchArray.push(`${key}=${searchRequest[key as keyof SkusImportSearchRequest]}`);
        });
           searchString=searchArray.join('&')
        const rs = await this.apiClient.getAsync(`/SkusImport?${searchString}`)

        return rs.data.result
    }

    async getSkuImport(id:string):Promise<SkuImportSearch> {
        const rs = await this.apiClient.getAsync(`skusImport/${id}`)

        return rs.data
    }

    async getSubTypesLookup() {
        const rs = await this.apiClient.getAsync('/types?lookup=true')

        return rs.data
    }

    async getSubTypes(id: string) {
        const rs = await this.apiClient.getAsync(`types/${id}`)

        return rs.data
    }

    async createReceiving(body: InventoryReceivingRequest) {
        return await this.apiClient.postAsync("/receivings", body);
    }

    async updateReceiving(code: string, body: InventoryReceivingRequest) {
        return await this.apiClient.postAsync(`/receivings/${code}`, body);
    }

    async postReceiving(code: string) {
        return await this.apiClient.postAsync(`/receivings/${code}/post`, {});
    }

    async reverseReceiving(code: string) {
        return await this.apiClient.postAsync(`/receivings/${code}/reverse`, {});
    }

    async createConsuming(body: InventoryReceivingRequest) {
        return await this.apiClient.postAsync("/Consumes", body);
    }

    async updateConsuming(code: string, body: InventoryReceivingRequest) {
        return await this.apiClient.postAsync(`/Consumes/${code}`, body);
    }

    async postConsuming(code: string) {
        return await this.apiClient.postAsync(`/Consumes/${code}/post`, {});
    }

    async reverseConsuming(code: string) {
        return await this.apiClient.postAsync(`/Consumes/${code}/reverse`, {});
    }

    async createTransferring(body: InventoryReceivingRequest) {
        return await this.apiClient.postAsync("/transfers", body);
    }

    async createChangeStatus(body: InventoryReceivingRequest) {
        return await this.apiClient.postAsync("/changestatus", body);
    }

    async updateTransferring(code: string, body: InventoryReceivingRequest) {
        return await this.apiClient.postAsync(`/transfers/${code}`, body);
    }

    async updateChangeStatus(code: string, body: InventoryReceivingRequest) {
        return await this.apiClient.postAsync(`/changestatus/${code}`, body);
    }

    async postChangeStatus(code: string) {
        return await this.apiClient.postAsync(`/changestatus/${code}/post`, {});
    }

    async postTransferring(code: string) {
        return await this.apiClient.postAsync(`/transfers/${code}/post`, {});
    }

    async reverseChangeStatus(code: string) {
        return await this.apiClient.postAsync(`/changestatus/${code}/reverse`, {});
    }

    async reverseReservation(code: string) {
        return await this.apiClient.postAsync(`/reservations/${code}/reverse`, {});
    }

    async reverseTransferring(code: string) {
        return await this.apiClient.postAsync(`/transfers/${code}/reverse`, {});
    }

    async deleteTransaction(id: string): Promise<boolean> {
        const result = await this.apiClient.deleteAsync(`/stock/${id}`, null);
        return result.succeeded;
    }

    async searchWarehouse(): Promise<warehouse[]> {
        const rs = await this.apiClient.getAsync(
            `/Warehouses`
        );
        return rs.data ?? {};
    }

    async warehousesLookup(warehouse?: string): Promise<{ [key: string]: string }> {
        let queryParts = [];
        let queryString = "";
        if (warehouse) queryParts.push(`warehouse=${warehouse}`);

        if (queryParts.length > 0) queryString = `&${queryParts.join("&")}`;

        const rs = await this.apiClient.getAsync(
            `/Warehouses?lookup=true${queryString}`
        );
        return rs.data ?? {};
    }

    async GetWarehouse(warehouse?: string) {
        let res = await this.apiClient.getAsync(`warehouses/${warehouse}`)
        return res.data
    }

    async GetWarehouseWithLookups(warehouse?: string) {
        let res = await this.apiClient.getAsync(`/warehouses/${warehouse}?lookup=true`)
        return res.data
    }

    async storesLookup(warehouse?: string): Promise<{ [key: string]: string }> {
        let queryParts = [];
        let queryString = "";
        if (warehouse) queryParts.push(`warehouse=${warehouse}`);

        if (queryParts.length > 0) queryString = `&${queryParts.join("&")}`;

        const rs = await this.apiClient.getAsync(
            `/Stores?lookup=true${queryString}`
        );
        return rs.data ?? {};
    }

    async areaLookup(warehouse?: string, store?: string, area?: string) {
        let queryParts = [];
        let queryString = "";
        if (warehouse) queryParts.push(`warehouse=${warehouse}`);
        if (store) queryParts.push(`store=${store}`);
        if (area) queryParts.push(`area=${area}`);

        if (queryParts.length > 0) queryString = `&${queryParts.join("&")}`;

        const rs = await this.apiClient.getAsync(
            `/WarehouseAreas?lookup=true${queryString}`
        );
        return rs.data ?? {};
    }

    async locationLookups(warehouse?: string, store?: string, area?: string, location?: string) {
        let queryParts = [];
        let queryString = "";
        if (warehouse) queryParts.push(`warehouse=${warehouse}`);
        if (store) queryParts.push(`store=${store}`);
        if (area) queryParts.push(`area=${area}`);
        if (location) queryParts.push(`location=${location}`);


        if (queryParts.length > 0) queryString = `&${queryParts.join("&")}`;

        const rs = await this.apiClient.getAsync(
            `/WarehouseLocations?lookup=true${queryString}`
        );
        return rs.data ?? {};
    }

    async getStock(id: any): Promise<GetStockTransactionResult> {
        const rs = await this.apiClient.getAsync(`/stock/${id}`);
        return rs.data;
    }

    async searchStock(filter: InventoryTransactionSearchFilter): Promise<IPaginatedResult<InventoryTransaction>> {
        const rs = await this.apiClient.getAsync(`/stock?${toQueryParam(filter)}`);
        return rs.data;
    }

    async searchStockDetails(filter: InventoryTransactionSearchFilter): Promise<IPaginatedResult<InventoryTransactionDetailDto>> {
        const rs = await this.apiClient.getAsync(`/stock/details?${toQueryParam(filter)}`);
        return rs.data;
    }

    async createWarehouse(body: WarehouseDto) {
        const rs = await this.apiClient.postAsync("warehouses", body)
        return rs;
    }

    async updateWarehouse(body: WarehouseDto) {
        const rs = await this.apiClient.postAsync("warehouses/update", body)
        return rs;
    }

    async deleteWarehouse(body: WarehouseDto) {
        const rs = await this.apiClient.postAsync("warehouses/delete", body)
        return rs;
    }

    async createArea(body: WarehouseAreaDto) {
        const rs = await this.apiClient.deleteAsync("warehouseareas", body)
        return rs;
    }

    async updateArea(body: WarehouseAreaDto) {
        const rs = await this.apiClient.postAsync("warehouseareas/update", body)
        return rs;
    }

    async deleteArea(body: WarehouseDto) {
        const rs = await this.apiClient.postAsync("warehouseareas/delete", body)
        return rs;
    }


    async createLocation(body: WarehouseLocationDto) {
        const rs = await this.apiClient.postAsync("warehouselocations", body)
        return rs;
    }

    async updateLocation(body: WarehouseLocationDto) {
        const rs = await this.apiClient.postAsync("warehouselocations/update", body)
        return rs;
    }

    async deleteLocation(body: WarehouseDto) {
        const rs = await this.apiClient.postAsync("warehouselocations/delete", body)
        return rs;
    }

    async inventorySummeryReport(
        reportParams: InventorySummeryReportQueryParameters
    ): Promise<InventorySummeryReportItem[]> {
        const rs = await this.apiClient.getAsync(
            `/skus/summery?${toQueryParam(reportParams)}`
        );
        return rs.data ?? [];
    }

    async inventoryDetailsReport(
        reportParams: InventoryDetailsReportQueryParameters
    ): Promise<InventoryDetailsReportItem[]> {
        const rs = await this.apiClient.getAsync(
            `/skus/details?${toQueryParam(reportParams)}`
        );
        return rs.data ?? [];
    }

    async inventoryTransactionDetailsReportExcel(reportParams: InventoryTransactionDetailsSearchModel): Promise<InventoryDetailsReportItem[]> {
        const rs = await this.apiClient.getAsync(
            `/stock/details?${toQueryParam({...reportParams, ExportToExcelOnSkuLevel: true})}`
        );
        return rs.data ?? [];
    }

    async inventoryTransactionDetailsReport(
        reportParams: InventoryTransactionDetailsSearchModel
    ): Promise<IPaginatedResult<InventoryTransactionDetailReportItem>> {

        const rs = await this.apiClient.getAsync(`/stock/details?${toQueryParam(reportParams)}`);
        return rs.data ?? {total: 0, matches: []};
    }

    async openReservations(reportParams: OpenReservationDetailsRequest): Promise<IPaginatedResult<OpenReservationDetails>> {
        const rs = await this.apiClient.getAsync(`/reservations/getopen?${toQueryParam(reportParams)}`);
        return rs.data ?? {total: 0, matches: []};
    }

    async lookup(reportParams: SkuLookup): Promise<SkuLookupResult[]> {

        const rs = await this.apiClient.getAsync(`/skus/lookup?${toQueryParam(reportParams)}`);
        return rs.data ?? [];
    }

    async LookupCustomers(): Promise<{ [key: string]: string }> {
        const rs = await this.apiClient.getAsync("/accounts?format=1");
        return rs.data ?? {};
    }

    async UploadSkuImage(file: File, account: string): Promise<RemoteBlob> {
        let formData = new FormData();
        formData.append("File", file);
        let res: ApiResponse = await this.apiClient.postFormAsync(
            `Upload/skuImage?accountCode=${account}`,
            formData
        );
        return res.data;

    };



    async AddBulkTransactionAttachments(request: AddBulkTransactionAttachmentRequest) {
        const rs = await this.apiClient.postAsync(`transactionAttachments/addBulk`, request)
        return rs.data
    }

    async AddTransactionAttachment(request: AddTransactionAttachmentRequest): Promise<TransactionAttachment> {
        const rs = await this.apiClient.postAsync(`transactionAttachments`, request)
        return rs.data
    }

    async DeleteTransactionAttachment(request: DeleteTransactionAttachmentRequest) {
        const rs = await this.apiClient.postAsync(`transactionAttachments/delete`, request)
        return rs
    }


    async UpdateTransactionAttachment(request: UpdateTransactionAttachmentRequest) {
        const rs = await this.apiClient.postAsync(`transactionAttachments/Update`, request)
        return rs
    }

    async UploadTransactionAttachment(file: File, inventoryTransactionCode: string): Promise<RemoteBlob> {
        let formData = new FormData();
        formData.append("transactionAttachement", file);
        let res: ApiResponse = await this.apiClient.postFormAsync(
            `newTransactionAttachment/${inventoryTransactionCode}`,
            formData
        );
        return res.data;
    };

    async SearchTransactionAttachmentsTypesLookup(): Promise<{ data: { [k: string]: string }[] }> {
        const rs = await this.apiClient.getAsync('transactionAttachments/typesLookup');
        return rs.data ?? {};
    }

}

export default WarehousesClient;

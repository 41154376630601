import {useLocation, useParams} from "react-router";
import GatewayClient from "../../../../clients/gatewayClient";
import {useDispatch, useSelector} from "react-redux";
import {AppModel} from "../../../../models/app";
import {useEffect, useState} from "react";
import {SetLoading} from "../../../../store/actions/auth";
import {GetShipmentLogs} from "../../../../models/getShipmentLogs";
import {CCardBody, CCol, CLabel, CRow} from "@coreui/react";
import ViewLogs from "./viewLogs";
import SWSelect from "../../../SharedComponents/SWSelect";

const Logs = () => {
    const {id} = useParams() as any;
    const gatewayClient = new GatewayClient();
    const loading = useSelector((state: AppModel) => state.app?.isLoading);
    const dispatch = useDispatch()
    const [data, setData] = useState<GetShipmentLogs[]>([])
    const [logsType,setLogsType]=useState<string>("1");
    const location = useLocation()
    
    const getLogs = async () => {
        
        const res =logsType=="2" ?
            await gatewayClient.getDeleteLogs(id):
            await gatewayClient.getShipmentLogs(id)
        if (res) {
            setData(res)
        }
    }
    useEffect(() => {
        dispatch(SetLoading(true))
        getLogs().then()
    }, [logsType]);

    const Logs = {
        "1": "Create",
        "2": "Delete",
    }
    
    
    return (
        <CCol>
            <SWSelect values={Logs} value={logsType} name={''}
                      isClearable={false}
                      onChange={(e)=>{ setLogsType(e as string)}} label={"Logs Type"}/>
            
        <CCardBody style={{minHeight: '500px'}} className={`bg-white p-5 text-black small`}>
            {(!loading && data) ? data.length > 0 ? <ViewLogs data={data} /> :
                <CRow className={'d-flex justify-content-center '}><CLabel className={'font-lg font-weight-bold'}>No
                    Logs were found</CLabel></CRow> : <></>
            }
        </CCardBody>
        </CCol>
    )
}
export default Logs

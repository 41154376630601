import React, {useEffect, useState} from "react";
import {CDataTable, CLink, CSpinner, CCol, CRow, CButton, CInputCheckbox, CBadge, CButtonGroup} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {freeSet} from "@coreui/icons";
import PageCount from "../../../SharedComponents/PageCount";
import {SetShipmentPage, setShipmentSearchLimit, ToggleShipmentView} from "../../../../store/actions/shipments";
import {ShipmentSearch} from "../../../../models/shipment";
import {useDispatch, useStore} from "react-redux";
import {SearchyResult} from "../../../../models/app";
import ResultDetails from "./ResultDetails";
import {useLocation} from "react-router";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye} from '@fortawesome/free-solid-svg-icons'
import {formatDate} from "../../../../utils/dateUtil";
import {IPagination} from "../../../../models/filter";
import {SearchyFilter} from "../../../../models/Searchy";

interface HtmlLineItem {
    label?: string;
    value: string;
}

interface IProps {
    omDeleteClicked: (uids: string[]) => void
    onRecalculateClicked: (uids: string[]) => void
    searchResult: SearchyResult<ShipmentSearch>
    loading: boolean
    handlePageChange: (pagination: IPagination) => void
    searchFilters: SearchyFilter[]
}

const getBadge = (status: any) => {
    let status_text = "light"

    if (status.deliveredCode) {
        status_text = 'info'
    }

    if (status.criticalCode) {
        status_text = 'dark'
    }

    if (status.discrepancyCode) {
        status_text = 'danger'
    }

    if (status.returnedCode) {
        status_text = 'success'
    }

    if (status.finalCode && status.returnedCode) {
        status_text = 'success'
    }

    return status_text
}

const getStatusName = (status: any) => {
    let status_text = "Pending"

    if (status.deliveredCode) {
        status_text = 'Delivered'
    }

    if (status.discrepancyCode) {
        status_text = 'Discrepancy'
    }

    if (status.criticalCode) {
        status_text = 'Critical'
    }

    if (status.returnedCode) {
        status_text = 'Returned'
    }

    if (status.finalCode && status.returnedCode) {
        status_text = 'Returned'
    }

    return status_text
}

const SearchResult = (props: IProps) => {
    const dispatch = useDispatch();
    const [shipmentsFreeMode, setShipmentsFreeMode] = useState(false)
    const [selectedShipmentUids, setSelectedShipmentUids] = useState<string[]>([])
    const [allShipmentSelected, setAllShipmentsSelected] = useState<boolean>(false)
    const [pagination, setPagination] = useState<IPagination>({
        offset: 0,
        limit: 20,
    })

    const loc = useLocation();

    useEffect(() => {
        setDeleteMode()
        setSelectedShipmentUids([]);
    }, [props.searchFilters])

    const setDeleteMode = () => {
        let filters = props.searchFilters
        let BookingFilter = filters?.find(f => f.field == "HasBooking")
        let DeleteFilter = filters?.find(f => f.field == "Deleted" && f.value == true)
        if (!BookingFilter) return
        if (BookingFilter.value == false && !DeleteFilter) {
            setShipmentsFreeMode(true)
        } else {
            setShipmentsFreeMode(false)
        }
    }

    const handleCheckboxClick = (uid: string) => {

        let checked = selectedShipmentUids.includes(uid)
        if (checked) {
            setSelectedShipmentUids(selectedShipmentUids.filter(st => st != uid))
        } else {
            setSelectedShipmentUids([...selectedShipmentUids, uid])
        }
    }

    const handleSelectAll = () => {
        if (allShipmentSelected) {
            setSelectedShipmentUids([])
            setAllShipmentsSelected(false)
        } else {
            let uids = props.searchResult.result?.map(s => s.uid)
            setSelectedShipmentUids(uids ?? [])
            setAllShipmentsSelected(true)
        }
    }

    useEffect(() => {
        setAllShipmentsSelected(false)
    }, [loc.search])

    const element = (val: HtmlLineItem, index: number, withBreak: boolean) =>
        val.label ? (
            <React.Fragment key={index}>
                <strong>{val.label}:</strong>
                {val.value} {withBreak ? <br/> : ""}
            </React.Fragment>
        ) : (
            <React.Fragment key={index}>
                {val.value}
                {withBreak ? <br/> : ""}
            </React.Fragment>
        );

    const inTd = (val: any) => <td>{val}</td>;
    const elementsIntTd = (vals: HtmlLineItem[]) => {
        const items = vals.slice();
        const [last] = items.splice(-1);
        const withBreaks = items.map((el, i) => element(el, i, true));
        withBreaks.push(element(last, vals.length - 1, false));
        return <td>{withBreaks}</td>;
    };

    const scopedSlots = {
        links: (item: ShipmentSearch) => {
            return <td>

                <CLink
                    className="card-header-action"
                    onClick={() => {
                        dispatch(ToggleShipmentView(item));
                    }}
                >
                    <CIcon content={freeSet.cilMagnifyingGlass}/>
                </CLink>
                <CLink to={`/shipments/${encodeURI(item.uid)}`} className="card-header-action">
                    <FontAwesomeIcon icon={faEye}/>

                </CLink>

            </td>;
        },
        "status": (item: any) => {
            return (<td>
                <CBadge style={item.shipmentTraceSummaryItem.criticalCode && {backgroundColor: "black"}}
                        color={getBadge(item.shipmentTraceSummaryItem)}>
                    {getStatusName(item.shipmentTraceSummaryItem)}
                </CBadge>
            </td>)
        },
        awb: (item: ShipmentSearch) => {
            const details =
                <>
                    {item.number ?? item.uid}
                </>
            ;
            return inTd(details);
        },
        receiver: (item: ShipmentSearch) => inTd(item.consigneeName),

        company: (item: ShipmentSearch) => inTd(item.consigneeCompanyName),
        shipper: (item: ShipmentSearch) => {
            let addr: HtmlLineItem[] = [];

            if (item.shipperCompanyName || item.shipperName) {
                if (
                    item.shipperName &&
                    item.shipperCompanyName &&
                    item.shipperCompanyName !== item.shipperName
                )
                    addr.push({
                        value: `${item.shipperCompanyName}, ${item.shipperName}`,
                    });
                else if (
                    item.shipperName &&
                    item.shipperCompanyName &&
                    item.shipperCompanyName === item.shipperName
                )
                    addr.push({value: item.shipperName});
                else if (item.shipperName) addr.push({value: item.shipperName});
                else addr.push({value: item.shipperCompanyName});
            }

            if (item.shipperAddressLine1)
                addr.push({value: item.shipperAddressLine1});
            if (item.shipperAddressLine2)
                addr.push({value: item.shipperAddressLine2});
            if (item.shipperAddressLine3)
                addr.push({value: item.shipperAddressLine3});

            if (item.shipperCity || item.shipperPostCode) {
                if (item.shipperPostCode && item.shipperCity)
                    addr.push({value: `${item.shipperPostCode}, ${item.shipperCity}`});
                else if (item.shipperPostCode)
                    addr.push({value: item.shipperPostCode});
                else addr.push({value: item.shipperCity});
            }

            if (item.shipperCountry) addr.push({value: item.shipperCountry});
            if (item.shipperEmail) addr.push({value: item.shipperEmail});
            if (item.shipperPhone) addr.push({value: item.shipperPhone});

            if (addr.length == 0) return inTd("");

            return elementsIntTd(addr);
        },
        createdOn: (item: ShipmentSearch) => inTd(formatDate(item.createdOn)),


        pickupDate: (item: ShipmentSearch) =>
            inTd(formatDate(item.pickupDate)),
        origin: (item: ShipmentSearch) =>
            inTd(`${item.shipperCountry}-${item.shipperPostCode}`),
        destination: (item: ShipmentSearch) =>
            inTd(`${item.consigneeCountry}-${item.consigneePostCode}, ${item.consigneeCity}`),
        number: (item: ShipmentSearch) => inTd(item.number ?? ""),

        show_delete_checkBox: (item: ShipmentSearch) => {
            return <td>
                <CInputCheckbox
                    checked={selectedShipmentUids?.includes(item.uid) ?? false}
                    style={{marginTop: "auto"}}
                    onChange={() => handleCheckboxClick(item.uid)}
                />
            </td>
        },
        deleted: (item: ShipmentSearch) => {
            return (
                <td>
                    {item?.hasErrors &&
                    <CBadge
                        style={{ fontSize: "10px" }}
                        shape="pill"
                        color="danger"
                    >Has Errors 
                    </CBadge>}
                    {item.deleted && <CBadge
                    style={{ fontSize: "10px" }}
                    shape="pill"
                    color="danger"
                >Deleted
                </CBadge>} 
                 {item.blocked && <CBadge
                    style={{ fontSize: "10px", color: "white" }}
                    shape="pill"
                    color="warning"
                >Blocked
                </CBadge>}
                    {item.pendingPayment && <CBadge
                        style={{ fontSize: "10px", color: "white" }}
                        shape="pill"
                        color="danger"
                    >Pending Payment
                    </CBadge>}
                </td>
            )
        }
    };
    const store = useStore()
    const filters = store.getState().shipment.shipmentsSearch.filterGroup

    return props.loading ? (
        <CSpinner
            className="mx-auto d-block my-5"
            color="primary"
            style={{width: "5em", height: "5em"}}
        />
    ) : (
        <>
            <div className="small">
                <CDataTable
                    size="sm"
                    hover
                    columnHeaderSlot={{
                        show_delete_checkBox:
                            <CInputCheckbox
                                style={{top: "0"}}
                                onClick={handleSelectAll}
                            />

                    }}
                    scopedSlots={scopedSlots}
                    items={props.searchResult?.result}
                    fields={[
                        {key: "links", label: "", _style: {width: '7%', padding: '0.25rem'}},
                        {key: "pickupDate", label: "Pickup Date"},
                        {key: "awb", label: "Number"},
                        {key: "receiver", label: "Receiver"},
                        {key: "company", label: "Company"},
                        {key: "origin", label: "Origin"},
                        {key: "destination", label: "Destination"},
                        {key: "createdOn", label: "Created On"},
                        {key: "deleted", label: ""},
                        {key: 'status', label: "Status", _style: {width: '8%', padding: '0.25rem'}},
                        {key: "show_delete_checkBox", label: "Select"},
                    ]}
                />

                <CRow>
                    <CCol>
                        <PageCount
                            onPageChange={(p) => {
                                let newPagination = pagination
                                newPagination.offset = pagination.limit! * p
                                setPagination(newPagination)
                                setSelectedShipmentUids([])
                                setAllShipmentsSelected(false)
                                dispatch(SetShipmentPage(p))
                                props.handlePageChange(newPagination)

                            }}
                            onPageSizeChange={(pageSize) => {
                                let newPagination = {...pagination, limit: pageSize}
                                setPagination(newPagination)
                                setSelectedShipmentUids([])
                                setAllShipmentsSelected(false)
                                dispatch(setShipmentSearchLimit(pageSize))
                                props.handlePageChange(newPagination)

                            }}
                            currentPage={filters?.page}
                            maxPage={Math.ceil(
                                props.searchResult?.totalCount! / pagination.limit!)}
                            maxFound={props.searchResult?.totalCount!}
                        />
                    </CCol>
                    <CCol className="justify-content-around text-right">
                        <small className="form-text text-muted" color={"blue"}>Total
                            selected: {selectedShipmentUids.length} </small>
                        <CButtonGroup className="text-right">
                            {shipmentsFreeMode && <CButton
                                className="mr-2"
                                size="sm"
                                style={{fontSize: "10px"}}
                                variant="outline"
                                color={"danger"}
                                disabled={!shipmentsFreeMode}
                                onClick={() => props.omDeleteClicked(selectedShipmentUids)}
                            >Delete Selected Shipments</CButton>}
                            <CButton
                                size="sm"
                                style={{fontSize: "10px"}}
                                variant="outline"
                                color="primary"
                                disabled={props?.searchResult?.result?.length == 0}
                                onClick={() => props.onRecalculateClicked(selectedShipmentUids)}
                            >Recalculate Routes</CButton>
                        </CButtonGroup>

                    </CCol>
                </CRow>

                <ResultDetails/>

            </div>
        </>
    );
};

export default SearchResult;

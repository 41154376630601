import {
    CFormGroup,
    CFormText,
    CInput,
    CInputGroup,
    CInputGroupAppend,
    CInputGroupPrepend,
    CInputGroupText,
    CLabel
} from "@coreui/react";
import React, {useRef, useState} from "react";

import CIcon from "@coreui/icons-react";
import {handleInputChange} from "react-select/src/utils";
import {pl} from "date-fns/locale";

interface Props {
    style?: React.CSSProperties;
    readonly?: boolean;
    label?: string;
    className?: string;
    labelClassName?: string
    append?: any;
    onChange?: (val: string, name:string) => void
    onChangeFile?: (val: File | undefined ) => void
    onBlur?: (e:any) => any
    icon?: any
    maxLength?: number
    id?: string
    name?: string
    key?: any
    value?: string
    valueFile?: File
    validationErrors?: string
    type?: "text" | "file" | "number";
    onChangeFormik?:(e:any) => void;
    placeHolder?: string
    isFieldHidden?:boolean
    onfocus?:()=>void,
    inputStyle?:React.CSSProperties;
}

const SWInput = (props: Props) => {
    const {
        key,
        id,
        name,
        value,
        valueFile,
        label,
        append,
        icon,
        className,
        labelClassName,
        style,
        readonly,
        onChange,
        onChangeFile,
        maxLength,
        onBlur,
        validationErrors,
        type,
        onChangeFormik,
        placeHolder,
        isFieldHidden = false,
        onfocus,
        inputStyle,
    } = props;

    const onChangeCall = (e: React.FormEvent<HTMLInputElement>) => {
        if(type && type == "file" )
        onChangeFile && onChangeFile((e as any).dataTransfer?.files ? (e as any).dataTransfer.files[0] : (e as any).currentTarget?.files ?  (e as any).currentTarget.files[0] : undefined);
        else {
            onChange && onChange((e as any).target.value, (e as any).target.name);
            onChangeFormik && onChangeFormik(e);
        }
    }

    const [dragActive, setDragActive] = React.useState(false);
    // handle drag events
    const handleDrag = function(e:any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function(e:any) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFileName(e.dataTransfer.files[0].name)
            onChangeCall(e)
        }
    };
    const [fileName, setFileName] = useState('')

    return (
        <div className={className + " position-relative"} onDragEnter={handleDrag} style={style}>
            <CFormGroup>
                {label && <CLabel className={labelClassName ?? "small"}>{label}</CLabel>}
                <CInputGroup>
                    {icon && (
                        <CInputGroupPrepend>
                            <CInputGroupText >
                                <CIcon content={icon}/>
                            </CInputGroupText>
                        </CInputGroupPrepend>
                    )}
                    <CInput
                        style={inputStyle ?? {fontSize: "10px", maxHeight:"28px",border:!!validationErrors ? "2px solid #007bff":undefined}}
                        className={type === "file" || isFieldHidden ? "d-none" : ""}
                        maxLength={maxLength}
                        readOnly={readonly}
                        id={id}
                        onBlur={(e) => onBlur ? onBlur(e) : undefined}
                        name={name}
                        key={key}
                        value={value}
                        onChange={onChangeCall}
                        type={type}
                        placeholder={placeHolder}
                        onFocus={()=>onfocus?.()}
                    />
                    {type === "file" && <label id="label-file-upload"
                        className={` rounded w-100 h-100 m-0 py-1 px-2 d-flex align-items-center justify-content-center ${dragActive ? "border-primary" : "border-secondary"}`}
                        htmlFor={id}
                    >
                        <div>
                            <p className={"m-0"}>{fileName? fileName :" Drop your file here or upload"}</p>

                        </div>
                    </label>}
                    { dragActive && <div id="drag-file-element" className={"position-absolute w-100 h-100"} style={{top:0, right:0, left:0, bottom:0}} onChange={onChangeCall} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
                    {append && (
                        <CInputGroupAppend>
                            {append}
                        </CInputGroupAppend>
                    )}
                </CInputGroup>
                {validationErrors ? (
                    <CFormText
                        style={{fontSize: "1em"}}
                        color="danger"
                        className="help-block">
                        {validationErrors}

                    </CFormText>
                ) : (
                    ""
                )}
            </CFormGroup>
        </div>
    );
};

export default SWInput;

import React, { useEffect, useState } from 'react';
import { CRow, CCol, CDataTable, CInputCheckbox, CLabel, CContainer, CButton, CTooltip, CSpinner, CCollapse } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { freeSet } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useFormik } from "formik";
import * as yup from "yup"
import RatesClient from '../../../../clients/ratesClient';
import ShipClient from '../../../../clients/shipClient';
import { AppModel } from '../../../../models/app';
import { RateQueryResult, RateQuery, RateQueryResultCharge } from '../../../../models/retes';
import { ShowNotification } from '../../../../store/actions/auth';
import { setCreateShipmentData } from '../../../../store/actions/shipments';
import { SetIsLoading } from '../../../../store/actions/ui';
import ConfirmModal from '../../../SharedComponents/ConfirmModal';
import SWDateAndTimePicker from '../../../SharedComponents/SWDateAndTimePicker';
import SWInput from '../../../SharedComponents/SWInput';
import { BookingCreateRequest, calculateTotalWeight, CreateShipment, createShipmentInitialValues, documentItem, ShipmentCreateResult, ShipmentFlags, StandardShipmentPiece } from '../../../../models/shipment';
import LabelModal from './labelModal';
import ConfirmShipmentModal from './confirmShipemntModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ChargesDetailsTable from './chargesDetailsTable';


interface IProps {
    data?: CreateShipment
    isSellingPricing?: boolean
    isCostPricing?: boolean
    isQuickPricing?: boolean
}
const Agents = ({ data, isSellingPricing, isCostPricing, isQuickPricing }: IProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isLoading = useSelector((state: AppModel) => state.app.isLoading);
    const ratesClient = new RatesClient();
    const shipClient = new ShipClient();
    const [selling, setSelling] = useState<RateQueryResult[]>([]);
    const [cost, setCost] = useState<RateQueryResult[]>([]);
    const [clickedRow, setClickedRow] = useState<RateQueryResult | null>(null);
    const [hasIdCheck, setHasIdCheck] = useState<boolean>(false);
    const [isPickup, setIsPickup] = useState<boolean>(false);
    const [pickupDate, setPickupDate] = useState<string | undefined>(undefined);
    const [hasWeekend1, setHasWeekend1] = useState<boolean>(false);
    const [hasWeekend2, setHasWeekend2] = useState<boolean>(false);
    const [hasInsurance, setHasInsurance] = useState<boolean>(false);
    const [confirmModal, setConfirmModal] = useState<{ isShown: boolean, data: RateQueryResult } | null>(null)
    const [creationModal, setCreationModal] = useState<{ isShown: boolean, data: RateQueryResult } | null>(null)

    const [printModal, setPrintModal] = useState<{ isShown: boolean, data: ShipmentCreateResult } | null>(null)

    const [search, setSearch] = useState<string>("")

    const [details, setDetails] = useState<{ service: string, agent: string }[]>([])
    const [detailsData, setDetailsData] = useState<{ [key: string]: any[] }>({});
    const [costPopoverDetails, setCostPopoverDetails] = useState<{ service: string, agent: string } | null>(null)
    const [sellingPopoverDetails, setSellingPopoverDetails] = useState<{ service: string, agent: string } | null>(null)

    const formik = useFormik<CreateShipment>({
        enableReinitialize: true,
        initialValues: createShipmentInitialValues(data),
        onSubmit: async () => {

        },
        validationSchema: yup.object({
        })
    })

    const { values } = formik;

    useEffect(() => {
        fetchData()
    }, [isPickup, hasIdCheck, hasInsurance, hasWeekend1, hasWeekend2, pickupDate]);

    const fetchData = async () => {
        await getCost()
        getSelling()
    }

    const handleSubmit = (type: "booking" | "freeShipment" | "fullShipment", labelSize?: string) => {
        let body = {
            ...values,
            pickupDate: pickupDate,
            agent: clickedRow?.agent,
            serviceCode: clickedRow?.service,
            items: mapItems(),
            pieces: mapPieces(),
            flags: calculateFlagsSum(),
            orderFulfillment: values?.itemsFromStock && values?.itemsFromStock?.length > 0 ? true : false
        }
        if (type == "booking") {
            booking(body)
        } else if (type == "freeShipment") {
            freeShipmentCreation(body)
        } else if (type == "fullShipment") {
            fullShipmentCreation(body, labelSize!)
        }
    }

    const booking = async (body: BookingCreateRequest) => {
        dispatch(SetIsLoading(true));
        let res = (await shipClient.BookingCreate(body))
        if (res?.succeeded) {
            const isSuccess = res?.data?.success;
            const uId = res?.data?.uId;
            const errorDescription = res?.data?.errorDescription ?? "";
            if (isSuccess === false) {
                dispatch(ShowNotification("Error", errorDescription, true));
            } else if (uId) {
                navigateToNewShipment(uId)
            }
        } else {
            dispatch(ShowNotification("Error", res?.error, true));
        }
    }

    const fullShipmentCreation = async (body: BookingCreateRequest, labelSize: string) => {
        dispatch(SetIsLoading(true));
        let res = (await shipClient.Create({ ...body, generateLabel: true, size: labelSize }))
        if (res?.succeeded) {
            setPrintModal({ isShown: true, data: res?.data })
        } else {
            dispatch(ShowNotification("Error", res?.data?.SWException?.[0], true));
        }
    }

    const freeShipmentCreation = async (body: BookingCreateRequest) => {
        dispatch(SetIsLoading(true));
        let res = (await shipClient.Create({ ...body, generateLabel: false }))
        if (res?.succeeded) {
            navigateToNewShipment(res?.data?.uid)
        } else {
            dispatch(ShowNotification("Error", res?.error, true));
        }
    }

    const navigateToNewShipment = (uId: string) => {
        dispatch(setCreateShipmentData(createShipmentInitialValues({
            account: values?.account, shipper: {}, consignee: {},
            weight: {
                value: 0.1,
                unit: 1
            },
            cOD: {
                currency: "EUR",
                amount: 0,
            },
            value: {
                currency: "EUR",
                amount: 0,
            },
        })))
        history.push({
            pathname: '/shipments/create',
            state: { uId: uId, duplicate: false }
        });

    }


    const calculateFlagsSum = () => {
        let sum = 0;
        if (isPickup) sum += ShipmentFlags.Pickup;
        if (!Boolean(values?.consignee?.companyName)) sum += ShipmentFlags.Residential;
        if (hasIdCheck) sum += ShipmentFlags.IdCheck;
        if (hasWeekend1) sum += ShipmentFlags.Weekend1;
        if (hasWeekend2) sum += ShipmentFlags.Weekend2;
        if (hasInsurance) sum += ShipmentFlags.Insurance;
        return sum;
    };

    const mapPieces = () => {
        // we don't have quantity on the level of pieces in backend
        // we need to have unique number for the reference 
        const mappedPieces: StandardShipmentPiece[] = [];
        values?.pieces?.forEach((piece, index) => {
            if (piece.quantity) {
                const quantity = Number(piece.quantity);
                if (quantity === 1) {
                    const newPiece = { ...piece, reference: Boolean(piece?.reference) ? `${piece?.reference}-${index + 1}` : `${index + 1}` };
                    mappedPieces.push(newPiece);
                } else {
                    for (let i = 0; i < quantity; i++) {
                        const newPiece = { ...piece, reference: Boolean(piece?.reference) ? `${piece?.reference}-${index + 1}-${i + 1}` : `${index + 1}-${i + 1}` };
                        mappedPieces.push(newPiece);
                    }
                }
            } else {
                mappedPieces.push(piece);
            }
        });
        return mappedPieces
    }

    const mapItems = () => {
        // Remove empty items & map weight
        const filteredItems = values?.items?.filter((item) => {
            return (
                item.countryOfOrigin !== '' ||
                item.description !== '' ||
                item.hsCode !== '' ||
                item.quantity !== undefined ||
                item.weight !== undefined
            );
        })?.map((e) => ({
            ...e,
            weight: e?.weight ? Number(e?.weight) : 0,
        }));
        // Handle isDocument case
        const items = values?.isDocument ? [documentItem(values?.shipper?.country,values?.weight?.value)] : filteredItems;

        // Validation on backend (warehouse required if it is orderFulfillment)
        const mappedItems = values?.orderFulfillment
            ? (items || []).map((item) => ({ ...item, warehouse: values?.itemsFromStock?.[0]?.warehouse }))
            : items;

        // Concatenate items and itemsFromStock
        return [...mappedItems || [], ...values?.itemsFromStock || []]
    };
    const mapGetRatesPayload: RateQuery = {
        residential: Boolean(values?.consignee?.companyName) ? false : true,
        weekend1: hasWeekend1,
        weekend2: hasWeekend2,
        insurance: hasInsurance,
        idCheck: hasIdCheck,
        pickupDate: isPickup ? pickupDate : undefined,
        pickup: isPickup,
        dimensionUnit: values?.dimensionUnit,
        weightUnit: values?.weight?.unit ?? undefined,
        from: {
            country: values?.shipper?.country ?? "",
            city: values?.shipper?.city ?? "",
            street: values?.shipper?.addressLine1 ? [values?.shipper?.addressLine1] : [],
            postCode: values?.shipper?.postCode ?? "",
            state: values?.shipper?.state ?? "",
        },
        to: {
            country: values?.consignee?.country ?? "",
            city: values?.consignee?.city ?? "",
            street: values?.consignee?.addressLine1! ? [values?.consignee?.addressLine1!] : [],
            postCode: values?.consignee?.postCode ?? "",
            state: values?.consignee?.state ?? "",
        },
        dutiable: [...values?.items || [], ...values?.itemsFromStock || []]?.some((item) => item.dutiable),
        cOD: Boolean((values?.cOD?.amount) && (values?.cOD?.amount != 0)),
        valueOfGoods: values?.value?.amount ?? 0,
        pieces: mapPieces()?.map((e) => ({
            weight: e?.weight ?? 0,
            length: e?.length == 0 ? null : e?.length ?? null,
            width: e?.width == 0 ? null : e?.width ?? null,
            height: e?.height == 0 ? null : e?.height ?? null,
        }))
    }

    // With account
    const getSelling = async () => {
        dispatch(SetIsLoading(true))
        if (values?.account) {
            let res = (await ratesClient.Calculate({ ...mapGetRatesPayload, customer: values?.account }))
            if (res?.succeeded) {
                setSelling(res?.data)
            }
        }
        dispatch(SetIsLoading(false))
    }

    // Without account 
    const getCost = async () => {
        dispatch(SetIsLoading(true))
        let res = (await ratesClient.Calculate({ ...mapGetRatesPayload }))
        if (res?.succeeded) {
            setCost(res?.data)
        }
        dispatch(SetIsLoading(false))
    }

    const calculateCharge = (e: RateQueryResult, type: "selling" | "cost") => {
        const { charges } = e
        let total: number = charges?.reduce((acc: number, charge: RateQueryResultCharge) => {
            if (!!charge?.amount) {
                return acc + Number(charge?.amount);
            } else {
                return acc;
            }
        }, 0) ?? 0;
        if (total) {
            return (
                <>
                    {total?.toFixed(2)}
                    {infoIcon(e, type)}
                </>
            )

        } else {
            return <>-</>
        }
    };

    const filterTableData = (search: string) => {
        let filteredData = [...cost || []] || [];
        if (Boolean(search)) {
            filteredData = filteredData.filter((item) =>
                item?.agentName?.toLowerCase()?.includes(search?.toLowerCase()) || item?.serviceDescription?.toLowerCase()?.includes(search?.toLowerCase())
            );
        }
        return filteredData;
    };



    const toggleDetails = async (data: RateQueryResult) => {
        const { service, agent, charges } = data
        const key = `${service}-${agent}`;
        const position = details.findIndex(e => e.service === service && e.agent === agent);
        let newDetails;
        if (position !== -1) {
            newDetails = details.slice();
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, { service, agent }];
        }
        setDetails(newDetails);
        if (position !== -1) {
            return;
        }
        setDetailsData({ ...detailsData, [key]: charges });
    }


    const infoIcon = (e: RateQueryResult, type: "cost" | "selling") => {
        return (
            <FontAwesomeIcon
                icon={isQuickPricing ? faArrowCircleDown : faInfoCircle}
                size="lg"
                color={"#FF8800"}
                style={{ cursor: "pointer", marginLeft: 3 }}
                // quick pricing >> show details table 
                // create shipment >> show popover
                onMouseOver={!isQuickPricing ? (event) => {
                    type == "cost" ? setCostPopoverDetails({ service: e?.service, agent: e?.agent })
                        : setSellingPopoverDetails({ service: e?.service, agent: e?.agent })
                } : undefined}
                onMouseOut={!isQuickPricing ? (event) => {
                    type == "cost" ? setCostPopoverDetails(null) :
                        setSellingPopoverDetails(null)
                } : undefined}
                onClick={(event) => {
                    event.stopPropagation()
                    if (isQuickPricing) {
                        toggleDetails(e);
                    }
                }}
            />
        )
    }

    const popover = (charges: RateQueryResultCharge[], isSelling?: boolean, chargeableWeight?: number | null) => {
        return (
            <div
                style={{
                    backgroundColor: "white", border: "solid 1px", borderColor: "#FF8800", zIndex: 100,
                    position: "absolute",
                    right: "1rem",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    textAlign: "left"
                }}
            >
                <ChargesDetailsTable charges={charges} isSelling={isSelling} chargeableWeight={chargeableWeight} />
            </div>
        )
    }
    const scopedSlots = {
        agent: (e: RateQueryResult) => {

            return <td className={clickedRow === e ? "table-active" : ""}>
                {e.agentName ?? "-"}
            </td>
        },
        service: (e: RateQueryResult) => {
            return <td className={clickedRow === e ? "table-active" : ""}>
                {e.serviceDescription ?? e.service ?? "-"}
            </td>
        },
        chargeWeight: (e: RateQueryResult) => {
            return <td className={clickedRow === e ? "table-active" : ""}>
                {e.chargeWeight?.toFixed(2) ?? "-"}
            </td>
        },

        cost: (e: RateQueryResult) => {
            return <td className={` ${clickedRow === e ? "table-active" : ""} text-right`}>
                {calculateCharge(e, "cost")}
                {((costPopoverDetails?.service == e?.service) && (costPopoverDetails?.agent == e?.agent)) &&
                    popover(e?.charges)}
            </td>
        },

        selling: (e: RateQueryResult) => {
            {/* // match the agent and service from selling to get the charges */ }
            const matched = selling?.length > 0 && selling?.find((item) => item?.agent === e?.agent && item?.service === e?.service);
            if (matched) {
                return <td className={`${clickedRow === e ? "table-active" : ""} text-right`}>
                    {calculateCharge(matched, "selling")}
                    {((sellingPopoverDetails?.service == matched?.service) && (sellingPopoverDetails?.agent == matched?.agent)) &&
                        popover(matched?.charges, true, matched?.chargeWeight)}
                </td>
            } else {
                return <td className={`${clickedRow === e ? "table-active" : ""} text-right`}>-</td>
            }
        },
        // for quick pricing
        "details":
            (item: RateQueryResult) => {
                return (
                    <CCollapse show={details?.some(detail => detail.service === item.service && detail.agent === item.agent)}>
                        <ChargesDetailsTable
                            charges={detailsData[`${item.service}-${item.agent}`] ?? []}
                            isSelling={isSellingPricing}
                            bgColor={"secondary"}
                            chargeableWeight={item?.chargeWeight}
                        />
                    </CCollapse>
                )
            }
    };

    const handleRowClick = (e: RateQueryResult) => {
        if (Boolean(values?.number)) {
            setClickedRow(e);
            setConfirmModal({ isShown: true, data: e });

        } else {
            setClickedRow(e);
            setCreationModal({ isShown: true, data: e });
        }
    };


    const getTableFields = () => {
        const baseFields = [
            { key: "agent", label: "Agent" },
            { key: "service", label: "Service" },
            { key: "chargeWeight", label: "Chargeable Weight (Kg)" },
        ];
        if (isCostPricing) {
            return [...baseFields, { key: "cost", label: "Cost", _style: { textAlign: "end", paddingRight: "1rem" } },]
        } else if (isSellingPricing) {
            return [...baseFields, { key: "selling", label: "Selling", _style: { textAlign: "end", paddingRight: "1rem" } },]
        } else {
            return [...baseFields,
            { key: "cost", label: "Cost", _style: { textAlign: "end", paddingRight: "1rem" } },
            { key: "selling", label: "Selling", _style: { textAlign: "end", paddingRight: "1rem" } },]
        }
    }

    const getTableItems = () => {
        if (isCostPricing) {
            return cost?.filter(result => result?.charges?.some(charge => charge.amount !== null));
        } else if (isSellingPricing) {
            return selling?.filter(result => result?.charges?.some(charge => charge.amount !== null));
        } else {
            return []
        }
    }

    return (
        <CContainer className="bg-white p-4 text-primary small ">
            {!isQuickPricing &&
                <>
                    <CRow>
                        <CTooltip content="Back">
                            <CButton>
                                <CIcon
                                    content={freeSet.cilArrowLeft}
                                    onClick={() => {
                                        history.push({
                                            pathname: '/shipments/create',
                                            state: { duplicate: true }
                                        });
                                    }}
                                />
                            </CButton>
                        </CTooltip>
                    </CRow>
                    <CRow className={"ml-4 "}>
                        <CCol md={5}>
                            <CRow>
                                <CCol md={3}>
                                    Origin:
                                </CCol>
                                <CCol>
                                    {formik?.values?.shipper?.postCode} - {formik?.values?.shipper?.country}
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol md={3}>
                                    Destination:
                                </CCol>
                                <CCol >
                                    {formik?.values?.consignee?.postCode} - {formik?.values?.consignee?.country}
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol md={3} />
                                <CCol >
                                    {calculateTotalWeight(formik?.values?.pieces)?.count} Pieces - {formik?.values?.weight?.value?.toFixed(2)} Kg
                                </CCol>
                            </CRow>
                            <CRow className={"mt-2"}>
                                <CCol>
                                    <SWInput
                                        label={"Search"}
                                        name={`search`}
                                        value={search ?? ""}
                                        onChange={(e) => setSearch(e)}
                                        type={"text"}
                                    />
                                </CCol>
                                <CCol />
                            </CRow>

                        </CCol>
                        <CCol md={5}>
                            <CRow>
                                <CCol>
                                    <CInputCheckbox
                                        name={"weekendOne"}
                                        checked={hasWeekend1}
                                        onChange={(e) => setHasWeekend1(!hasWeekend1)}
                                    />
                                    <CLabel className={"mt-1"}>Saturday</CLabel>
                                </CCol>

                                <CCol>
                                    <CInputCheckbox
                                        name={"idCheck"}
                                        checked={hasIdCheck}
                                        onChange={(e) => setHasIdCheck(!hasIdCheck)}

                                    />
                                    <CLabel className={"mt-1"}>Id Check</CLabel>
                                </CCol>
                            </CRow>

                            <CRow>
                                <CCol>
                                    <CInputCheckbox
                                        name={"insurance"}
                                        checked={hasInsurance}
                                        onChange={(e) => setHasInsurance(!hasInsurance)}
                                    />
                                    <CLabel className={"mt-1"}>Insurance</CLabel>
                                </CCol>
                                <CCol>
                                    <CInputCheckbox
                                        name={"pickup"}
                                        checked={isPickup}
                                        onChange={(e) => {
                                            if (isPickup) {
                                                setPickupDate(undefined)
                                            }
                                            setIsPickup(!isPickup)
                                        }}
                                    />
                                    <CLabel className={"mt-1"}>Pickup</CLabel>
                                </CCol>
                            </CRow>

                            <CRow>
                                <CCol />
                                <CCol>
                                    {isPickup &&
                                        <SWDateAndTimePicker
                                            showTimeInput
                                            label={""}
                                            name={"pickupDate"}
                                            value={pickupDate ? new Date(pickupDate) : undefined}
                                            handleOnChange={(e) => {
                                                setPickupDate(e)
                                            }}
                                        />}
                                </CCol>
                            </CRow>
                        </CCol>

                    </CRow>
                </>}
            <>
                {isLoading ? <CSpinner
                    className="mx-auto d-block my-5"
                    color="primary"
                    style={{ width: "5em", height: "5em" }}
                /> :
                    <CDataTable
                        onRowClick={(e: RateQueryResult) => !isQuickPricing && handleRowClick(e)}
                        size="sm"
                        hover
                        scopedSlots={scopedSlots}
                        items={isQuickPricing ? getTableItems() : filterTableData(search)}
                        fields={getTableFields()}
                        {...(!isQuickPricing ? { clickableRows: true } : {})}
                    />}
                <div className="mt-4 mb-4"></div>
            </>
            {/* // with shipment number */}
            {confirmModal?.isShown &&
                <ConfirmModal
                    submitting={isLoading}
                    onClose={() => {
                        setConfirmModal(null)
                        setClickedRow(null)
                    }}
                    onSubmit={() => {
                        handleSubmit("freeShipment")
                        setConfirmModal(null)
                    }}
                    title={'Create Shipment'}
                    body={'Are you sure you want to create shipment?'} />
            }
            {/* // without shipment number */}
            {creationModal?.isShown &&
                <ConfirmShipmentModal
                    submitting={isLoading}
                    agent={clickedRow?.agent!}
                    onSubmit={(creationType: "booking" | "fullShipment", labelSize?: string) => {
                        handleSubmit(creationType, labelSize)
                        setCreationModal(null)
                    }}
                    onClose={() => {
                        setCreationModal(null)
                        setClickedRow(null)
                    }}
                />
            }
            {printModal?.isShown &&
                <LabelModal
                    data={printModal?.data}
                    navigateToNewShipment={(uId: string) => navigateToNewShipment(uId)} />
            }
        </CContainer>
    )

}

export default Agents;


import React, { Fragment, useEffect, useState } from 'react';
import { freeSet } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CRow, CTooltip, CCol, CCardBody, CLabel } from '@coreui/react';
import { useFormik } from 'formik';
import { calculateTotalWeight, CreateShipment, createShipmentInitialValues, CreateShipmentStandardShipmentPiece } from '../../../../models/shipment';
import SWInput from '../../../SharedComponents/SWInput';
import * as yup from "yup"
import ResetIcon from '../../../../icons/resetIcon';
import { FormikProps } from 'formik';
import { isNumber } from '../../../../utils/commonValidators';


interface IProps {
    pieces: CreateShipmentStandardShipmentPiece[]
    addPiece: (values: CreateShipmentStandardShipmentPiece) => void
    handleDeletePiece: (index: number) => void
    handleEditPiece: (index: number, key: string, newValue: string | number) => void
    showFields: boolean;
    setShowFields: (e: boolean) => void
    formik: FormikProps<CreateShipment>;
}

const ParcelDetails = ({ formik, pieces, addPiece, handleDeletePiece, handleEditPiece, showFields, setShowFields }: IProps) => {
    const [isHover, setIsHover] = useState(false);
    const [focusedInput, setFocusedInput] = useState<boolean>(false)
    const [count, setCount] = useState<number>(0);
    const [totalWeight, setTotalWeight] = useState<number>(0)
    const { resetForm, submitForm } = useFormik<CreateShipmentStandardShipmentPiece>({
        enableReinitialize: true,
        initialValues: {
            reference: "",
            weight: 0.1,
            length: 10,
            width: 10,
            height: 2,
            quantity: "1",

        },
        onSubmit: (values) => {
            addPiece(values);
            resetForm();
        },
        validationSchema: yup.object({

        })
    })

    const handleClear = () => {
        formik.setFieldValue("pieces", createShipmentInitialValues()?.pieces)
    }

    const getPosition = (index: number) => {
        let position = 0;
        if (showFields) {
            position = index == 0 ? 15 : 0
        } else {
            position = index == 0 ? 22 : 5
        }
        return position
    }
    const handleTotalWeight = () => {
        const result = calculateTotalWeight(pieces);
        setCount(result?.count??0)
        setTotalWeight(result?.weight??0)
    }

    useEffect(() => {
        handleTotalWeight()
    }, [pieces]);
    return (
        <CCardBody
            className={` mt-0 pt-2 pb-0 mt-0 mb-0  rounded text-black  ${(isHover || focusedInput) ? "bg-secondary" : ""
                }`}
            onMouseOver={() => {
                setIsHover(true);
            }}
            onMouseLeave={() => {
                setIsHover(false);
            }}
        >
            <CRow className="mb-1">
                <h6>
                    <strong>Pieces: </strong>
                </h6>
                <CCol>
                    <CRow style={{ marginLeft: "1px" }}>
                        <CLabel className={"text-primary"}>
                            {`${count} Pieces - ${totalWeight && totalWeight?.toFixed(2)} Kg`}
                        </CLabel>
                    </CRow>
                </CCol>
                <CCol className="text-right">
                    {(isHover || focusedInput) &&
                        <>
                            {!showFields &&
                                <CTooltip content={"unlock"} >
                                    <CIcon
                                        content={freeSet.cilLockLocked}
                                        onClick={() => setShowFields(true)}
                                        style={{
                                            height: "1.5em",
                                            width: "1.5em",
                                            cursor: "pointer",

                                        }}
                                    />
                                </CTooltip>}
                            {showFields &&
                                <>
                                    <ResetIcon onClick={() => handleClear()} tooltipText={"Clear"} />
                                    <CTooltip content={"Lock"} >
                                        <CIcon
                                            content={freeSet.cilLockUnlocked}
                                            onClick={() => setShowFields(false)}
                                            style={{
                                                height: "1.5em",
                                                width: "1.5em",
                                                cursor: "pointer",
                                                marginLeft: 8
                                            }}
                                        />
                                    </CTooltip></>}
                        </>
                    }
                </CCol>
            </CRow>


            {pieces?.map((item: CreateShipmentStandardShipmentPiece, index: number) => {
                return (
                    <Fragment key={index}>
                        <CRow className={'ml-5 mb-1'}>
                            <CCol className={"p-0 mr-2"}>
                                <SWInput
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "Quantity" : ""}
                                    name={"quantity"}
                                    value={item?.quantity}
                                    onChange={(e) => {
                                        if (e) {
                                            isNumber(e) && handleEditPiece(index, "quantity", e)
                                        } else {
                                            handleEditPiece(index, "quantity", e)
                                        }
                                    }}
                                    type={"text"}
                                    maxLength={2}
                                    // @ts-ignore
                                    validationErrors={formik?.touched?.pieces && !!formik?.errors?.pieces?.[index] && formik?.errors?.pieces?.[index]?.quantity?.toString() || ""}
                                />
                                {!showFields &&
                                    <strong > {item?.quantity}</strong>}

                            </CCol>

                            <CCol className={"p-0 mr-2"} md={2} >

                                <SWInput
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "Reference" : ""}
                                    name={"reference"}
                                    value={item?.reference}
                                    onChange={(e) => handleEditPiece(index, "reference", e)}
                                    type={"text"}
                                />   {!showFields && <strong> {item?.reference}</strong>}
                            </CCol>
                            <CCol className={"p-0 mr-2"}  >

                                <SWInput
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "Kg" : ""}
                                    name={"weight"}
                                    value={item?.weight?.toString() ?? ""}
                                    onChange={(e) => {
                                        if (e) {
                                            isNumber(e) && handleEditPiece(index, "weight", e)
                                        } else {
                                            handleEditPiece(index, "weight",e)
                                        }
                                    }}
                                    type={"text"}
                                    maxLength={4}
                                    // @ts-ignore
                                    validationErrors={formik?.touched?.pieces && !!formik?.errors?.pieces?.[index] && formik?.errors?.pieces?.[index]?.weight?.toString() || ""}
                                />  {!showFields && <strong> {item?.weight}</strong>}
                            </CCol>
                            <CCol className={"p-0"}  >

                                <SWInput
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "cm" : ""}
                                    name={"length"}
                                    value={item?.length?.toString() ?? ""}
                                    onChange={(e) => {
                                        if (e) {
                                            isNumber(e) && handleEditPiece(index, "length",e)
                                        } else {
                                            handleEditPiece(index, "length", e)
                                        }
                                    }}
                                    type={"text"}
                                    maxLength={4}
                                />  {!showFields && <strong> {item?.length}</strong>}
                            </CCol>

                            <div style={{ position: "relative", bottom: 2, top: getPosition(index), right: showFields ? 0 : 12 }}><p>x</p></div>

                            <CCol
                                className={"p-0 m-0"} >
                                <SWInput
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 p-0 small'
                                    label={index == 0 ? "cm" : ""}
                                    name={"width"}
                                    value={item?.width?.toString() ?? ""}
                                    onChange={(e) => {
                                        if (e) {
                                            isNumber(e) && handleEditPiece(index, "width", e)
                                        } else {
                                            handleEditPiece(index, "width", e)
                                        }
                                    }}
                                    type={"text"}
                                    maxLength={4}
                                />  {!showFields && <strong > {item?.width}</strong>}
                            </CCol>
                            <div style={{ position: "relative", bottom: 2, top: getPosition(index), right: showFields ? 0 : 12 }}><p>x</p></div>

                            <CCol className={"p-0"}  >

                                <SWInput
                                    onfocus={() => setFocusedInput(true)}
                                    onBlur={() => setFocusedInput(false)}
                                    style={{ marginTop: -10 }}
                                    isFieldHidden={!showFields}
                                    labelClassName='m-0 small'
                                    label={index == 0 ? "cm" : ""}
                                    name={"height"}
                                    value={item?.height?.toString() ?? ""}
                                    onChange={(e) => {
                                        if (e) {
                                            isNumber(e) && handleEditPiece(index, "height", e)
                                        } else {
                                            handleEditPiece(index, "height", e)
                                        }
                                    }}
                                    type={"text"}
                                    maxLength={4}
                                />  {!showFields && <strong> {item?.height}</strong>}
                            </CCol>
                            <CCol style={{ marginTop: -5 }} className={"p-0"} md={2} sm={4} >
                                {(showFields && index != 0) &&
                                    <CTooltip content={"Delete"}>
                                        <CIcon
                                            tabIndex={-1}
                                            content={freeSet.cilTrash}
                                            onClick={() => handleDeletePiece(index)}
                                            style={{
                                                height: "1em",
                                                width: "1.5em",
                                                cursor: "pointer",
                                                marginTop: index == 0 ? "1rem" : 0
                                            }}
                                        />
                                    </CTooltip>}
                                {(showFields && index == pieces?.length - 1) &&
                                    <CTooltip content={"Add new piece"}>
                                        <CIcon
                                            tabIndex={-1}
                                            content={freeSet.cilPlus}
                                            onClick={() => submitForm()}
                                            style={{
                                                height: "1.5em",
                                                width: "1.5em",
                                                cursor: "pointer",

                                                marginTop: index == 0 ? "1rem" : 0
                                            }}
                                        />
                                    </CTooltip>}
                            </CCol>
                        </CRow>
                    </Fragment>
                )
            })}

        </CCardBody>
    )
}

export default ParcelDetails;